export const REBUILD = {
  label: 'Rebuild',
  value: 'REBUILD',
};
export const REPAIR = {
  label: 'repair',
  value: 'REPAIR',
};
export const RESEAL = {
  label: 'Reseal',
  value: 'RESEAL',
};
export const REPLACE = {
  label: 'Replace',
  value: 'REPLACE',
};
export const RESILICON = {
  label: 'Resilicon',
  value: 'RESILICON',
};
export const MAJOR_REPAIR = {
  label: 'Major Repair',
  value: 'MAJOR_REPAIR',
};

export const COMPLETE_REBUILD = {
  label: 'Complete Rebuild',
  value: 'Contact office for quote',
};


