import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid';

import { APP_ERROR } from './constants';

export const appError = ({
  id = uuidv4(),
  title = 'Unknown error',
  route = '#',
  when = DateTime
} = {}) => ({
  type: APP_ERROR,
  notification: {
    id,
    title,
    route,
    type: 'error',
    when,
    read: false
  }
});
