import { SET_LOADING_DASH, SET_TECHS } from "actions/dasharama";

const initialState = {
    isLoadingDash: false,
    techs: []
};
const state = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING_DASH:
            return {
                ...state,
                isLoadingDash: action.loading,
                techs: []
            };
        case SET_TECHS:
            return {
                ...state,
                isLoadingDash: false,
                techs: action.techs
            };
        default:
            return state;
    }
}
export default state