import * as Yup from 'yup';
import {
  PREFAB,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';


const state = {
  initState: {
    prefabHeightState: '',
    prefabHeightVersion: VERSION_PRO.value,
    prefabHeightLiteIssues: [],
    prefabHeightLitePhotos: [],
    prefabHeightPhotos: [],
    prefabHeightRebuildWidth: '',
    prefabHeightRebuildLength: '',
    prefabHeightRebuildFeet: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.prefabHeightVersion === VERSION_PRO.value && 
      inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        prefabHeightState: Yup.string().required(REQUIRED),
        prefabHeightPhotos: Yup.array().when(
          ['prefabHeightVersion', 'prefabHeightState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabHeightRebuildWidth: Yup.number().when(
          ['prefabHeightVersion', 'prefabHeightState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabHeightRebuildLength: Yup.number().when(
          ['prefabHeightVersion', 'prefabHeightState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabHeightRebuildFeet: Yup.number().when(
          ['prefabHeightVersion', 'prefabHeightState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.prefabHeightVersion === VERSION_LITE.value
        && inspection.data.overviewStructureFuelType !== FUEL_GAS.value ?
        {
          prefabHeightVersion: Yup.string().notRequired(REQUIRED),
          prefabHeightLitePhotos: Yup.array().when(
            ['prefabHeightState', 'prefabHeightVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          )
        } : {}),
  }),
};

export default state 