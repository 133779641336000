import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Topbar from './components/Topbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';


import {
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

const FrontEnd = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar />

      <main className={classes.content}>
        <ScrollToTop />
        <Component {...rest} />
      </main>

      <Footer />
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  FrontEnd.propTypes = {
    component: PropTypes.any.isRequired,
  };
}

export default FrontEnd;
