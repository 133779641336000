import axios from 'axios';

export const userSignUp = (data) => async (dispatch) => {


    return axios
        .post('/api/auth/signup', data)
        .then((res) => {
            if (!res.data.success) {
                throw new Error();
            };
            return (res);
        })
        .catch((err) => {
            throw err.response.data.errors;
        })
};