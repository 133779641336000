import React from 'react';

import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Paper,
} from '@material-ui/core';

import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';
import ContactFormForNewsletterComponent from '../ContactFormNewsletters';
import CalendlyPopUpComponent from '../components/CalendlyComponent/index.js';
import PopUpButtonComponent from '../components/popUpButtonComponent/index.js';

// import TicketComponent from './TicketComponent';
// import SignUpButton from '../components/signUpButton';

const useStyles = makeStyles(styles);

const Pricing = (props) => {
  const classes = useStyles();

  return (
    <Grid>
      <Grid
        container
        style={{
          backgroundColor: '#ffffff',
          paddingBottom: '30px',
          paddingTop: '20px',
        }}
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            className={classes.title}
            style={{ color: '#1a4c61' }}
          >
            Simple Pricing
          </Typography>
          <Typography className={classes.subheading}>
            Choose the plan that's right for your business.
          </Typography>
          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <PopUpButtonComponent />
          </div>
        </Grid>
      </Grid>

      <Container className={classes.mainContainer}>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.pricingBoxWhole}>
            <Paper className={classes.noBorderRadius}>
              <div
                style={{
                  background: '#5192AD',
                  borderBottom: '4px solid #1A4C61',
                }}
              >
                <Typography
                  align="center"
                  className={classes.pricing}
                  variant="h2"
                >
                  Standard
                </Typography>
              </div>
              <div className={classes.pricingBox}>
                <Typography variant="h3" className={classes.moneySign}>
                  $249
                </Typography>
                <Typography variant="body1" className={classes.moneySignSub}>
                  /month
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.moneySignSubSmol}
                >
                  Includes 1 technician account
                </Typography>
                <hr className={classes.halfSizedHr} />
                <Typography variant="body1" className={classes.blueSub}>
                  Additional technician accounts
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.blueSub}
                  style={{ marginTop: '10px' }}
                >
                  $49 ea / month
                </Typography>

                <hr className={classes.halfSizedHr} />

                <div style={{ marginTop: '30px', marginLeft: '5px' }}>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Wood, gas pellet, & oil inspections
                  </Typography>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Instantly generated reports & proposals
                  </Typography>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    100+ pre-set repair responses
                  </Typography>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Preloaded chimney & fireplace repair images
                  </Typography>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Access to unlimited office accounts
                  </Typography>
                </div>

                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                  <PopUpButtonComponent />
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} className={classes.pricingBoxWhole}>
            <Paper className={classes.noBorderRadius}>
              <div
                style={{
                  background: '#1A4C61',
                  borderBottom: '4px solid #5192AD',
                }}
              >
                <Typography
                  align="center"
                  className={classes.pricing}
                  variant="h2"
                >
                  Premium
                </Typography>
              </div>
              <div className={classes.pricingBox}>
                <Typography variant="h3" className={classes.moneySign}>
                  $449
                </Typography>
                <Typography variant="body1" className={classes.moneySignSub}>
                  /month
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.moneySignSubSmol}
                >
                  Includes 1 technician account
                </Typography>
                <hr className={classes.halfSizedHr} />
                <Typography variant="body1" className={classes.blueSub}>
                  Additional technician accounts
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.blueSub}
                  style={{ marginTop: '10px' }}
                >
                  $49 ea / month
                </Typography>

                <hr className={classes.halfSizedHr} />

                <Typography
                  variant="body1"
                  style={{
                    marginTop: '25px',
                    marginBottom: '25px',
                    color: '#AD8251',
                    fontStyle: 'italic',
                    fontSize: '20px',
                    fontWeight: '',
                    textAlign: 'center',
                  }}
                >
                  Includes all the Standard features/benefits and more...
                </Typography>

                <div style={{ marginTop: '30px', marginLeft: '5px' }}>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Pull customer data instantly from your CRM
                  </Typography>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Transfer reports and proposals into your CRM
                  </Typography>
                  <Typography variant="h5" className={classes.bulletList}>
                    <img
                      src="/static/pricing/arrow.png"
                      height="20px"
                      alt="bullet point"
                      style={{ marginRight: '5px' }}
                    />{' '}
                    Available with Service Titan, Service Monster, KickServ, &
                    Housecall Pro*
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  style={{
                    textAlign: 'center',
                    marginBottom: '20px',
                    color: '#AD8251',
                  }}
                >
                  *Don't see your CRM? Contact us today so we can go over
                  integration options.
                </Typography>

                <div style={{ textAlign: 'center', position: 'relative' }}>
                  <PopUpButtonComponent />
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              justifyContent: 'center',
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '20px',
            }}
          >
            <Paper className={classes.paperStyle}>
              <div style={{ background: '#AD8251' }}>
                <Typography
                  align="center"
                  className={classes.moreFeatures}
                  variant="h3"
                >
                  More Features
                </Typography>
              </div>
              <div className={classes.moreFeaturesBox}>
                <Typography variant="h5" className={classes.headPaperStyle}>
                  Wait, there's more!
                </Typography>
                <ul className={classes.listStyle}>
                  <li className={classes.listLiStyle}>
                    Dryer vent inspections
                  </li>
                  <li className={classes.listLiStyle}>
                    Customizable company information, inspection flow, and
                    repair responses
                  </li>
                  <li className={classes.listLiStyle}>
                    Unlimited office employee accounts
                  </li>
                  <li className={classes.listLiStyle}>
                    Resources and tutorials to get you started
                  </li>
                </ul>
                <Typography
                  variant="h5"
                  className={classes.headPaperStyle}
                  style={{ marginTop: '25px' }}
                >
                  To see everything that Bear Hands Pro has to offer, schedule a
                  demo!
                </Typography>
              </div>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            <PopUpButtonComponent />
          </Grid>
        </Grid>

        <Grid container justifycontent="space-around" alignitems="center">
          <Grid item lg={12}>
            <Typography
              align="center"
              className={classes.ctaTitle}
              variant="h3"
            >
              Sign up for our Newsletter to stay up to date with industry news,
              new features and FREE chimney resources!
            </Typography>
            <ContactFormForNewsletterComponent />
          </Grid>
        </Grid>

        <CalendlyPopUpComponent />
      </Container>
    </Grid>
  );
};

export default publicComponent(Pricing);
