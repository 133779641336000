import { TYPE } from '../components/ButtonGroup';

export const COMPONENT = {
  label: 'Component',
  value: 'COMPONENT',
  type: TYPE.positive
};
export const MEASUREMENT = {
  label: 'Measurements',
  value: 'MEASUREMENTS'
};
