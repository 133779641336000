import React from 'react';

import {
    Typography,
    makeStyles,
    Card,
    CardActionArea,
    CardMedia,
    CardContent
} from '@material-ui/core';

import styles from './styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(styles);

const TutorialCard = ({ image, title, link, description }) => {
    const classes = useStyles();
    return (
        <RouterLink to={link}>
            <Card className={classes.root}>
                <CardActionArea >
                    <CardMedia
                        className={classes.media}
                        image={image}
                        titale={title}
                    />
                    <CardContent className={classes.cardContent}  >
                        <Typography className={classes.cardText} gutterBottom variant='h5' component='h2'>
                            {title}
                        </Typography>
                        {description &&
                            <Typography className={classes.cardText} variant="body2" color="textSecondary" component="p">
                                {description}
                            </Typography>
                        }
                    </CardContent>
                </CardActionArea>
            </Card>
        </RouterLink>
    )
}

export default TutorialCard;
