import * as Yup from 'yup';
import {
  PREFAB,
  VERSION_PRO,
  FUEL_GAS,
} from 'components/app/InspectionForm/constants';


const state = {
  initState: {
    prefabVentModel: '',
    prefabVentSerial: '',
    prefabVentStickerPhoto: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.prefabVentVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        prefabVentModel: Yup.string().notRequired(),
        prefabVentSerial: Yup.string().notRequired(),
        prefabVentStickerPhoto: Yup.array().notRequired(),
      } : {}),
  }),
};

export default state