import colors from 'theme/palette';

const styles = (theme) => ({
    hero: {
        backgroundColor: colors.darkBlue,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    heroWrapper: {
        height: '30vh',
    },
    heroText: {
        color: colors.black,
    },
    heroTagline: {
        fontWeight: 800,
        fontSize: '60px',
        lineHeight: '70px',
    },
    heroTitle: {
        color: colors.white,
        marginTop: theme.spacing(15),
        fontWeight: 800,
        fontSize: '60px',
        lineHeight: '70px',
    },
    mainContainer: {
        marginTop: theme.spacing(-10),
    },
    question: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    answer: {
        margin: theme.spacing(.5, 0, 2, 2)
    },
    secondContainer: {
        marginTop: theme.spacing(5)
    },
    ticketHeader: {
        marginTop: theme.spacing(2)
    },
    ticketSubHeader: {
        marginTop: theme.spacing(2)
    }
});

export default styles