export const VALVE_GV60 = {
  label: "GV60 System",
  value: "VALVE_GV60",
};
export const VALVE_ROBERT_SHAW = {
  label: "Robert Shaw w/ Standing Pilot",
  value: "VALVE_ROBERT_SHAW",
};
export const VALVE_SIT820 = {
  label: "SIT 820 Series w/ Standing Pilot",
  value: "VALVE_SIT820",
};
export const VALVE_DEXEN_IPI = {
  label: "Dexen Hearth & Home w/ IPI",
  value: "VALVE_DEXEN_IPI",
};
export const VALVE_AMERICANFLAME = {
  label: "American Flame/Skytech",
  value: "VALVE_AMERICANFLAME",
};
export const VALVE_HEATNGLO = {
  label: "DSI/HSI",
  value: "VALVE_HEATNGLO",
};
export const VALVE_SIGNATURECOMMAND = {
  label: "Signature Command",
  value: "VALVE_SIGNATURECOMMAND",
};
export const VALVE_PROFRLAME = {
  label: "Proflame w/ IPI",
  value: "VALVE_PROFRLAME",
};

export const VALVE_PROFRLAME_TWO = {
  label: "Proflame 2",
  value: "VALVE_PROFRLAME_TWO",
};
export const VALVE_HONEYWELL = {
  label: "Honeywell SV9501M2528/U",
  value: "VALVE_HONEYWELL",
};
