import React from 'react';

import {
  Grid,
  Typography,
  makeStyles,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';

import BookIcon from '@material-ui/icons/LibraryBooks';
import MicIcon from '@material-ui/icons/Mic';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import styles from './styles';

const useStyles = makeStyles(styles);

const Resources = ({ ...rest }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Container>
        <Grid container className={classes.faqContentBlock}>
          <Grid item xs={12} className={classes.titleBar}>
            <Typography className={classes.faqText} variant="h2">
              Guides
            </Typography>
            <Divider />
          </Grid>
          <Divider />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <List className={classes.horizontalList}>
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'/static/guides/techTrainingGuideFull.pdf'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Technician Training Guide'}
                />
              </ListItem>
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'/static/guides/FutureAuth-3.pdf'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Authoring Your Future'}
                />
              </ListItem>
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'/static/guides/managingTime.webp'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Managing Your Time'}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container className={classes.faqContentBlock}>
          <Grid item xs={12} className={classes.titleBar}>
            <Typography className={classes.faqText} variant="h2">
              Sales Roleplaying Guides for Technicians
            </Typography>
            <Divider />
          </Grid>
          <Divider />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <List className={classes.horizontalList}>
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'/static/placemats/chaseCoverPlacemat.pdf'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Chase Cover'}
                />
              </ListItem>
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'/static/placemats/crownSealPlacemat.pdf'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Crown Seal'}
                />
              </ListItem>
              {/* removed because it has copywrite image on the pdf */}
              {/* <ListItem className={classes.faqQuestion} button component='a' target='_blank' href={'/static/placemats/flueLinerPlacemat.pdf'}>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText className={classes.faqText} primary={"Flue Liner"} />
              </ListItem> */}
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'/static/placemats/refractoryPanelPlacemat.pdf'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Refractory Panels'}
                />
              </ListItem>
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'/static/placemats/flashingPlacemat.pdf'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Flashing'}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container justifyContent="space-between">
          <Grid container item lg={5} className={classes.faqContentBlock}>
            <Grid item xs={12} className={classes.titleBarTan}>
              <Typography className={classes.faqTextTan} variant="h2">
                Business Building Podcasts
              </Typography>
              <Divider />
            </Grid>
            <Divider />
            <Grid container>
              <List>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://open.spotify.com/show/3wuzXtKQVCunhUl02m2PGb'}
                >
                  <ListItemIcon>
                    <MicIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'The Blue Collar Gold Podcast with Mark Stoner '}
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://open.spotify.com/show/1ms7VyRiOmDYUMUbmrX1Aq'}
                >
                  <ListItemIcon>
                    <MicIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={
                      "The Outside the Business Box's Podcast with Chad Murray"
                    }
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://open.spotify.com/show/2wPkAYBvmDvyzhdcS5bqUS'}
                >
                  <ListItemIcon>
                    <MicIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'Built from the Ground Up with Justin Neri'}
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={
                    'https://lendmeyourears.buzzsprout.com/?fbclid=IwAR2FlIuTg28IVkiXNqGp5dDLLj30Q0IRGkmJPbCE80JFQP-rjrmmyn_Zzqk'
                  }
                >
                  <ListItemIcon>
                    <MicIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'Lend Me Your Ears With John Ceaser'}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={5}
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.faqContentBlock}
          >
            <Grid item xs={12} className={classes.titleBar}>
              <Typography className={classes.faqText} variant="h2">
                Business Building
              </Typography>
              <Divider />
            </Grid>
            <Grid
              item
              container
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <List>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'http://www.rushprofits.com/'}
                >
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={
                      'Developing Sales, Systems and Processes - Rush Profits'
                    }
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://rooftopsafetyusa.com/'}
                >
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'Rooftop Safety Training - Rooftop Safety USA'}
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://outsidethebusinessbox.com/'}
                >
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'Business Coach - Chad Muarray'}
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://markstoner.com/'}
                >
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'2 day Business workshop - Mark Stoner'}
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://surefire.academy/'}
                >
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'SureFire Training Academy'}
                  />
                </ListItem>
                <ListItem
                  className={classes.faqQuestion}
                  button
                  component="a"
                  target="_blank"
                  href={'https://sparkmarketer.com/'}
                >
                  <ListItemIcon>
                    <AssignmentIndIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.faqText}
                    primary={'Spark Marketer'}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container className={classes.faqContentBlock}>
          <Grid item xs={12} className={classes.titleBarBiggerMobile}>
            <Typography className={classes.faqText} variant="h2">
              Chimney Industry Facebook Groups
            </Typography>
            <Divider />
            <Divider />
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <List className={classes.horizontalList}>
              <ListItem
                className={classes.faqQuestion}
                button
                component="a"
                target="_blank"
                href={'https://www.facebook.com/groups/197561712174829'}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.faqText}
                  primary={'Chimney World'}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Resources;
