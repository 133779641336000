import { get, set } from 'idb-keyval';
import axios from 'axios';
import {
  checkLocalInspectionStored,
  getNextInspectionId
} from 'common/localInspections';
import state from 'components/app/InspectionForm/state';

export const addInspection = ({
  inspectionId = '',
  inspector = '',
  data = {},
  report = {},
  status = 'IN_PROGRESS',
  mf = false,
  mfData = {},
  createdAt = '',
  updatedAt = '',
  priceBook = {},
}) => ({
  type: 'ADD_INSPECTION',
  inspectionId,
  inspector,
  data,
  report,
  status,
  mf,
  mfData,
  createdAt,
  updatedAt,
  priceBook
});


export const setPhotoUrl = ({ inspectionId, fieldId, dataUrl, url }) => ({
  type: 'SET_PHOTO_URL',
  inspectionId,
  fieldId,
  dataUrl,
  url
});

// Protected. Must be set by server.
const setInspectionStatus = ({ inspectionId, status }) => ({
  type: 'SET_INSPECTION_STATUS',
  inspectionId,
  status
});

export const updateInspection = ({ inspectionId, data }) => ({
  type: 'UPDATE_INSPECTION',
  inspectionId,
  data
});

export const updateMFInspection = ({
  inspectionId, mfData
}) => ({
  type: 'UPDATE_MULTIFLUE_INSPECTION',
  inspectionId,
  mfData
});

export const updateMFStatus = ({
  inspectionId, mfStatus
}) => ({
  type: 'UPDATE_MULTIFLUE_STATUS',
  inspectionId,
  mfStatus,
});

export const updateReport = (inspectionId, report) => ({
  type: 'UPDATE_REPORT',
  inspectionId,
  report
});

export const setStatus = ({ inspectionId, status }) => async (dispatch) => {
  if (status !== 'COMPLETED_QUEUED') {
    return dispatch(setInspectionStatus({ inspectionId, status }));
  }

  // Send request to finalize the report
  return axios.post(`/api/inspections/queue/${inspectionId}`)
    .then((res) => {
      if (!res.data.success) {
        return;
      }
      dispatch(
        setInspectionStatus({
          inspectionId,
          status: 'COMPLETED_QUEUED'
        })
      );
    });
};

// Attempts to load inspection. First tries local copy if within persistent storage.
// If it's not within local, attempt to load inspection from server
export const loadInspection = ({ inspectionId, controller }) => async (
  dispatch
) => {
  // Prefers local copy over the remote copy
  if (await checkLocalInspectionStored(inspectionId)) {
    return dispatch(loadLocalInspection({ inspectionId }));
  }
  // Attempt to load from the server or generate a new ID from the server
  return dispatch(loadRemoteInspection({ inspectionId, controller }))
    .catch(
      async () => {
        // Failed to load from the server - offline?
        // Create new report with a temporary ID
        inspectionId = await getNextInspectionId();
        return dispatch(loadLocalInspection({ inspectionId }));
      }
    );
};

export const loadLocalInspection = ({ inspectionId }) => async (dispatch) => {
  let inspection = {}
  try {
    inspection = await get(inspectionId);
    // inspection = JSON.parse(serializedInspection);
    if (inspection === '') {
      throw new Error('None found')
    }
  } catch { inspection = {} }

  // Set Data
  if (!inspection
    || Object.keys(inspection).length === 0
    || (inspection.data && Object.keys(inspection.data).length === 0)) {

    inspection.data = state.initState;
  }

  // Set ID
  inspection.inspectionId = inspectionId;
  // console.log('loading local inspection:', inspection);
  dispatch(addInspection(inspection));
  return inspectionId;
};

export const loadRemoteInspection = ({
  inspectionId = '',
  // controller = new AbortController()
}) => async (dispatch) => {
  return axios.get(`/api/inspections/${inspectionId}`)
    .then((res) => {
      // if (!res.data.success) {
      //   throw new Error();
      // }

      if (res.data.status === "IN_PROGRESS") {
        // Assign expected defaults if not already set
        res.data.data = { ...state.initState, ...res.data.data };
      };

      // console.log('loading remote inspection:', res.data);
      dispatch(addInspection(res.data));
      return res.data.inspectionId;
    })
    .catch((err) => {
      if (inspectionId === '') {
        throw new Error();
      } else {
        throw new Error(err);
      }
    })
};

export const updatePricebook = ({ inspectionId, priceBook }) => ({
  type: 'UPDATE_PRICEBOOK',
  inspectionId,
  priceBook,
})

export const loadRemoteInspections = (
  params = {},
) => async (dispatch) => {
  return axios
    .get(
      '/api/inspections/q',
      {
        params,
      },
    )
    .then((res) => {
      if (!res.data.success) {
        throw new Error();
      }

      if (res.data.inspections) {
        const IDs = res.data.inspections.map((foundInspection) => {
          // Assign expected defaults if not already set
          foundInspection.data = { ...state.initState, ...foundInspection.data };
          dispatch(addInspection(foundInspection));
          return foundInspection.inspectionId;
        });
        return [IDs, res.data.count];
      }
      return [[], 0];
    });
};

export const saveAllInspections = ({ inspections, reports }) => async (
  dispatch
) => {
  const inspectionIds = Object.keys(inspections);
  inspectionIds.forEach((inspectionId) => {
    const inspection = inspections[inspectionId];
    // Only save if the inspection has been modified since last save - status === SYNCING
    if (inspection && inspection.status === 'SYNCING') {
      const report = reports[inspectionId];
      dispatch(saveInspection({ inspection, report }));
    }
  });
};

export const saveInspection = ({ inspection, report }) => async (dispatch) => {
  await dispatch(saveInspectionLocal(inspection));
  inspection.status = 'IN_PROGRESS';

  return axios.post('/api/inspections', { inspection, report })
    .then((res) => {
      if (!res.data.success) {
        // Quietly fail if offline or unable to update
        return;
      }

      if (res.data.success) {
        if (res.data.status) {
          dispatch(
            setStatus({
              inspectionId: inspection.inspectionId,
              status: res.data.status
            })
          );
          return;
        }

        dispatch(
          setStatus({
            inspectionId: inspection.inspectionId,
            status: 'IN_PROGRESS'
          })
        );
      }
    }).catch((err) => {
      //if user fails check on token logs user out.
      // dispatch(
      //   logoutUser()
      // )
    });
};

export const saveInspectionLocal = (inspection) => async () => {
  const { inspectionId } = inspection;
  await set(inspectionId, inspection);
  return inspectionId;
};

const empty = () => { };

export const uploadAsset = ({
  dataUrl,
  inspectionId,
  progress = empty
}) => async () => {
  return axios.post(
    `/api/inspections/upload/${inspectionId}`,
    { data: dataUrl },
    {
      // Converts progress into a percentage
      onUploadProgress: (progressEvent) => {
        progress(
          (progressEvent.loaded / progressEvent.total).toFixed(2) * 100
        );
      }
    }
  )
    .then((res) => {
      if (!res.data.success) {
        return;
      }

      if (res.data.url) {
        return res.data.url;
      }
    });
};
