import * as Yup from 'yup';
import {
  BOTH,
  BRICKWORK,
  MASONRY,
  REBUILD,
  SCAFFOLDING,
  SERVICE_REPOINT_BRICKWORK,
  STUCCO,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  OPEN_FIREBOX,
  INSERT,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryStackState: '',
    masonryStackVersion: VERSION_PRO.value,
    masonryStackLiteIssues: [],
    masonryStackLitePhotos: [],
    masonryStackStructureRemoval: '',
    masonryStackMaterial: '',
    masonryStackBrickworkPhotos: [],
    masonryStackBrickworkProposal: '',
    masonryStackBrickworkRebuildFeet: '',
    masonryStackBrickworkDemoCost: '',
    masonryStackLift: { value: '' },
    masonryStackScaffoldingDays: '',
    masonryStackRepointArea: '',
    masonryStackStuccoPhotos: [],
    masonryStackStuccoDescription: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryStackState: Yup.string().when('overviewChimneyType', {
          is: MASONRY.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        masonryStackMaterial: Yup.string().when(
          ['masonryStackVersion', 'masonryStackState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryStackBrickworkPhotos: Yup.array().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
          ],
          {
            is: (version, state, material) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryStackStructureRemoval: Yup.string().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
          ],
          {
            is: (version, state, material) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryStackBrickworkProposal: Yup.string().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
          ],
          {
            is: (version, state, material) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryStackBrickworkRebuildFeet: Yup.number().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
            'masonryStackBrickworkProposal',
          ],
          {
            is: (version, state, material, proposal) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material) &&
              proposal === REBUILD.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryStackBrickworkDemoCost: Yup.number().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
            'masonryStackBrickworkProposal',
          ],
          {
            is: (version, state, material, proposal) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material) &&
              proposal === REBUILD.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryStackLift: Yup.object().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
            'masonryStackBrickworkProposal',
          ],
          {
            is: (version, state, material, proposal) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material) &&
              proposal === REBUILD.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        masonryStackScaffoldingDays: Yup.number().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
            'masonryStackBrickworkProposal',
            'masonryStackLift',
          ],
          {
            is: (version, state, material, proposal, lift) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material) &&
              proposal === REBUILD.value &&
              lift.value === SCAFFOLDING.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryStackRepointArea: Yup.number().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
            'masonryStackBrickworkProposal',
          ],
          {
            is: (version, state, material, proposal) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [BRICKWORK.value, BOTH.value].includes(material) &&
              proposal === SERVICE_REPOINT_BRICKWORK.value,
            then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryStackStuccoPhotos: Yup.array().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
          ],
          {
            is: (version, state, material) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [STUCCO.value, BOTH.value].includes(material),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryStackStuccoDescription: Yup.string().when(
          [
            'masonryStackVersion',
            'masonryStackState',
            'masonryStackMaterial',
          ],
          {
            is: (version, state, material) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [STUCCO.value, BOTH.value].includes(material),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryStackLitePhotos: Yup.array().when(
          ['masonryStackState', 'masonryStackVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state