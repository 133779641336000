import * as Yup from 'yup';
import {
    CEILING,
    FREESTANDING, UNSATISFACTORY, VERSION_LITE
} from 'components/app/InspectionForm/constants';
import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        freestandingCeilingSupportBoxState: "",
        freestandingCeilingSupportBoxVersion: '',
        freestandingCeilingSupportBoxLiteIssues: [],
        freestandingCeilingSupportBoxLitePhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value
            && inspection.data.overviewFreestandingVentType === CEILING.value
            ? {
                freestandingCeilingSupportBoxState: Yup.string().required(REQUIRED),
                freestandingCeilingSupportBoxLitePhotos: Yup.array().when(
                    ['freestandingCeilingSupportBoxState', 'freestandingCeilingSupportBoxVersion'],
                    {
                        is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_LITE.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                )
            } : {}),
    }),
};

export default state