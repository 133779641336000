import React from 'react';

import {
    Grid,
    makeStyles,
    Button,
} from '@material-ui/core';

import CreatAdmin from './CreatAdmin';
import CreateOrg from './CreateOrg';
import Promo from './Promo';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';

import { useDispatch } from 'react-redux';
import { userSignUp } from 'actions/admin';
import { handleLogin } from 'actions/auth';

import styles from './styles'

import useDebounce from 'utils/useDebounce';

import ErrorHandling from './ErrorHandling';
const useStyles = makeStyles(styles);



const SignUpButton = ({ promoCode, ...props }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [showSignUpAdmin, setShowSignUpAdmin] = React.useState(false);

    const [showPromo, setShowPromo] = React.useState(false);

    const [showTerms, setShowTerms] = React.useState(false);

    const [terms, setTerms] = React.useState(false);

    const [showError, setShowError] = React.useState(false);
    const [error, setError] = React.useState(false);


    const [enableNext, setEnableNext] = React.useState(false);

    const [passwordsDontmatch, setPasswordsDontMatch] = React.useState(false);

    const [passwordToShort, setPasswordToShort] = React.useState(false);

    // state for company sign up dialog
    const [showSignUpComp, setShowSignUpComp] = React.useState(false);

    //opens admin sign up form.
    const handleOpenSignUpAdmin = () => {
        setShowSignUpAdmin(true);
    };


    // function for switching pages of sign up form, takes in id's that point it to next dialog
    const handleNext = (e) => {
        e.preventDefault();
        setEnableNext(false);
        const { id } = e.currentTarget;
        if (id === 'toCompany') {
            setShowSignUpAdmin(false);
            setShowSignUpComp(true);
        } else if (id === 'toTerms') {
            setShowSignUpComp(false);
            setShowTerms(true);
        } else if (id === 'toPromo') {
            setShowTerms(false);
            setShowPromo(true);
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        if (id === 'toAdmin') {
            setShowSignUpComp(false);
            setShowSignUpAdmin(true);
        } else if (id === 'toCompany') {
            setShowTerms(false);
            setShowSignUpComp(true);
        } else if (id === 'toTerms') {
            setShowTerms(true);
            setShowPromo(false);
        } else if (id === 'toPromo') {
            setShowPromo(true);
            setShowError(false);
        }
    }


    //closes everything.
    const handleClose = () => {
        setShowSignUpComp(false);
        setShowSignUpAdmin(false);
        setShowPromo(false);
        setShowTerms(false);
        setShowError(false);
    };

    const [admin, setAdmin] = React.useState({
        name: '',
        email: '',
        password: '',
        repeatPassword: '',
    });

    const debouncedFirstPassword = useDebounce(admin.password, 1000);
    const debouncedSecondPassword = useDebounce(admin.repeatPassword, 1000);


    const [company, setCompany] = React.useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
    });

    const [promo, SetPromo] = React.useState({
        reference: '',
        promoCode: promoCode,
    });

    const handleChangeAdmin = (e) => {
        e.preventDefault();
        const { name, value } = e.currentTarget;
        setAdmin({ ...admin, [name]: value })
    };

    const handleChangeCompany = (e) => {
        e.preventDefault();
        const { name, value } = e.currentTarget;
        setCompany({ ...company, [name]: value });
    };

    const handleChangePromo = (e) => {
        e.preventDefault();
        const { name, value } = e.currentTarget;
        SetPromo({ ...promo, [name]: value });
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        const data = {
            organization: company,
            admin: admin,
            promos: promo
        };
        try {
            const response = await dispatch(userSignUp(data));
            dispatch(handleLogin(response, { remember: true }))
        }
        catch (error) {
            console.log(error);
            if (error.name) {
                setError(error.name);
            } else if (error.promoCode) {
                setError(error.promoCode);
            } else if (error.email) {
                setError(error.email)
            } else if (error.password) {
                setError(error.password)
            } else {
                setError("Unknown error please contact support")
            };

            setShowError(true);
            setShowPromo(false);
        }

    };

    React.useEffect(() => {

        if (admin.password && admin.repeatPassword && debouncedFirstPassword && debouncedSecondPassword && debouncedFirstPassword !== debouncedSecondPassword) {
            setPasswordsDontMatch(true);
        } else {
            setPasswordsDontMatch(false);
        };

        if (debouncedFirstPassword && debouncedFirstPassword.length < 8) {
            setPasswordToShort(true)
        } else if (debouncedFirstPassword && debouncedFirstPassword.length >= 8 &&
            debouncedSecondPassword && debouncedSecondPassword.length >= 8
        ) {
            setPasswordToShort(false)
        }
        ;

        if (debouncedSecondPassword && debouncedSecondPassword.length < 8) {
            setPasswordToShort(true)
        } else if (debouncedFirstPassword && debouncedFirstPassword.length < 8 &&
            debouncedSecondPassword && debouncedSecondPassword.length < 8
        ) {
            setPasswordToShort(false)
        }
        ;


        if (showSignUpAdmin) {
            if (admin.name
                && admin.email
                && admin.password
                && admin.repeatPassword
                && admin.password === admin.repeatPassword
                && admin.password.length >= 8) {
                setEnableNext(true);
            } else {
                setEnableNext(false);
            }
        } else if (showSignUpComp) {
            if (company.name
                && company.email
                && company.phone
                && company.address
                && company.city
                && company.state
                && company.zip) {
                setEnableNext(true);
            } else {
                setEnableNext(false);
            };
        } else if (showTerms) {
            if (terms) {
                setEnableNext(true);
            } else {
                setEnableNext(false);
            }
        }
        // eslint-disable-next-line
    }, [showSignUpAdmin, showSignUpComp, showPromo, admin, company, debouncedFirstPassword, debouncedSecondPassword, showTerms, terms]);



    return (
        <>
            <Grid item container justifyContent='center'>
                <Button variant='contained' className={classes.signUpButton} onClick={handleOpenSignUpAdmin} color="primary">Sign Up Today!</Button>
            </Grid>
            <CreatAdmin
                classes={classes}
                handleChangeAdmin={handleChangeAdmin}
                admin={admin}
                handleClose={handleClose}
                handleNext={handleNext}
                enableNext={enableNext}
                showSignUpAdmin={showSignUpAdmin}
                passwordsDontmatch={passwordsDontmatch}
                passwordToShort={passwordToShort}
            />
            <CreateOrg
                showSignUpComp={showSignUpComp}
                handleClose={handleClose}
                classes={classes}
                company={company}
                handleChangeCompany={handleChangeCompany}
                handleBack={handleBack}
                enableNext={enableNext}
                handleNext={handleNext}
            />
            <TermsAndConditionsDialog
                showTerms={showTerms}
                handleClose={handleClose}
                classes={classes}
                handleBack={handleBack}
                handleNext={handleNext}
                terms={terms}
                setTerms={setTerms}
                enableNext={enableNext}
            />
            <Promo
                showPromo={showPromo}
                handleClose={handleClose}
                classes={classes}
                promo={promo}
                handleChangePromo={handleChangePromo}
                handleBack={handleBack}
                handleSubmitForm={handleSubmitForm}
            />
            <ErrorHandling
                showError={showError}
                handleClose={handleClose}
                classes={classes}
                error={error}
                handleBack={handleBack}
            />
        </>
    )
}

export default SignUpButton;