export const BLACK = {
  label: 'Black',
  value: 'BLACK'
};
export const BLACKNICKEL = {
  label: 'Black Nickel',
  value: 'BLACKNICKEL'
};
export const BRASS = {
  label: 'Brass',
  value: 'BRASS'
};
export const GUNMETAL = {
  label: 'Gun Metal',
  value: 'GUNMETAL'
};
export const NICKEL = {
  label: 'Nickel',
  value: 'NICKEL'
};
