import * as Yup from 'yup';
import { REQUIRED } from 'components/app/InspectionForm/stateConstants';
import { NO } from 'components/app/InspectionForm/constants'

const state = {
    initState: {
        addNote: NO.value,
        reportNote: ''
    },
    validation: () => ({
        addNote: Yup.string().required(REQUIRED)
    })
};

export default state