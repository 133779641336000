import colors from 'theme/palette';

const styles = (theme) => ({
  hero: {
    backgroundColor: theme.palette.common.neutral,
    height: '100%',
    width: '100%',
    display: 'flex',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(/static/landing/fireplaceHeroDarker.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/static/landing/fireplaceHeroDarker.png)',
      alignItems: 'flex-start',
      backgroundPosition: '65% 50%'
    }
  },
  heroWrapper: {
    height: '60vh',
    width: '100vh'
  },
  whiteText: {
    color: colors.white
  },
  heroText: {
    color: colors.white,
    marginLeft: theme.spacing(2),
  },
  heroTagline: {
    fontWeight: 800,
    fontSize: '60px',
    marginLeft: theme.spacing(2),
    marginBottom: '10px',
    color: colors.white,
    lineHeight: '70px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
      fontSize: '50px',
      lineHeight: '50px'
    },
  },
  middleSection: {
    backgroundColor: colors.white,
  },
  darkBlueBg: {
    backgroundColor: '#5192AD',
    color: colors.white
  },
  darkBlueBgwithPadding: {
    backgroundColor: '#5192AD',
    color: colors.white,
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  whiteBg: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  whatIsBHPContainer: {
    margin: theme.spacing(2, 0)
  },
  realtorsLoveBHPContainer: {
    marginTop: theme.spacing(0),
  },
  proVsLiteContainer: {
    marginTop: theme.spacing(3),
  },
  ourStoryContainer: {
    marginTop: theme.spacing(3),
  },
  tldr: {
    margin: theme.spacing(2),
  },
  tldrWhite: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: colors.white
  },
  button: {
    margin: theme.spacing(2),
  },
  tldrHealine: {
    fontWeight: 800,
    fontSize: '60px',
    lineHeight: '70px',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      lineHeight: '35px'
    },
  },
  tldrHealineh3: {
    color: colors.white,
    fontWeight: 800,
    fontSize: '35px',
    lineHeight: '35px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '35px'
    },
  },
  image: {
    maxWidth: '100%',
    marginBottom: '20px',
    paddingRight: '40px'
  },
  imageBHP: {
    maxWidth: '100%',
    paddingRight: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px'
    },
  },
  
  imageSteps: {
    maxWidth: '100%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '0px',
    [theme.breakpoints.only('xs')]: {
      marginBottom: '40px'
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom: '15px'
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: '20px'
    },
  },
  step1: {
    height: '200px',
    width: '100%',
    backgroundImage: 'url(/static/home/step1schedule.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '30px',
  },
  sameSize: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bradleyBear: {
    height: '100%',
    width: '100%',
    display: 'flex',
    backgroundImage: 'url(/static/home/brad.png)',
    backgroundRepeat: 'no-repeat',
  },
  darkBlueHeaderText: {
    marginTop: '50px',
    textAlign: 'center',
    marginBottom: '40px',
    color:'#1a4c61',
    fontSize: '60px',
    lineHeight: '60px',
    fontWeight: '600',
    textShadow: "#1A4C61 1px 2px 6px"
  },
  imageSmall: {
    width: '200px'
  },
  threeItems: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  extraMarginBottom: {
    marginBottom: theme.spacing(5)
  },
  dividerBreak: {
    margin: theme.spacing(0.5),
    backgroundColor: colors.black,
  },

});

export default styles