import colors from 'theme/palette';

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(10),
    marginBotton: theme.spacing(10),
    fontWeight: 800,
    lineHeight: '4.2rem',
    fontSize: '4rem',
  },
  contentBlock: {
    margin: theme.spacing(5),
  },
  image: {
    maxWidth: '100%',
  },
  textSpacing: {
    marginTop: theme.spacing(2),
  },
  textSpacingWhite: {
    marginTop: theme.spacing(2),
    color: 'white'
  },
  whiteText: {
    color: 'white',
  },
  white: {
    backgroundColor: 'white',
    padding: theme.spacing(1),
  },
  darkBlue: {
    backgroundColor: colors.darkBlue,
    textColor: 'white',
    padding: theme.spacing(1),
  }
});

export default styles