import * as Yup from 'yup';
import {
  MASONRY,
  NO,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  EXTERIOR,
  VERSION_LITE,
  INSERT,
  OPEN_FIREBOX,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonrySmokeChamberState: '',
    masonrySmokeChamberHasCreosote: '',
    masonrySmokeChamberHasCreosotePhotos: [],
    masonrySmokeChamberVersion: VERSION_PRO.value,
    masonrySmokeChamberLiteIssues: [],
    masonrySmokeChamberLitePhotos: [],
    masonrySmokeChamberPhotos: [],
    masonrySmokeChamberMortarJoints: '',
    masonrySmokeChamberPargingThroughAccessDoor: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value &&
    (inspection.data.overviewApplianceType === OPEN_FIREBOX.value ||
      inspection.data.overviewApplianceType === INSERT.value) &&
    inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
          masonrySmokeChamberState: Yup.string().required(REQUIRED),
          masonrySmokeChamberPhotos: Yup.array().when(
            ['masonrySmokeChamberVersion', 'masonrySmokeChamberState'],
            {
              is: (version, state) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          masonrySmokeChamberMortarJoints: Yup.string().when(
            ['masonrySmokeChamberVersion', 'masonrySmokeChamberState'],
            {
              is: (version, state) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonrySmokeChamberPargingThroughAccessDoor: Yup.string().when(
            [
              'masonrySmokeChamberVersion',
              'masonrySmokeChamberState',
              'masonrySmokeChamberMortarJoints',
              'overviewStackType',
            ],
            {
              is: (version, state, detiorated, type) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                detiorated &&
                detiorated !== NO.value &&
                type === EXTERIOR.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonrySmokeChamberLitePhotos: Yup.array().when(
            ['masonrySmokeChamberState', 'masonrySmokeChamberVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value &&
                version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
        }
      : {}),
  }),
};

export default state;
