import { TYPE } from '../components/ButtonGroup';

export const SATISFACTORY = {
  label: 'Satisfactory',
  value: 'SATISFACTORY',
  type: TYPE.POSITIVE,
};
export const ADDITIONAL_NOTES = {
  label: 'Additional Issues',
  value: 'ADDITIONAL_NOTES',
  type: TYPE.NEGATIVE,
};
export const SATISFACTORY_GAS = {
  label: 'Satisfactory (gas)',
  value: 'SATISFACTORY_GAS',
  type: TYPE.POSITIVE,
};
export const SATISFACTORY_WATER_PROOF = {
  label: 'Satisfactory (eligible for Water Proofing)',
  value: 'SATISFACTORY_WATER_PROOF',
  type: TYPE.NEGATIVE,
};
export const SATISFACTORY_CHASE_CAP = {
  label: 'Satisfactory (Recommend Chase Cap)',
  value: 'SATISFACTORY_CHASE_CAP',
  type: TYPE.NEUTRAL,
};
export const COMPLETED = {
  label: 'Completed',
  value: 'COMPLETED',
  type: TYPE.POSITIVE,
};
export const NO_DAMAGE_FOUND = {
  label: 'No Damage Found',
  value: 'NO_DAMAGE_FOUND',
  type: TYPE.POSITIVE,
};
export const UNSATISFACTORY = {
  label: 'Defect',
  value: 'UNSATISFACTORY',
  type: TYPE.NEGATIVE,
};
export const DAMAGED = {
  label: 'Damaged',
  value: 'DAMAGED',
  type: TYPE.NEGATIVE,
};
export const MISSING = {
  label: 'Missing',
  value: 'MISSING',
  type: TYPE.NEGATIVE,
};
export const YES = {
  label: 'Yes',
  value: 'YES',
  type: TYPE.POSITIVE,
};
export const BROKEN = {
  label: 'Broken',
  value: 'BROKEN',
  type: TYPE.NEGATIVE,
};
export const HEAT_DAMAGE = {
  label: 'Heat Damage',
  value: 'HEAT_DAMAGE',
  type: TYPE.NEGATIVE,
};
export const STRUCTURE_DAMAGE = {
  label: 'Structure Damage',
  value: 'STRUCTURE_DAMAGE',
  type: TYPE.NEGATIVE,
};
export const CRACKS = {
  label: 'Cracks',
  value: 'CRACKS',
  type: TYPE.NEGATIVE,
};
export const SPALLING = {
  label: 'Spalling',
  value: 'SPALLING',
  type: TYPE.NEGATIVE,
};
export const LOOSE_BRICK = {
  label: 'Loose Brick',
  value: 'LOOSE_BRICK',
  type: TYPE.NEGATIVE,
};
export const PROTRUDING_MORTAR_JOINTS = {
  label: 'Protruding Mortar Joints',
  value: 'PROTRUDING_MORTAR_JOINTS',
  type: TYPE.NEGATIVE,
};
export const MISSING_DETERIORATED_MORTAR = {
  label: 'Missing/Deteriorated Mortar Joints',
  value: 'MISSING_DETERIORATED_MORTAR',
  type: TYPE.NEGATIVE,
};
export const CRACKED_LINER = {
  label: 'Cracked Liner',
  value: 'CRACKED_LINER',
  type: TYPE.NEGATIVE,
};
export const NO_EXISTING_LINER = {
  label: 'No Existing Liner',
  value: 'NO_EXISTING_LINER',
  type: TYPE.NEGATIVE,
};
export const DAMAGED_BRICK = {
  label: 'Damaged Brick',
  value: 'DAMAGED_BRICK',
  type: TYPE.NEGATIVE,
};
export const DAMAGED_MORTAR = {
  label: 'Damaged Mortar',
  value: 'DAMAGED_MORTAR',
  type: TYPE.NEGATIVE,
};
export const NOT_FUNCTIONING_MOISTURE_BARRIER = {
  label: 'Not Functioning Moisture barrier',
  value: 'NOT_FUNCTIONING_MOISTURE_BARRIER',
  type: TYPE.NEGATIVE,
};
export const SINGLE_FLUE = {
  label: 'Single Flue Cap',
  value: 'SINGEL_FLUE',
  type: TYPE.NEGATIVE,
};
export const CHIMNEY_CAP = {
  label: 'Chimney cap',
  value: 'CHIMNEY_CAP',
  type: TYPE.POSITIVE,
};

export const PROPANE = {
  label: 'Propane',
  value: 'Propane',
  type: TYPE.NEUTRAL,
};
export const NATURAL_GAS = {
  label: 'Natural Gas',
  value: 'NATURAL_GAS',
  type: TYPE.NEUTRAL,
};

export const VENTED_GAS_LOG_SET = {
  label: 'Vented',
  value: 'Vented Gas Log Set',
  type: TYPE.NEUTRAL,
};

export const VENT_FREE_GAS_LOG_SET = {
  label: 'Vent Free',
  value: 'Vent Free Gas Log Set',
  type: TYPE.NEUTRAL,
};

export const MANUAL_VALVE = {
  label: 'Manual Valve',
  value: 'MANUAL_VALVE',
  type: TYPE.NEUTRAL,
};

export const ELECTRONIC_IGNITION = {
  label: 'Electronic Ignition',
  value: 'ELECTRONIC_IGNITION',
  type: TYPE.NEUTRAL,
};

export const FLUE_TILE = {
  label: 'Flue Tile',
  value: 'FLUE_TILE',
  type: TYPE.NEUTRAL,
};

export const STEEL_LINER = {
  label: 'Steel Liner',
  value: 'STEEL_LINER',
  type: TYPE.NEUTRAL,
};
