import React from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

const ErrorHandeling = ({ showError, error, handleClose, classes, handleBack }) => {


    return (
        <Dialog open={showError} onClose={handleClose} fullWidth aria-labelledby='error-title'>
            <DialogTitle className={classes.dialogTitleContainer} id='error-title'>
                <Typography className={classes.dialogTitle} variant='h2' component='p'>Whoops Something Went Wrong: </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    {error}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" id='toPromo' onClick={handleBack} color='primary'>
                    back
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorHandeling;