export const LITE_COMPONENTS = [
    "masonryAshDumpLiteIssues",
    "masonryCapLiteIssues",
    "masonryCompleteRebuildLiteIssues",
    "masonryCrownLiteIssues",
    "masonryDamperLiteIssues",
    "masonryFireboxLiteIssues",
    "masonryFlashingLiteIssues",
    "masonryHearthLiteIssues",
    "masonryHeightLiteIssues",
    "masonryLinerLiteIssues",
    "masonryLowerStackLiteIssues",
    "masonrySmokeChamberLiteIssues",
    "masonrySparkScreenLiteIssues",
    "masonryStackLiteIssues",
    "gasFireboxLiteIssues",
    "gasInsulationLiteIssues",
    "gasValveLiteIssues",
    "insertBoxLiteIssues",
    "insertCatalyticLiteIssues",
    "insertEpaLiteIssues",
    "insertInsulationLiteIssues",
    "insertRainCollarLiteIssues",
    "insertTopPlateLiteIssues",
    "insertVentLiteIssues",
    "prefabCapLiteIssues",
    "prefabChaseCoverLiteIssues",
    "prefabChaseLiteIssues",
    "prefabDamperLiteIssues",
    "prefabFireboxLiteIssues",
    "prefabFlashingLiteIssues",
    "prefabHearthLiteIssues",
    "prefabHeightLiteIssues",
    "prefabSparkScreenLiteIssues",
    "prefabVentLiteIssues",
    "freestandingStoveApplianceLiteIssues",
    "freestandingChimneyCapLiteIssues",
    "freestandingChimneyPipeLiteIssues",
    "reestandingConnectorPipeLiteIssues",
    "freestandingFlashingLiteIssues",
    "freestandingHearthLiteIssues",
    "freestandingHeatShieldLiteIssues",
    "freestandingStormCollarLiteIssues",
    "freestandingCeilingSupportBoxLiteIssues",
    "freestandingStovePipeLiteIssues",
    "miscNotes"
]