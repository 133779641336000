import * as Yup from 'yup';
import { DRYER_VENT, NONE, UTILITY_CHIMNEY, VERSION_PRO } from '../../constants';
import { REQUIRED, VALID_INTEGER, VALID_ONE_PHOTO } from '../../stateConstants';
import { FREESTANDING } from 'components/app/InspectionForm/constants';



const state = {
  initState: {
    overviewNumberOfStories: '',
    overviewStackType: '',
    overviewAccessoryType: NONE.value,
    overviewStructureFuelType: '',
    overviewApplianceType: '',
    overviewChimneyType: '',
    overviewInspectionLevel: '',
    overviewHousePhoto: [],
    overviewFreestandingVentType: '',
    overviewVentType: '',
    overviewVersion: VERSION_PRO.value,
    utilChimNumberOfAppliances: '',
    overviewReasonForCallOut: '',
    insertSelected: { insert: false },
    fledge: false,
    overviewNumChim: '',
  },
  validation: (inspection) => ({
    ...(!inspection.mf ? {
      overviewNumberOfStories: Yup.number()
      .typeError(VALID_INTEGER)
      .integer(VALID_INTEGER)
      .positive(VALID_INTEGER)
      .required(VALID_INTEGER),
    overviewInspectionLevel: Yup.string().when('fledge', {
      is: (org) => org === false,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
    overviewStackType: Yup.string().when('overviewInspectionLevel', {
      is: (type) => type !== DRYER_VENT.value,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
    overviewAccessoryType: Yup.string().when('overviewInspectionLevel', {
      is: (type) => type !== DRYER_VENT.value,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
    overviewStructureFuelType: Yup.string().when('overviewInspectionLevel', {
      is: (type) => type !== DRYER_VENT.value,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
    overviewApplianceType: Yup.string().when('overviewInspectionLevel', {
      is: (type) => type !== DRYER_VENT.value,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
    overviewChimneyType: Yup.string().when(['overviewApplianceType', 'overviewInspectionLevel'], {
      is: (type, level) => type === FREESTANDING.value || type === UTILITY_CHIMNEY.value || level === DRYER_VENT.value,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(REQUIRED)
    }),
    overviewFreestandingVentType: Yup.string().when(['overviewApplianceType', 'overviewInspectionLevel'], {
      is: (type, level) => type === FREESTANDING.value && level !== DRYER_VENT.value,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
    overviewVentType: Yup.string().when(['overviewApplianceType', 'overviewInspectionLevel'], {
      is: (type, level) => type === UTILITY_CHIMNEY.value && level !== DRYER_VENT.value,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
    overviewHousePhoto: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO)
    } : {}),
  }),
};

export default state