import * as Yup from 'yup';
import {
    FREESTANDING, UNSATISFACTORY, VERSION_LITE, VERSION_PRO
} from 'components/app/InspectionForm/constants';
import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';
const state = {
    initState: {
        freestandingDistanceOfApplianceToWall: '',
        freestandingDistanceOfLoadingSide: '',
        freestandingStoveApplianceState: "",
        freestandingStoveApplianceVersion: '',
        freestandingStoveApplianceLiteIssues: [],
        freestandingStoveAppliancePipState: '',
        freestandingStoveApplianceLitePhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value ? {
            freestandingStoveApplianceState: Yup.string().required(REQUIRED),
            freestandingStoveApplianceLiteIssues: Yup.array().when(
                ['freestandingStoveApplianceVersion', 'freestandingStoveApplianceState'],
                {
                    is: (version, state) =>
                        version === VERSION_PRO.value && state === UNSATISFACTORY.value,
                    then: Yup.array().required(REQUIRED).min(1),
                    otherwise: Yup.array().notRequired(),
                }
            ),
            freestandingStoveApplianceLitePhotos: Yup.array().when(
                ['freestandingStoveApplianceVersion', 'freestandingStoveApplianceState'],
                {
                    is: (version, state) =>
                        version === VERSION_LITE.value && state === UNSATISFACTORY.value,
                    then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                    otherwise: Yup.array().notRequired(),
                }
            )
        } : {}),
    }),
};

export default state