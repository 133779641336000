import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_PRO,
  YES,
  FUEL_GAS,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryHearthState: '',
    masonryHearthVersion: VERSION_PRO.value,
    masonryHearthLiteIssues: [],
    masonryHearthLitePhotos: [],
    masonryHearthPhotos: [],
    masonryHearthExtensionRequirements: '',
    masonryHearthRepoint: '',
    masonryHearthRepointArea: '',
    masonryHearthLiteWidthLeft: '',
    masonryHearthLiteWidthRight: '',
    masonryHearthLiteLength: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryHearthState: Yup.string().required(REQUIRED),
        masonryHearthPhotos: Yup.array().when(
          ['masonryHearthVersion', 'masonryHearthState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryHearthExtensionRequirements: Yup.string().when(
          ['masonryHearthVersion', 'masonryHearthState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryHearthRepoint: Yup.string().when(
          ['masonryHearthVersion', 'masonryHearthState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryHearthRepointArea: Yup.number().when(
          [
            'masonryHearthVersion',
            'masonryHearthState',
            'masonryHearthRepoint',
          ],
          {
            is: (version, state, needsRepoint) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              needsRepoint === YES.value,
            then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryHearthLitePhotos: Yup.array().when(
          ['masonryHearthState', 'masonryHearthVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state