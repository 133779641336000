import * as Yup from 'yup';
import {
  FUEL_WOOD,
  PREFAB,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  NA,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabDamperState: '',
    prefabDamperVersion: VERSION_PRO.value,
    prefabDamperGroundUpPhotos: [],
    prefabDamperLiteIssues: [],
    prefabDamperLitePhotos: [],
    prefabDamperPhotos: [],
    prefabDamperDiameter: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.overviewStructureFuelType === FUEL_WOOD.value &&
      inspection.data.prefabDamperVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        prefabDamperState: Yup.string().required(REQUIRED),
        prefabDamperGroundUpPhotos: Yup.array().when(
          ['prefabDamperState'],
          {
            is: (state) => state !== NA.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabDamperPhotos: Yup.array().when(
          ['prefabDamperVersion', 'prefabDamperState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabDamperDiameter: Yup.number().when(
          ['prefabDamperVersion', 'prefabDamperState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.overviewStructureFuelType === FUEL_WOOD.value &&
        inspection.data.prefabDamperVersion === VERSION_LITE.value
        && inspection.data.overviewStructureFuelType !== FUEL_GAS.value ?
        {
          prefabDamperState: Yup.string().required(REQUIRED),
          prefabDamperGroundUpPhotos: Yup.array().when(
            ['prefabDamperState'],
            {
              is: (state) => state !== NA.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          prefabDamperLitePhotos: Yup.array().when(
            ['prefabDamperState', 'prefabDamperVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          )
        } : {}),
  }),
};

export default state