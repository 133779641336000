// Changes to this file need to be mirrored in `server/common/rbac/roles.js`
const status = {
  // Insppction has new information that has not yet been saved to the server
  SYNCING: {
    label: 'Syncing',
    value: 'SYNCING',
  },
  // Inspection is not complete and changes have been saved to server
  IN_PROGRESS: {
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
  // Inspection is complete and has been queued for delivery and report generation
  COMPLETED_QUEUED: {
    label: 'Completed (Queued)',
    value: 'COMPLETED_QUEUED',
  },
  // Inspection has been completed and a copy of the report has been delivered to the client
  COMPLETED_DELIVERED: {
    label: 'Completed (Delivered)',
    value: 'COMPLETED_DELIVERED',
  },
  // Inspection has been completed but a copy of the report was not delivered to the client
  COMPLETED_NOT_DELIVERED: {
    label: 'Completed (Not Delivered)',
    value: 'COMPLETED_NOT_DELIVERED',
  },
  // Inspection was previously completed but has since been edited, a copy of the most current report has not been delievered to office nor client
  REVISED: {
    label: 'Revised',
    value: 'REVISED',
  },
  ARCHIVED: {
    label: 'Archived',
    value: 'ARCHIVED',
  },
};
export default status
