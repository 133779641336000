
import { UTILITY_CHIMNEY } from 'components/app/InspectionForm/constants';


const state = {
    initState: {

    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === UTILITY_CHIMNEY.value
            ? {

            }
            : {}),
    }),
};

export default state