import * as Yup from 'yup';
import {
  FUEL_GAS,
  UNSATISFACTORY,
  YES,
  VERSION_PRO,
  UTILITY_CHIMNEY,
  FREESTANDING,
  SATISFACTORY,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    gasValveState: '',
    gasValveControlValveReplace: '',
    gasValveLiteIssues: [],
    gasValveLitePhotos: [],
    gasValveVersion: VERSION_PRO.value,
    gasValveControlValvePhotos: [],
    gasValveECMReplace: '',
    gasValveECMPhotos: [],
    gasValveReceiverReplace: '',
    gasValveReceiverPhotos: [],
    gasValveTransmitterReplace: '',
    gasValveTransmitterPhotos: [],
    gasValveFanModuleReplace: '',
    gasValveFanModulePhotos: [],
    gasValveBurnerCoil: '',
    gasValveDexenTestEv1: '',
    gasValveDexenTestEv2: '',
    gasOverviewLiteValveType: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewStructureFuelType === FUEL_GAS.value
      && inspection.data.overviewApplianceType !== UTILITY_CHIMNEY.value
      && inspection.data.overviewApplianceType !== FREESTANDING.value
      ? {
        gasValveState: Yup.string().required(REQUIRED),
        gasValveControlValveReplace: Yup.string().when(
          ['gasValveState', 'gasValveVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_PRO.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }),
        gasValveControlValvePhotos: Yup.array().when(
          ['gasValveVersion', 'gasValveState', 'gasValveControlValveReplace'],
          {
            is: (version, state, replace) =>
              version === VERSION_PRO.value && (state === SATISFACTORY.value || (state === UNSATISFACTORY.value && replace === YES.value)),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        gasValveECMReplace: Yup.string().when(
          ['gasValveState', 'gasValveVersion'], {
          is: (state, version) =>
            state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        gasValveECMPhotos: Yup.array().when(
          ['gasValveState', 'gasValveECMReplace'],
          {
            is: (state, replace) =>
              state === UNSATISFACTORY.value && replace === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        gasValveReceiverReplace: Yup.string().when(
          ['gasValveState', 'gasValveVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        gasValveReceiverPhotos: Yup.array().when(
          ['gasValveState', 'gasValveReceiverReplace'],
          {
            is: (state, replace) =>
              state === UNSATISFACTORY.value && replace === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        gasValveTransmitterReplace: Yup.string().when(['gasValveState', 'gasValveVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        gasValveTransmitterPhotos: Yup.array().when(
          ['gasValveState', 'gasValveTransmitterReplace'],
          {
            is: (state, replace) =>
              state === UNSATISFACTORY.value && replace === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        gasValveFanModuleReplace: Yup.string().when(
          ['gasValveState', 'gasValveVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_PRO.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }),
        gasValveFanModulePhotos: Yup.array().when(
          ['gasValveState', 'gasValveFanModuleReplace'],
          {
            is: (state, replace) =>
              state === UNSATISFACTORY.value && replace === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state