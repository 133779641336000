import * as Yup from 'yup';
import {
  CUSTOM,
  PREFAB,
  FUEL_GAS,
  VERSION_PRO,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabOverviewInteriorOverviewPhotos: [],
    prefabOverviewAppliancePhotos: [],
    prefabOverviewFireboxWidth: { label: '', value: '' },
    prefabOverviewFireboxHeight: { label: '', value: '' },
    prefabOverviewFireboxDepth: { label: '', value: '' },
    prefabOverviewHearthExtension: { label: '', value: '' },
    gasOverviewStayLit: '',
    prefabOverviewStackPhotos: [],
    prefabOverviewCapSize: { value: '' },
    prefabOverviewCustomCapSize: '',
    prefabOverviewElectric: '',
    prefabOverviewGasLine: '',
    prefabOverviewManufactorer: '',
    prefabOverviewModel: '',
    prefabOverviewFireboxBackWidth: '',
    prefabOverviewYear: '',
    prefabMantelCombustibleMaterials: '',
    prefabMantelCombustibleProjection: '',
    prefabMantelState: '',
    prefabMantelClearancePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
    inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
          prefabOverviewInteriorOverviewPhotos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          prefabOverviewAppliancePhotos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          prefabOverviewFireboxWidth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          prefabOverviewFireboxHeight: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          prefabOverviewFireboxDepth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          prefabOverviewFireboxBackWidth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          prefabOverviewHearthExtension: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          gasOverviewStayLit: Yup.string().when(
            ['overviewStructureFuelType', 'gasOverviewVersion'],
            {
              is: (state, version) =>
                state === FUEL_GAS.value && version === VERSION_PRO.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          prefabOverviewStackPhotos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          prefabOverviewCustomCapSize: Yup.string().when(
            'prefabOverviewCapSize',
            {
              is: CUSTOM.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          prefabOverviewElectric: Yup.string().required(REQUIRED),
          prefabOverviewGasLine: Yup.string().required(REQUIRED),
        }
      : {}),
  }),
};

export default state;
