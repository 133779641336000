import React from 'react';
import { Grid, Typography, makeStyles, Container } from '@material-ui/core';
import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';

const useStyles = makeStyles(styles);

const RealtorsLove = () => {
  const classes = useStyles();

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={12}>
          <Typography align="center" className={classes.title} variant="h1">
            Realtors Love BHP
          </Typography>
        </Grid>

        <Grid item className={classes.contentBlock} lg={12}>
          <Typography align="center" variant="h5">
            I am confident that, if during a home sale, two companies are called
            and two reports are compared that we will look like heroes every
            time. BHP requires photos to be taken for every issue, it explains
            the issue, what can be done to rectify it and gives all parties an
            instant, professional looking digital report.
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          className={classes.contentBlock}
          lg={10}
        >
          <img
            alt="bhp inspections difference"
            src="/static/landing/bhpReport.png"
            className={classes.image}
          />
        </Grid>
        <Grid item className={classes.contentBlock} lg={12}>
          <Typography align="center" variant="h5">
            Bear Hands Pro is years ahead of the competition with our Instant
            Digital Reports, complete with pictures, explanations of issues, and
            an itemized quote for repairs.
          </Typography>
        </Grid>
        <Grid item className={classes.contentBlock} lg={12}>
          <Typography align="center" variant="h5">
            You’re probably familiar with a typical chimney inspection report -
            illegible handwriting, covered in soot, giving you half the
            information and no pictures. Both buyers and sellers are scratching
            their heads wondering what a chase cover is or why cracks around the
            lintel are important.
          </Typography>
        </Grid>
        <Grid item className={classes.contentBlock} lg={12}>
          <Typography align="center" variant="h5">
            Our inspection reports were developed specifically with Realtors in
            mind to make everything crystal clear and to make you look like a
            true professional. Mike’s Mobile is licensed, bonded, insured, CSIA
            Certified and has 17 years in business with a stellar reputation you
            can count on!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default publicComponent(RealtorsLove);
