export const STOVE_PIPE_ATTIC_CRAWL = {
  label: 'Stove Pipe pass through Attic or Crawl space',
  value: 'STOVE_PIPE_ATTIC_CRAWL',
  whatsWrong:
    'Something in attic/crawlspace is within minnimun clearance of your stove pipe.',
  whatCaused:
    'Stove pipe is missing protecting screen in attic /or crawl space.',
  additional:
    'combustibles within minimum clearance to stove pipe are subject to heat transfer and a fire risk',
  repair: [
    'Remove object(s) and install protective screen to prevent things getting to close to pipe.',
  ],
};
