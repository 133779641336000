export const FUEL_WOOD = {
  label: 'Wood',
  value: 'FUEL_WOOD'
};
export const FUEL_GAS = {
  label: 'Gas',
  value: 'FUEL_GAS'
};
export const FUEL_PROPANE = {
  label: 'Propane',
  value: 'FUEL_PROPANE'
};
export const FUEL_NATURAL_GAS = {
  label: 'Natural Gas',
  value: 'FUEL_NATURAL_GAS'
};
export const FUEL_PELLET = {
  label: 'Pellet',
  value: 'FUEL_PELLET'
};

export const FUEL_OIL = {
  label: 'Oil',
  value: 'FUEL_OIL'
};

export const FUEL_OIL_AND_GAS = {
  label: 'Oil and Gas',
  value: 'FUEL_OIL_AND_GAS',
};