import applianceState from './ApplianceOverviewForm/state';
import clientState from './ClientForm/state';
import jobNumberState from './JobNumberForm/state';

const state = {
  initState: {
    ...applianceState.initState,
    ...clientState.initState,
    ...jobNumberState.initState,
  },
  validation: (inspection, org) => ({
    ...applianceState.validation(inspection),
    ...clientState.validation(org, inspection),
    ...jobNumberState.validation,
  }),
};

export default state;
