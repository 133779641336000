import * as Yup from 'yup';
import {
  PREFAB,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  LEVEL_TWO,
  LEVEL_ONE
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabVentState: '',
    prefabVentVersion: VERSION_PRO.value,
    prefabVentFlueScan1Photos: [],
    prefabVentFlueScan5Photos: [],
    prefabVentFlueScan10Photos: [],
    prefabVentLiteIssues: [],
    prefabVentLitePhotos: [],
    prefabVentPhotos: [],
    prefabLinerClearPhotos: [],
    overviewChimneyFlueBlocked: '',
    chimChamps: false,
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.prefabVentVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        prefabVentState: Yup.string().required(REQUIRED),
        prefabVentFlueScan1Photos: Yup.array()
          .required(REQUIRED)
          .min(1, VALID_ONE_PHOTO),
        prefabVentFlueScan5Photos: Yup.array()
          .required(REQUIRED)
          .min(1, VALID_ONE_PHOTO),
        prefabVentFlueScan10Photos: Yup.array()
          .required(REQUIRED)
          .min(1, VALID_ONE_PHOTO),
        prefabVentPhotos: Yup.array().when(
          ['prefabVentVersion', 'prefabVentState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.prefabVentVersion === VERSION_LITE.value
        && inspection.data.chimChamps === false
        && inspection.data.overviewStructureFuelType !== FUEL_GAS.value ?
        {
          prefabVentState: Yup.string().required(REQUIRED),
          prefabVentFlueScan1Photos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          prefabVentFlueScan5Photos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          prefabVentFlueScan10Photos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          prefabVentLitePhotos: Yup.array().when(
            ['prefabVentState', 'prefabVentVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          )
        } : inspection.data.overviewChimneyType === PREFAB.value &&
          inspection.data.prefabVentVersion === VERSION_LITE.value
          && inspection.data.chimChamps === true
          && inspection.data.overviewStructureFuelType !== FUEL_GAS.value ?
          {
            prefabVentState: Yup.string().required(REQUIRED),
            prefabVentFlueScan1Photos: Yup.array().when(
              ['overviewInspectionLevel'], {
              is: (inspectionLevel) =>
                inspectionLevel === LEVEL_TWO.value,
              then: Yup.array().required(REQUIRED)
                .min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
            ),
            prefabVentFlueScan5Photos: Yup.array().when(
              ['overviewInspectionLevel'], {
              is: (inspectionLevel) =>
                inspectionLevel === LEVEL_TWO.value,
              then: Yup.array().required(REQUIRED)
                .min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
            ),
            prefabVentFlueScan10Photos: Yup.array().when(
              ['overviewInspectionLevel'], {
              is: (inspectionLevel) =>
                inspectionLevel === LEVEL_TWO.value,
              then: Yup.array().required(REQUIRED)
                .min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
            ),
            prefabLinerClearPhotos: Yup.array().when(
              ['overviewInspectionLevel'], {
              is: (inspectionLevel) =>
                inspectionLevel === LEVEL_ONE.value,
              then: Yup.array().required(REQUIRED)
                .min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
            ),
            prefabVentLitePhotos: Yup.array().when(
              ['prefabVentState', 'prefabVentVersion'],
              {
                is: (state, version) =>
                  state === UNSATISFACTORY.value && version === VERSION_LITE.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            )
          } : {}),
  }),
};

export default state