export const DRYER_VENT = {
    label: 'Dryer Vent',
    value: 'DRYER_VENT'
};

export const FUEL_ELECTRIC = {
    label: 'Electric',
    value: 'FUEL_ELECTRIC'
};

// export const REAR = {
//     label: 'Rear',
//     value: 'REAR'
// };

export const SIDE = {
    label: 'Side',
    value: 'SIDE'
};