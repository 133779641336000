import * as Yup from "yup";

import { REQUIRED, VALID_EMAIL, VALID_PHONE, CUSTOMER_QUESTIONS } from "../../stateConstants";

const state = {
  initState: {
    clientName: { label: "", value: "" },
    clientEmail: { label: "", value: "" },
    clientPhone: { label: "", value: "" },
    clientServiceAddress: { label: "", value: "" },
    clientServiceCity: "",
    clientServiceZip: "",
    clientServiceState: "",
    customerQuestions: [],
  },
  validation: (org) => ({
    clientName: Yup.object().shape({
      value: Yup.string().required(REQUIRED),
    }),
    clientEmail: Yup.object().shape({
      value: Yup.string().required(REQUIRED).email(VALID_EMAIL),
    }),
    clientPhone: Yup.object().shape({
      value: Yup.string().required(VALID_PHONE),
    }),
    clientServiceAddress: Yup.object().shape({
      value: Yup.string().required(REQUIRED),
    }),
    clientServiceCity: Yup.string().required(REQUIRED),
    clientServiceZip: Yup.string().required(REQUIRED),
    clientServiceState: Yup.string().required(REQUIRED),
    customerQuestions: org.settings?.clientComfortSurvey
      ? Yup.array().required(CUSTOMER_QUESTIONS).min(10, CUSTOMER_QUESTIONS)
      : undefined,
  }),
};

export default state;
