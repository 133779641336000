import axios from 'axios';


//function to edit individual user info
import { setCurrentUser } from '../auth/setCurrentUser';

export const editUser = (user, you) => async (dispatch) => {
  return axios
    .post('/api/admin/edituser', user)
    .then((res) => {


      if (!res.data.success) {

        throw new Error();
      }


      if (you === true || you === null) {

        dispatch(setCurrentUser(res.data.user, res.data.org));
      }
    })
    .catch((err) => {
      console.log(err)
    });
};
