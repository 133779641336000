import axios from 'axios';

export const deleteUser = (user) => async () => {

  return axios
    .post('/api/admin/deleteuser', user)
    .then((res) => {
      if (!res.data.success) {
        throw new Error();
      }
    })
    .catch((err) => {
      throw err.response.data.errors;
    });
};
