import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Hidden,
} from '@material-ui/core';
import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';
import CTABox from '../components/CTABox';

const useStyles = makeStyles(styles);

const WhatIsBHP = () => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item lg={12}>
            <Typography align="center" className={classes.title} variant="h1">
              Why Bear Hands Pro?
            </Typography>
          </Grid>
          <Grid item lg={10} className={classes.contentBlock}>
            <Typography align="center" variant="h3">
              Save both time and money on your inspection reports! Unlike traditional paper reports that can be messy and disorganized, BHP’s fully digital, easy-to-read reports are fast and convenient. Your customers will also appreciate how simple it is to understand their chimneys’ issues!
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid container className={classes.white}>
        <Container >
          <Grid container justifyContent="space-around" alignItems="center">
            <Hidden smUp>
              <Grid item container justifyContent="center" lg={5}>
                <img
                  className={classes.image}
                  alt="bhp inspection"
                  src="/static/landing/reportWithCustomer.jpg"
                />
              </Grid>
            </Hidden>
            <Grid item lg={4}>
              <Typography variant="h3" className={classes.textSpacing}>
                Save your technicians time:
              </Typography>
              <Typography variant="body1">
                BHP will guide your technicians through the inspection, making each one a simpler and faster process!
              </Typography>
              <Typography variant="h3" className={classes.textSpacing}>
                Cut down on the supplies your technicians need:
              </Typography>
              <Typography variant="body1">
                With built in responses and calculations, your technicians can leave the pricing sheets and reference manuals behind!
              </Typography>
              <Typography variant="h3" className={classes.textSpacing}>
                Keep your inspections consistent:
              </Typography>
              <Typography variant="body1">
                With measurements and photos required, BHP ensures that your technicians will consistently generate high-quality reports!
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item container justifyContent="center" lg={5}>
                <img
                  className={classes.image}
                  alt="bhp inspection"
                  src="/static/landing/reportWithCustomer.jpg"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Grid>
      <Grid container className={classes.darkBlue}>
        <Container >
          <Grid container justifyContent="space-around" alignItems='center'>
            <Grid item container justifyContent="center" lg={4}>
              <img
                className={classes.image}
                alt="bhp inspection"
                src="/static/landing/tabletInuse.jpg"
              />
            </Grid>
            <Grid item lg={4}>
              <Typography variant="h3" className={classes.textSpacingWhite}>
                Transparency for your customers:
              </Typography>
              <Typography variant="body1" className={classes.whiteText}>
                Each digital report is easy to read and comes complete with photos so that your technicians can explain their findings with full clarity!
              </Typography>
              <Typography variant="h3" className={classes.textSpacingWhite} >
                Empower your technicians to make the sale:
              </Typography>
              <Typography variant="body1" className={classes.whiteText}>
                BHP gives you the option of auto-generating quotes for proposed repairs, giving your technicians the tools they need to make sales on the spot!
              </Typography>
              <Typography variant="h3" className={classes.textSpacingWhite}>
                Realtors approve of BHP:
              </Typography>
              <Typography variant="body1" className={classes.whiteText}>
                Realtors agree: digital reports are the way to go for home inspections. Make sure your company is at the top of their list with BHP!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Container>
        <Grid container justifyContent="space-around" alignItems='center'>
          <Hidden smUp>
            <Grid
              item
              container
              justifyContent="center"
              lg={7}
              className={classes.textSpacing}
            >
              <img
                className={classes.image}
                alt="bhp inspection"
                src="/static/landing/bhpDashBoard.png"
              />
            </Grid>
          </Hidden>
          <Grid item lg={4} className={classes.textSpacing}>
            <Typography variant="h3" className={classes.textSpacing}>
              Manage all company reports right on your dashboard:
            </Typography>
            <Typography variant="body1">
              BHP keeps all of your company’s reports in one place, making it easy to find measurements, perform edits, and send reports to clients!
            </Typography>
            <Typography variant="h3" className={classes.textSpacing}>
              No time wasted tracking down measurements:
            </Typography>
            <Typography variant="body1">
              BHP includes a fully comprehensive measurement sheet to take the guesswork out of ordering parts!
            </Typography>
            <Typography variant="h3" className={classes.textSpacing}>
              Create report copies instantly:
            </Typography>
            <Typography variant="body1">
              Copies of each report are easily accessible from the office and the field!
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              container
              justifyContent="center"
              lg={7}
              className={classes.textSpacing}
            >
              <img
                className={classes.image}
                alt="bhp inspection"
                src="/static/landing/bhpDashBoard.png"
              />
            </Grid>
          </Hidden>
        </Grid>
        <Grid container justifyContent="space-around" alignItems='center'>
          <CTABox />
        </Grid>
      </Container>
    </>
  );
};

export default publicComponent(WhatIsBHP);
