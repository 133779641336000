import * as Yup from "yup";
import {
  VERSION_PRO,
  MASONRY,
  UNSATISFACTORY,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT,
} from "components/app/InspectionForm/constants";
import { REQUIRED, VALID_ONE_PHOTO } from "components/app/InspectionForm/stateConstants";
const state = {
  initState: {
    masonryAtticState: "",
    masonryAtticVersion: VERSION_PRO.value,
    masonryAtticLiteIssues: [],
    masonryAtticPhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value &&
    inspection.data.masonryAtticVersion === VERSION_LITE.value &&
    inspection.data.fledge === false &&
    (inspection.data.overviewApplianceType === OPEN_FIREBOX.value ||
      inspection.data.overviewApplianceType === INSERT.value)
      ? {
          masonryAtticState: Yup.string().required(REQUIRED),
          masonryAtticPhotos: Yup.array().when("masonryAtticState", {
            is: UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }),
        }
      : {}),
  }),
};

export default state;
