export const UNSUITIBLE_FOR_OPERATION = {
  label: 'Unsuitable for operation',
  value: 'UNSUITIBLE_FOR_OPERATION',
};
export const OPERATIONAL_CONDITION_AT_TIME_OF_INSPECTION = {
  label: 'Operational condition at time of inspection',
  value: 'OPERATIONAL_CONDITION_AT_TIME_OF_INSPECTION',
};
export const OPERATIONAL_WITH_MAINTENANCE_ITEMS_NEEDED = {
  label: 'Operational with maintenance items needed',
  value: 'OPERATIONAL_WITH_MAINTENANCE_ITEMS_NEEDED',
};
export const OPERATIONAL_BUT_VULNERABLE_TO_WATER_INTRUSION = {
  label: 'Operational but vulnerable to water intrusion',
  value: 'OPERATIONAL_BUT_VULNERABLE_TO_WATER_INTRUSION',
};

export const PASS = {
  label: 'Pass',
  value: 'PASS',
};

export const FAIL = {
  label: 'Fail',
  value: 'FAIL',
};

export const OVERALL_CONDITION_OPTIONS = [
  'UNSUITIBLE_FOR_OPERATION',
  'OPERATIONAL_CONDITION_AT_TIME_OF_INSPECTION',
  'OPERATIONAL_WITH_MAINTENANCE_ITEMS_NEEDED',
  'OPERATIONAL_BUT_VULNERABLE_TO_WATER_INTRUSION',
];

export const MASTERS_OVERALL_CONDITION_OPTIONS = ['PASS', 'FAIL'];
