import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const Responses = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: Responses</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4">This video is part of our series of getting started videos! In this one we cover everything you need to know about Bear Hands Pro's response system. <br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/4nihMO_uAcY" title="Getting Started: Responses" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/4nihMO_uAcY" title="Getting Started: Responses" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: Everything you need to know about responses.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={10} className={classes.stepText}>
                            <Typography variant='h6'>
                                The response system is the heart of Bear Hands Pro.  It comes with default responses pre built in or you can create as many as you want for your techs. <br />
                                This is where you would set your prices, add recomended repair pictures, and add in any language to help improve your sales. <br />
                                We recomend you try to show your customers, what's the issue, what caused it, what happens if it's not addressed, and what needs to happen to fix it.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default Responses;