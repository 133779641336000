import * as Yup from 'yup';
import {
    FREESTANDING,
    UNSATISFACTORY,
    VERSION_LITE,
    WALL,
} from 'components/app/InspectionForm/constants';
import {
    REQUIRED, VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        freestandingThimbleState: "",
        freestandingThimbleVersion: '',
        freestandingThimbleLiteIssues: [],
        freestandingThimbleLitePhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value
            && inspection.data.overviewFreestandingVentType === WALL.value
            ? {
                freestandingThimbleState: Yup.string().required(REQUIRED),
                freestandingThimbleLitePhotos: Yup.array().when(
                    ['freestandingThimbleVersion', 'freestandingThimbleState'],
                    {
                        is: (version, state) =>
                            version === VERSION_LITE.value && state === UNSATISFACTORY.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                )
            } : {}),
    }),
};

export default state