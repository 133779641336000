import * as Yup from 'yup';
import {
  MASONRY,
  SCAFFOLDING,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';


const state = {
  initState: {
    masonryHeightState: '',
    masonryHeightVersion: VERSION_PRO.value,
    masonryHeightLiteIssues: [],
    masonryHeightLitePhotos: [],
    masonryHeightPhotos: [],
    masonryHeightRebuildFeet: '',
    masonryHeightDemoCost: '',
    masonryHeightLift: { value: '' },
    masonryHeightScaffoldingDays: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryHeightState: Yup.string().required(REQUIRED),
        masonryHeightPhotos: Yup.array().when(
          ['masonryHeightVersion', 'masonryHeightState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryHeightRebuildFeet: Yup.number().when(
          ['masonryHeightVersion', 'masonryHeightState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryHeightLift: Yup.object().when(
          ['masonryHeightVersion', 'masonryHeightState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        masonryHeightScaffoldingDays: Yup.string().when(
          ['masonryHeightVersion', 'masonryHeightState', 'masonryHeightLift'],
          {
            is: (version, state, lift) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              lift.value === SCAFFOLDING.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryHeightLitePhotos: Yup.array().when(
          ['masonryHeightState', 'masonryHeightVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state