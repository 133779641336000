import { colors } from '@material-ui/core';
import colorPallet from 'theme/palette';

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(3),
    marginBotton: theme.spacing(10),
    fontWeight: 800,
    lineHeight: '4.2rem',
    fontSize: '4rem',
  },
  subheading: {
    color: '#1a4c61',
    fontWeight: 800,
    lineHeight: '2.2rem',
    fontSize: '2rem',
    marginTop: theme.spacing(2),
  },
  contentBlock: {
    margin: theme.spacing(5),
  },

  image: {
    maxWidth: '100%',
    padding: '10px'
  },
  textSpacing: {
    marginTop: theme.spacing(2),
  },
  tabPanel: {
    maxWidth: '100%',
    margin: 'Auto',
  },
  versionTile: {
    maxWidth: '100%',
    minWidth: 275,
  },
  paper: {
    height: '100%',
  },
  versionTileTitle: {
    backgroundColor: colorPallet.lightBlue,
    padding: theme.spacing(2),
    MarginBottom: theme.spacing(2),
    color: 'white',
  },
  versionTilePrice1: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  versionTilePrice2: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  checkMark: {
    color: colors.green[500],
  },
  ctaTitle: {
    margin: theme.spacing(0, 4),
    paddingTop: theme.spacing(2),
    fontWeight: 800,
    color: colorPallet.lightBlue,
  },
  listStyle: {
    fontFamily: 'Roboto',
    marginLeft: '40px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '30px'
    },
  },
  listLiStyle: {
    fontSize: '18px', 
    marginBottom: '10px', 
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px'
    },
  },
  headPaperStyle: {
    fontSize: '20px',
    fontWeight: '900',
    marginBottom: '15px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    },
  },
  moreFeatures: {
    margin: theme.spacing(0, 4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 800,
    color: colorPallet.white,
    fontSize: '30px',
    lineHeight: '30px'
  },
  moreFeaturesBox: {
    padding: '25px',
    [theme.breakpoints.down('md')]: {
      padding: '15px'
    },
  },
  pricing: {
    margin: theme.spacing(0, 4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 800,
    color: colorPallet.white,
    fontSize: '70px',
    lineHeight: '70px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      lineHeight: '40px',
    },
  },
  pricingBox: {
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '15px',
    },

  },
  bulletList: {
    fontSize: '20px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: '5px'
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '18px',
      marginBottom: '20px'
    },
  },
  pricingBoxWhole: {
    paddingTop: '20px',
    justifyContent: 'center', 
    paddingLeft: '10px', 
    paddingRight: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px',
      marginRight: '10px'
    },
  },
  moneySign: {
    fontSize: '80px', 
    lineHeight: '80px', 
    color: '#AD8251', 
    fontWeight: 'bold', 
    textAlign: 'center'
  },
  moneySignSub: {
    marginTop: '15px',
    fontSize: '25px', 
    lineHeight: '25px', 
    color: '#AD8251',  
    textAlign: 'center'
  },
  halfSizedHr: {
    marginTop: '10px',
    marginBottom: '10px',
    height: '5px', 
    backgroundColor: '#5192AD', 
    borderRadius: '90px', 
    width: '50%',  
    margin: 'auto'
  },
  blueSub: {
    fontSize: '20px', 
    color: '#1A4C61', 
    textAlign:'center'
  },
  moneySignSubSmol: {
    marginTop: '15px',
    fontSize: '15px', 
    lineHeight: '15px', 
    color: '#AD8251',  
    textAlign: 'center'
  },
  paperStyle: {
    borderRadius: '0px',
    border: '2px solid', 
    borderColor: colorPallet.darkBlue
  },
  noBorderRadius: {
    borderRadius: '0px',
    height: '100%'
  },

  signUpButton: {
    margin: theme.spacing(2, 0),
  },
  dialogTitleContainer: {
    backgroundColor: colorPallet.darkBlue,
  },
  dialogTitle: {
    color: colorPallet.white,
  },
  dialogContent: {
    height: '100%',
    margin: theme.spacing(3),
  },
  formFeild: {
    margin: theme.spacing(1, 0)
  },
  confirmTerms: {
    margin: 'auto'
  },
  nested: {
    margin: theme.spacing(0, 2)
  },
  warning: {
    color: colors.red[400]
  }
});

export default styles