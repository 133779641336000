import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  YES,
  OPEN_FIREBOX,
  INSERT,
  NA,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state =  {
  initState: {
    masonryFlashingState: '',
    masonryFlashingVersion: VERSION_PRO.value,
    masonryFlashingLiteIssues: [],
    masonryFlashingLitePhotos: [],
    masonryFlashingReplace: '',
    masonryFlashingPhotos: [],
    masonryFlashingCricket: '',
    masonryFlashingCricketPhotos: [],
    masonryFlashingOverviewPhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryFlashingState: Yup.string().required(REQUIRED),
        masonryFlashingReplace: Yup.string().when(
          ['masonryFlashingVersion', 'masonryFlashingState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryFlashingPhotos: Yup.array().when(
          [
            'masonryFlashingVersion',
            'masonryFlashingState',
            'masonryFlashingReplace',
          ],
          {
            is: (version, state, replace) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              replace === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryFlashingCricket: Yup.string().when(
          ['masonryFlashingVersion', 'masonryFlashingState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryFlashingCricketPhotos: Yup.array().when(
          [
            'masonryFlashingVersion',
            'masonryFlashingState',
            'masonryFlashingCricket',
          ],
          {
            is: (version, state, cricket) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              cricket === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryFlashingOverviewPhotos: Yup.array().when(
          ['masonryFlashingState'],
          {
            is: (state) => state !== NA.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryFlashingLitePhotos: Yup.array().when(
          ['masonryFlashingState', 'masonryFlashingVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state