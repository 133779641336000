import React from 'react';


const PaymentSuccess = () => {



    return (
        <div>
            <h1>Payment Success</h1>
        </div>
    )
};

export default PaymentSuccess;