import axios from 'axios';

import { setCurrentUser } from '../auth/setCurrentUser';

export const editOrgSettings = (user) => async (dispatch) => {

    return axios
        .post('/api/admin/editorgsettings', user)
        .then((res) => {
            if (!res.data.success) {
                throw new Error();
            }
            const { user, org } = res.data.data;
            dispatch(setCurrentUser(user, org));
        })
        .catch((err) => {
            console.log(err)
        });
};