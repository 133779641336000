import React from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';


const CreatAdmin = ({ classes, handleChangeAdmin, admin, handleClose, handleNext, enableNext, showSignUpAdmin, passwordsDontmatch, passwordToShort }) => {


    return (
        <Dialog open={showSignUpAdmin} onClose={handleClose} fullWidth aria-labelledby='sign-up-admin-form-title'>
            <DialogTitle className={classes.dialogTitleContainer} id='sign-up-admin-form-title'><Typography className={classes.dialogTitle} variant='h2' component='p'>Create Your Admin Account 1/4</Typography></DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    From your admin account you will create your other users, and manage your company settings. (All this information is changable inside of the app.)
          </DialogContentText>
                <Typography variant='h3'>Admin</Typography>
                <TextField
                    variant='outlined'
                    margin='dense'
                    name='name'
                    id='admin_name'
                    label='Name'
                    className={classes.formFeild}
                    value={admin.name}
                    onChange={handleChangeAdmin}
                    type='text'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='admin_email'
                    name='email'
                    className={classes.formFeild}
                    label='Email Address'
                    value={admin.email}
                    onChange={handleChangeAdmin}
                    type='email'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='admin_password'
                    name='password'
                    error={passwordsDontmatch}
                    className={classes.formFeild}
                    value={admin.password}
                    onChange={handleChangeAdmin}
                    label='Password'
                    type='password'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='admin_password_confirm'
                    name='repeatPassword'
                    error={passwordsDontmatch}
                    className={classes.formFeild}
                    value={admin.repeatPassword}
                    onChange={handleChangeAdmin}
                    label='Confirm Password'
                    type='password'
                    fullWidth
                />
                {passwordsDontmatch &&
                    <DialogContentText>
                        Passwords Don't Match
                    </DialogContentText>}
                {passwordToShort &&
                    <DialogContentText>
                        Password must be at least 8 chars
                    </DialogContentText>}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleClose} color='primary'>
                    cancel
          </Button>
                <Button variant='outlined' disabled={!enableNext} id='toCompany' onClick={handleNext} color='primary'>
                    Next
          </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreatAdmin;