export const POSSIBLE_CHIMNEY_FIRE = {
  label: 'Possible Chimney Fire',
  value: 'POSSIBLE_CHIMNEY_FIRE',
};

export const SALE_OR_TRANSFER_OF_PROPERTY = {
  label: 'Sale or Transfer of Property',
  value: 'SALE_OR_TRANSFER_OF_PROPERTY',
};

export const ESTIMATE_REQUESTED = {
  label: 'Estimate Requested',
  value: 'ESTIMATE_REQUESTED',
};

export const INSPECTION_REPORT_REQUESTED = {
  label: 'Inspection Report Requested',
  value: 'INSPECTION_REPORT_REQUESTED',
};

export const GAS_CLEAN_AND_SERVICE = {
  label: 'Gas Clean & Service',
  value: 'GAS_CLEAN_AND_SERVICE',
};
export const GAS_DIAGNOSTIC = {
  label: 'Gas Diagnostic',
  value: 'GAS_DIAGNOSTIC',
};

export const CHIMNEY_SWEEP_WITH_INSPECTION = {
  label: 'Chimney Sweep With Inspection',
  value: 'CHIMNEY_SWEEP_WITH_INSPECTION',
};

export const CHANGE_OF_USE = {
  label: 'Change of Use',
  value: 'CHANGE_OF_USE',
};

export const EXTERNAL_EVENT_LIKELY_TO_HAVE_DAMAGED_THE_CHIMNEY = {
  label: 'External event likely to have damaged the chimney',
  value: 'EXTERNAL_EVENT_LIKELY_TO_HAVE_DAMAGED_THE_CHIMNEY',
};

export const LINING_SYSTEM_REPLACEMENT = {
  label: 'Lining System Replacement',
  value: 'LINING_SYSTEM_REPLACEMENT',
};

export const DRYER_VENT_INSPECTION = {
  label: 'Dryer Vent Inspection',
  value: 'DRYER_VENT_INSPECTION',
};

export const GAS_LOG_SERVICE = {
  label: 'Gas Log Service',
  value: 'GAS_LOG_SERVICE',
};
export const VISUAL_INSPECTION_REPORT = {
  label: 'Visual Inspection Report',
  value: 'VISUAL_INSPECTION_REPORT',
};

export const SELECT_REASON_FOR_INSPECTION = {
  label: '',
  value: '',
};

export const REASONS_FOR_INSPECTION = [
  'SELECT_REASON_FOR_INSPECTION',
  'ESTIMATE_REQUESTED',
  'CHANGE_OF_USE',
  'DRYER_VENT_INSPECTION',
  'POSSIBLE_CHIMNEY_FIRE',
  'SALE_OR_TRANSFER_OF_PROPERTY',
  'INSPECTION_REPORT_REQUESTED',
  'GAS_CLEAN_AND_SERVICE',
  'GAS_DIAGNOSTIC',
  'CHIMNEY_SWEEP_WITH_INSPECTION',
  'EXTERNAL_EVENT_LIKELY_TO_HAVE_DAMAGED_THE_CHIMNEY',
  'LINING_SYSTEM_REPLACEMENT',
];

export const REASONS_FOR_INSPECTION_SOUTHERN_CHIMNEY = [
  'SELECT_REASON_FOR_INSPECTION',
  'CHIMNEY_SWEEP_WITH_INSPECTION',
  'GAS_LOG_SERVICE',
  'VISUAL_INSPECTION_REPORT',
];
