const styles = (theme) => ({
  root: {
    boxShadow: 'none',
  },
  logoText: {
    color: 'white',
    whiteSpace: 'nowrap',
  },
  flexGrow: {
    flexGrow: 1,
  },
  rightButton: {
    marginLeft: theme.spacing(1),
  },
  navLink: {
    color: 'white',
  },
  cta: {
    margin: 'auto',
    flexGrow: 1,
  },
  ctaText: {
    color: 'white'
  },
  logo: {
    maxWidth: '50px',
    margin: theme.spacing(1)
  },
  logoSubText: {
    color: 'white',
  }
});


export default styles