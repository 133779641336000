import React from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles, Typography, Container, Grid } from '@material-ui/core';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

import { Link as RouterLink } from 'react-router-dom';

import styles from './styles';

const useStyles = makeStyles(styles);

const Footer = ({ position = false }) => {
  const classes = useStyles();
  const [build] = React.useState(process.env.REACT_APP_BUILD);
  const [smaller, setSmaller] = React.useState(false);

  React.useEffect(() => {
    if (position) {
      setSmaller(true);
    } else {
      setSmaller(false);
    }
  }, [position]);

  return (
    <div className={smaller ? classes.rootSmaller : classes.root}>
      <Container>
        <Grid container>
          <Grid item container direction="row" lg={12}>
            <Grid item lg={4} xs={12}>
              <Typography variant="h2" className={classes.logo}>
                Bear Hands Pro
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Container>
          <Grid container justifyContent="space-between" className={classes.footerNavContainer}>
            <Grid item xs={12} md={4} style={{ marginBottom: '15px' }}>
              <Typography className={classes.footerNaveHeader} variant="body1">
                Bear Hands Pro will save both time and money! Unlike traditional paper reports that
                can be messy and disorganized, BHP’s digital, easy-to-read reports are fast and
                convenient. Plus customers love them!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={classes.footerSpace}>
              <Typography className={classes.footerNaveHeader} variant="h3">
                Site Map
              </Typography>
              <RouterLink to="/resources">
                <Typography className={classes.footerNaveHeader} variant="body1">
                  Resources
                </Typography>
              </RouterLink>
              <RouterLink to="/pricing">
                <Typography className={classes.footerNaveHeader} variant="body1">
                  Pricing
                </Typography>
              </RouterLink>
              <RouterLink to="/tutorials">
                <Typography className={classes.footerNaveHeader} variant="body1">
                  Tutorials
                </Typography>
              </RouterLink>
              <RouterLink to="/login">
                <Typography className={classes.footerNaveHeader} variant="body1">
                  Log In
                </Typography>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={classes.footerSpace}>
              <Typography className={classes.footerNaveHeader} variant="h3">
                Contact Us
              </Typography>
              <a href="tel:+1-916-954-2775">
                <Typography className={classes.footerNaveHeader} variant="body1">
                  (916) 954-2775
                </Typography>
              </a>
              <a href="mailto:contact@bearhandspro.com">
                <Typography className={classes.footerNaveHeader} variant="body1">
                  contact@bearhandspro.com
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          xs={12}
        >
          <Grid item lg={4}>
            <Typography variant="body1">&copy; 2021</Typography>
          </Grid>

          <Grid item container justifyContent="center" lg={4}>
            <a href="https://www.facebook.com/Bear-Hands-Pro-Chimney-Inspection-Software-102196458557098">
              <FacebookIcon className={classes.icons} />
            </a>
            <a href="https://www.instagram.com/bearhandspro/">
              <InstagramIcon className={classes.icons} />
            </a>
          </Grid>
          <Grid item lg={4}>
            <Typography align="right" variant="body2">
              Build: {build}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Footer.propTypes = {
    className: PropTypes.string,
  };
}

export default Footer;
