import { get, set } from 'idb-keyval';

// Returns boolean true if an inspection with the specified id is stored locally.
// Else false
export const checkLocalInspectionStored = async (id = '') => {
  const inspection = await get(id);
  return !!inspection && !!inspection.inspectionId;
};

// Finds the avaialble ID for use in persistent storage
export const getNextInspectionId = async () => {
  for (let i = 0; true; i++) {
    const id = `L${`${i}`.padStart(6, '0')}`;
    const isIdTaken = await checkLocalInspectionStored(id);
    if (!isIdTaken) {
      // Claim ID before returning to ensure we own it
      await set(id, '');
      return id;
    }
  }
};
