import * as Yup from 'yup';
import {
  CUSTOM,
  INSERT,
  UNSATISFACTORY,
  YES,
  VERSION_PRO,
  FUEL_GAS,
  FUEL_PELLET,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    insertCatalyticState: '',
    insertCatalyticLiteIssues: [],
    insertCatalyticLitePhotos: [],
    insertCatalyticsVersion: VERSION_PRO.value,
    insertCatalyticPhotos: [],
    insertCatalyticDeteriorated: '',
    insertCatalyticSize: { value: '' },
    insertCatalyticCustomSize: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === INSERT.value &&
      inspection.data.insertCatalyticsVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && inspection.data.overviewStructureFuelType !== FUEL_PELLET.value

      ? {
        insertCatalyticState: Yup.string().required(REQUIRED),
        insertCatalyticPhotos: Yup.array().when('insertCatalyticState', {
          is: UNSATISFACTORY.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }),
        insertCatalyticDeteriorated: Yup.string().when(
          'insertCatalyticState',
          {
            is: UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        insertCatalyticSize: Yup.object().when(
          ['insertCatalyticState', 'insertCatalyticDeteriorated'],
          {
            is: (catalyticState, deteriorated) =>
              catalyticState === UNSATISFACTORY.value &&
              deteriorated === YES.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        insertCatalyticCustomSize: Yup.string().when(
          [
            'insertCatalyticState',
            'insertCatalyticDeteriorated',
            'insertCatalyticSize',
          ],
          {
            is: (catalyticState, deteriorated, size) =>
              catalyticState === UNSATISFACTORY.value &&
              deteriorated === YES.value &&
              size.value === CUSTOM.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state