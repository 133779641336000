export const PRODUCT_ACS_SPRAY = {
  label: 'ACS Spray',
  value: 'PRODUCT_ACS_SPRAY',
};
export const OVAL_FLUE = {
  label: 'Oval',
  value: 'OVAL_FLUE',
};
export const SQUARED_FLUE = {
  label: 'Squared',
  value: 'SQUARED_FLUE',
};
export const ROUND_FLUE = {
  label: 'Round',
  value: 'ROUND_FLUE',
};
export const PRODUCT_PCR_SPRAY = {
  label: 'PCR Spray',
  value: 'PRODUCT_PCR_SPRAY',
};
