const styles = (theme) => ({
  root: {
    width: '350px',
    maxWidth: '100%'
  },
  header: {
    backgroundColor: theme.palette.background.default,
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    padding: theme.spacing(2, 1)
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  content: {},
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  listItemIcon: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    minWidth: 0,
    padding: '10px'
  },
  listItemTextSecondary: {
    marignTop: '4px',
    color: theme.palette.text.secondary
  },
  listItemActionIcon: {
    color: theme.palette.text.secondary,
    height: '16px',
    width: '16px'
  },
  empty: {
    textAlign: 'center',
    padding: theme.spacing(3)
  }
});

export default styles