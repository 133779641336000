import protectedComponent from 'components/helpers/protectedComponent';

import action from 'common/rbac/actions';

export default protectedComponent(
  () => import('./refractoryPanelCuringInstructions'),
  {
    action: action.TECH_VIEW,
  }
);
