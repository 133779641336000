import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';

import AppRouter from './AppRouter';
import store from './configureStore';
import theme from './theme';
import { checkSavedLogin } from './actions/auth';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import './styles/index.scss';

// Trigger a check for a saved JWT if it exists
//test key: pk_test_Mu7dbAQvsAfQv7IZFqoT3gdi00m20NY7Zi
// 
store.dispatch(checkSavedLogin());
const promise = loadStripe("pk_live_aWoa8GtaXXDH0oL2ArJ2ClJ000ePsZyToA")

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Elements stripe={promise}>
        <AppRouter />
      </Elements>
    </ThemeProvider>
  </Provider>
);
export default App
