export const PRODUCT_MASONRY_SPARK_SCREEN = {
  label: 'Tri Fold Spark Screen',
  value: 'PRODUCT_MASONRY_SPARK_SCREEN'
};
export const PRODUCT_MASONRY_GLASS_DOOR = {
  label: 'Glass Door Set',
  value: 'PRODUCT_MASONRY_GLASS_DOOR'
};
export const PRODUCT_GLASS_DOOR_30_37_225_295 = {
  label: '30-37" W by 22.5-29.5" H',
  value: 'PRODUCT_GLASS_DOOR_30_37_225_295'
};
export const PRODUCT_GLASS_DOOR_30_37_255_325 = {
  label: '30-37" W by 25.5-32.5" H',
  value: 'PRODUCT_GLASS_DOOR_30_37_255_325'
};
export const PRODUCT_GLASS_DOOR_36_43_255_325 = {
  label: '36-43" W by 25.5-32.5" H',
  value: 'PRODUCT_GLASS_DOOR_36_43_255_325'
};
export const PRODUCT_SPARK_SCREEN_18 = {
  label: '18"',
  value: 'PRODUCT_SPARK_SCREEN_18'
};
export const PRODUCT_SPARK_SCREEN_19 = {
  label: '19"',
  value: 'PRODUCT_SPARK_SCREEN_19'
};
export const PRODUCT_SPARK_SCREEN_20 = {
  label: '20"',
  value: 'PRODUCT_SPARK_SCREEN_20'
};
export const PRODUCT_SPARK_SCREEN_21 = {
  label: '21"',
  value: 'PRODUCT_SPARK_SCREEN_21'
};
export const PRODUCT_SPARK_SCREEN_22 = {
  label: '22"',
  value: 'PRODUCT_SPARK_SCREEN_22'
};
export const PRODUCT_SPARK_SCREEN_23 = {
  label: '23"',
  value: 'PRDUCT_SPARK_SCREEN_23'
};
export const PRODUCT_SPARK_SCREEN_24 = {
  label: '24"',
  value: 'PRODUCT_SPARK_SCREEN_24'
};
export const PRODUCT_SPARK_SCREEN_26 = {
  label: '26"',
  value: 'PRODUCT_SPARK_SCREEN_26'
};
export const PRODUCT_SPARK_SCREEN_28 = {
  label: '28"',
  value: 'PRODUCT_SPARK_SCREEN_28'
};
export const PRODUCT_SPARK_SCREEN_30 = {
  label: '30"',
  value: 'PRODUCT_SPARK_SCREEN_30'
};
export const PRODUCT_ROD_KIT = {
  label: 'Rod Kit',
  value: 'PRODUCT_ROD_KIT'
};
export const PRODUCT_PREFAB_GLASS_DOOR = {
  label: 'Custom Glass Door Set',
  value: 'PRODUCT_PREFAB_GLASS_DOOR'
};
export const PRODUCT_PREFAB_SPARK_SCREEN = {
  label: 'Spark Screen',
  value: 'PRODUCT_PREFAB_SPARK_SCREEN'
};
