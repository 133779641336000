import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  INSERT,
  OPEN_FIREBOX
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryMantelState: '',
    masonryMantelVersion: VERSION_PRO.value,
    masonryMantelLiteIssues: [],
    masonryMantelLitePhotos: [],
    masonryMantelCombustibleMaterials: '',
    masonryMantelCombustibleProjection: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      && inspection.data.masonryMantelVersion !== VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        masonryMantelState: Yup.string().required(REQUIRED),
        masonryMantelLitePhotos: Yup.array().when(
          ['masonryMantelState', 'masonryMantelVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state