import * as Yup from 'yup';
import {
  CRACKED_LINER,
  MASONRY,
  MISSING_DETERIORATED_MORTAR,
  NO_EXISTING_LINER,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  YES,
  NO,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT,
  LEVEL_TWO,
  LEVEL_ONE,
  FUEL_PELLET,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryLinerState: '',
    masonryLinerVersion: VERSION_PRO.value,
    masonryLinerLiteIssues: [],
    masonryLinerLitePhotos: [],
    masonryLinerFlueScan1Photos: [],
    masonryLinerFlueScan5Photos: [],
    masonryLinerFlueScan10Photos: [],
    masonryLinerRequiresCleaning: '',
    masonryLinerRequiresCleaningPhotos: [],
    masonryLinerGlazed: '',
    masonryLinerGlazedPhotos: [],
    masonryLinerStatus: '',
    masonryLinerStatusPhotos: [],
    masonryLinerLength: '',
    masonryLinerIDSizeSelect: { value: '' },
    masonryLinerODSmall: { label: '', value: '' },
    masonryLinerODLarge: { label: '', value: '' },
    masonryLinerODSizeSelect: { value: '' },
    masonryLinerFireboxRebuild: '',
    heatShieldPrevent: '',
    masonryLinerInspectable: '',
    masonryLinerCanBurn: '',
    flueTileShape: '',
    overviewChimneyFlueBlocked: '',
    masonryLinerFlueClearPhotos: [],
    chimChamps: false,
    masonryLinerLeaveMeasurements: '',
    masonryLinerShape: '',
    masonryLinerLongSide: '',
    masonryLinerShortSide: '',
    masonryLinerDiameter: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && inspection.data.overviewStructureFuelType !== FUEL_PELLET.value
      && inspection.data.chimChamps === false
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryLinerState: Yup.string().required(REQUIRED),
        masonryLinerFlueScan1Photos: Yup.array().when(
          ['overviewStructureFuelType'], {
          is: (fuleType) =>
            fuleType !== FUEL_GAS.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }
        ),
        masonryLinerFlueScan5Photos: Yup.array().when(
          ['overviewStructureFuelType'], {
          is: (fuleType) =>
            fuleType !== FUEL_GAS.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }
        ),
        masonryLinerFlueScan10Photos: Yup.array().when(
          ['overviewStructureFuelType'], {
          is: (fuleType) =>
            fuleType !== FUEL_GAS.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }
        ),

        masonryLinerRequiresCleaning: Yup.string().when(
          ['masonryLinerVersion', 'masonryLinerState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLinerRequiresCleaningPhotos: Yup.array().when(
          [
            'masonryLinerVersion',
            'masonryLinerState',
            'masonryLinerRequiresCleaning',
          ],
          {
            is: (version, state, cleaningRequired) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              cleaningRequired === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryLinerGlazed: Yup.string().when(
          ['masonryLinerVersion', 'masonryLinerState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLinerInspectable: Yup.string().when(
          ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed'],
          {
            is: (version, state, glazed) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value && glazed === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLinerCanBurn: Yup.string().when(
          ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed'],
          {
            is: (version, state, glazed) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value && glazed === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLinerGlazedPhotos: Yup.array().when(
          ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed'],
          {
            is: (version, state, isGlazed) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              isGlazed === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryLinerStatus: Yup.string().when(
          ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed', 'masonryLinerInpsectable'],
          {
            is: (version, state, glazed, inspectable) =>
              (version === VERSION_PRO.value && state === UNSATISFACTORY.value && glazed === NO.value) || (version === VERSION_PRO.value && state === UNSATISFACTORY.value && inspectable === NO.value),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLinerStatusPhotos: Yup.array().when(
          ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerStatus'],
          {
            is: (version, state, status) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [
                MISSING_DETERIORATED_MORTAR.value,
                CRACKED_LINER.value,
                NO_EXISTING_LINER.value,
              ].includes(status),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryLinerLength: Yup.string().when(
          ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerStatus'],
          {
            is: (version, state, status) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [
                MISSING_DETERIORATED_MORTAR.value,
                CRACKED_LINER.value,
                NO_EXISTING_LINER.value,
              ].includes(status),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLinerIDSizeSelect: Yup.object().notRequired(),
        masonryLinerODSmall: Yup.object().notRequired(),
        masonryLinerODLarge: Yup.object().notRequired(),
        masonryLinerODSizeSelect: Yup.object().notRequired(),
        masonryLinerFireboxRebuild: Yup.string().notRequired(),
        heatShieldPrevent: Yup.string().when(
          ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerStatus'],
          {
            is: (version, state, status) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [
                MISSING_DETERIORATED_MORTAR.value,
                CRACKED_LINER.value
              ].includes(status),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLinerLitePhotos: Yup.array().when(
          ['masonryLinerState', 'masonryLinerVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === MASONRY.value
        && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
        && inspection.data.chimChamps === true
        && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
          || inspection.data.overviewApplianceType === INSERT.value)
        ? {
          masonryLinerState: Yup.string().required(REQUIRED),

          masonryLinerFlueScan1Photos: Yup.array().when(
            ['overviewStructureFuelType', 'overviewInspectionLevel'], {
            is: (fuleType, inspectionLevel) =>
              fuleType !== FUEL_GAS.value && inspectionLevel === LEVEL_TWO.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
          ),
          masonryLinerFlueScan5Photos: Yup.array().when(
            ['overviewStructureFuelType', 'overviewInspectionLevel'], {
            is: (fuleType, inspectionLevel) =>
              fuleType !== FUEL_GAS.value && inspectionLevel === LEVEL_TWO.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
          ),
          masonryLinerFlueScan10Photos: Yup.array().when(
            ['overviewStructureFuelType', 'overviewInspectionLevel'], {
            is: (fuleType, inspectionLevel) =>
              fuleType !== FUEL_GAS.value && inspectionLevel === LEVEL_TWO.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
          ),
          masonryLinerFlueClearPhotos: Yup.array().when(
            ['overviewStructureFuelType', 'overviewInspectionLevel'], {
            is: (fuleType, inspectionLevel) =>
              fuleType !== FUEL_GAS.value && inspectionLevel === LEVEL_ONE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
          ),
          masonryLinerRequiresCleaning: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState'],
            {
              is: (version, state) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryLinerRequiresCleaningPhotos: Yup.array().when(
            [
              'masonryLinerVersion',
              'masonryLinerState',
              'masonryLinerRequiresCleaning',
            ],
            {
              is: (version, state, cleaningRequired) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                cleaningRequired === YES.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          masonryLinerGlazed: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState'],
            {
              is: (version, state) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryLinerInspectable: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed'],
            {
              is: (version, state, glazed) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value && glazed === YES.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryLinerCanBurn: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed'],
            {
              is: (version, state, glazed) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value && glazed === YES.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryLinerGlazedPhotos: Yup.array().when(
            ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed'],
            {
              is: (version, state, isGlazed) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                isGlazed === YES.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          masonryLinerStatus: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerGlazed', 'masonryLinerInpsectable'],
            {
              is: (version, state, glazed, inspectable) =>
                (version === VERSION_PRO.value && state === UNSATISFACTORY.value && glazed === NO.value) || (version === VERSION_PRO.value && state === UNSATISFACTORY.value && inspectable === NO.value),
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryLinerStatusPhotos: Yup.array().when(
            ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerStatus'],
            {
              is: (version, state, status) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                [
                  MISSING_DETERIORATED_MORTAR.value,
                  CRACKED_LINER.value,
                  NO_EXISTING_LINER.value,
                ].includes(status),
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          masonryLinerLength: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerStatus'],
            {
              is: (version, state, status) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                [
                  MISSING_DETERIORATED_MORTAR.value,
                  CRACKED_LINER.value,
                  NO_EXISTING_LINER.value,
                ].includes(status),
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryLinerIDSizeSelect: Yup.object().notRequired(),
          masonryLinerODSmall: Yup.object().notRequired(),
          masonryLinerODLarge: Yup.object().notRequired(),
          masonryLinerODSizeSelect: Yup.object().notRequired(),
          masonryLinerFireboxRebuild: Yup.string().notRequired(),
          heatShieldPrevent: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState', 'masonryLinerStatus'],
            {
              is: (version, state, status) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                [
                  MISSING_DETERIORATED_MORTAR.value,
                  CRACKED_LINER.value
                ].includes(status),
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryLinerLitePhotos: Yup.array().when(
            ['masonryLinerState', 'masonryLinerVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          masonryLinerLeaveMeasurements: Yup.string().when(
            ['masonryLinerVersion', 'masonryLinerState'],
            {
              is: (version, state) =>
                version === VERSION_LITE.value && state === UNSATISFACTORY.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.array().notRequired(),
            }
          )
        } : {}),
  }),
};

export default state