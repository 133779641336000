import React from 'react';
import axios from 'axios';

import {
    Grid,
    Typography,
    makeStyles,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';

import styles from './styles';
import useFormState from 'utils/forms/useFormState';
// import { useSelector } from 'react-redux';


const useStyles = makeStyles(styles);


const ContactFormForNewsletterComponent = () => {
    const classes = useStyles();

    // const auth = useSelector((state) => state.auth);


    const [ableToSubmit, setAbleToSubmit] = React.useState(true);
    const [question, setQuestion] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [email, setEmail] = React.useState(false);
    const [showThankYou, setShowThankYou] = React.useState(false);

    const handleOpenThankYou = () => {
        setShowThankYou(true);
    };

    const handleCloseThankYou = () => {
        setShowThankYou(false);
    };


    const {
        state: { values, errors },
        handleError,
        handleChange,
        handleSuccess,
    } = useFormState({
        question: 'Newsletter',
        additional: '',
        email: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/maillist/sendticket', values).then(() => {
            handleOpenThankYou();
            handleSuccess();
            setQuestion(true);
            setMessage(false);
            setEmail(false);
        }).catch((err) => {
            handleError(err);
        })


    }

    const handleChangeText = (e) => {
        const { name } = e.currentTarget;
        if (name === 'question') {
            setQuestion(true)
        }
        if (name === 'additional') {
            setMessage(true)
        }
        if (name === 'email') {
            setEmail(true)
        }
        handleChange(e);
    }

    React.useEffect(() => {
        if (message && email) {
            setAbleToSubmit(false);
        }
    }, [question, message, email])




    return (
        <Grid container alignItems='center' justifyContent='center' className={classes.ticketContainer}>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid item container alignItems='center' justifyContent='center' lg={12}>
                    <Grid item lg={4}>
                        <TextField id="additional" className={classes.textField} onChange={handleChangeText} name="additional" value={values.additional} label="Name/Company Name" variant="outlined" required fullWidth />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField id="email" className={classes.textField} onChange={handleChangeText} name="email" value={values.email} label="Your Email" variant="outlined" required fullWidth />
                        <Typography align='center' variant="h3">{errors.email}</Typography>
                    </Grid>
                    <Grid item lg={12} style={{ display: 'none' }}>
                        <TextField id="question" className={classes.textField} onChange={handleChangeText} name="question" value={values.question} label="Company Name" variant="outlined" required fullWidth />
                    </Grid>
                </Grid>
                <Grid item container alignItems='center' justifyContent='center' >
                    <Button disabled={ableToSubmit} className={classes.submitButton} variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
                </Grid>
            </form>
            <Dialog
                open={showThankYou}
                onClose={handleCloseThankYou}
            >
                <DialogTitle>Thank you for joining the Bear Hands Pro Newsletter!</DialogTitle>
                <DialogActions>
                    <Button variant='contained' color='primary' onClick={handleCloseThankYou}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
};

export default ContactFormForNewsletterComponent;