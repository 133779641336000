import DryerVentOverviewFormState from './DryerVentOverviewForm/state';
import DryerVentConnectorFormState from './DryerVentConnectorForm/state';
import DryerventDuctFormState from './DryerVentDuctForm/state';
import DryerventTerminationFormState from './DryerVentTerminationForm/state';


const state = {
    initState: {
        ...DryerVentOverviewFormState.initState,
        ...DryerVentConnectorFormState.initState,
        ...DryerventDuctFormState.initState,
        ...DryerventTerminationFormState.initState,
    },
    validation: (inspection) => ({
        ...DryerVentConnectorFormState.validation(inspection),
        ...DryerVentConnectorFormState.validation(inspection),
        ...DryerventDuctFormState.validation(inspection),
        ...DryerventTerminationFormState.validation(inspection),
    }),
};

export default state