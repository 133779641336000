import * as Yup from 'yup';
import {
  NA,
  PREFAB,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  YES,

} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabFlashingState: '',
    prefabFlashingVersion: VERSION_PRO.value,
    prefabFlashingLiteIssues: [],
    prefabFlashingLitePhotos: [],
    prefabFlashingEvidence: '',
    prefabFlashingStackWidth: { label: '', value: '' },
    prefabFlashingStackLength: { label: '', value: '' },
    prefabFlashingPhotos: [],
    prefabFlashingCricket: '',
    prefabFlashingOverviewPhoto: []
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.prefabFlashingVersion === VERSION_PRO.value
      ? {
        prefabFlashingState: Yup.string().required(REQUIRED),
        prefabFlashingPhotos: Yup.array().when(
          ['prefabFlashingVersion', 'prefabFlashingState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabFlashingEvidence: Yup.string().when(
          ['prefabFlashingVersion', 'prefabFlashingState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFlashingStackWidth: Yup.object().when(
          [
            'prefabFlashingVersion',
            'prefabFlashingState',
            'prefabFlashingEvidence',
          ],
          {
            is: (version, state, replace) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              replace === YES.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        prefabFlashingStackLength: Yup.object().when(
          [
            'prefabFlashingVersion',
            'prefabFlashingState',
            'prefabFlashingEvidence',
          ],
          {
            is: (version, state, replace) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              replace === YES.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        prefabFlashingCricket: Yup.string().when(
          ['prefabFlashingVersion', 'prefabFlashingState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFlashingOverviewPhoto: Yup.array().when(
          ['prefabFlashingState'],
          {
            is: (state) => state !== NA.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.prefabFlashingVersion === VERSION_LITE.value ?
        {
          prefabFlashingState: Yup.string().required(REQUIRED),
          prefabFlashingOverviewPhoto: Yup.array().when(
            ['prefabFlashingState'],
            {
              is: (state) => state !== NA.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          prefabFlashingLiteIssues: Yup.array().when(
            ['prefabFlashingState', 'prefabFlashingVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          prefabFlashingLitePhotos: Yup.array().when(
            ['prefabFlashingState', 'prefabFlashingVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          )
        } : {}),
  }),
};

export default state