export const ENERBANK = {
    label: 'EnerBank',
    value: 'ENERBANK'
};

export const GREENSKY = {
    label: 'GreenSky',
    value: 'GREENSKY'
};

export const HEARTH_FINANCING = {
    label: 'Hearth',
    value: 'HEARTH_FINANCING'
}

export const CUSTOM_FINANCING = {
    label: 'Custom Financing',
    value: 'CUSTOM_FINANCING'
};