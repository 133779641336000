import * as Yup from 'yup';
import {
  PREFAB,
  REPLACE,
  RESEAL,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  YES
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabCapState: '',
    prefabCapVersion: VERSION_PRO.value,
    prefabCapLiteIssues: [],
    prefabCapLitePhotos: [],
    prefabCapMissingDamaged: '',
    prefabCapPhotos: [],
    prefabCapType: '',
    prefabCollarProposal: '',
    prefabCollarPhotos: [],
    prefabCollarInDiameter: '',
    prefabCollarOutDiameter: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.prefabCapVersion === VERSION_PRO.value 
      ? {
        prefabCapState: Yup.string().required(REQUIRED),
        prefabCapMissingDamaged: Yup.string().when(
          ['prefabCapVersion', 'prefabCapState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabCapPhotos: Yup.array().when(
          ['prefabCapVersion', 'prefabCapMissingDamaged', 'prefabCapState'],
          {
            is: (version, damage, state) =>
              version === VERSION_PRO.value &&
              damage === YES.value &&
              state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabCapType: Yup.string().when(
          ['prefabCapVersion', 'prefabCapState', 'prefabCapMissingDamaged'],
          {
            is: (version, state, missing) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              missing === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabCollarProposal: Yup.string().when(
          ['prefabCapVersion', 'prefabCapState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabCollarPhotos: Yup.array().when(
          ['prefabCapVersion', 'prefabCapState', 'prefabCollarProposal'],
          {
            is: (version, state, repair) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [REPLACE.value, RESEAL.value].includes(repair),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabCollarInDiameter: Yup.number().when(
          ['prefabCapVersion', 'prefabCapState', 'prefabCollarProposal'],
          {
            is: (version, state, repair) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              repair === REPLACE.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabCollarOutDiameter: Yup.number().when(
          ['prefabCapVersion', 'prefabCapState', 'prefabCollarProposal'],
          {
            is: (version, state, repair) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              repair === REPLACE.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value && inspection.data.prefabCapVersion === VERSION_LITE.value ? {
        prefabCapState: Yup.string().required(REQUIRED),
        prefabCapLitePhotos: Yup.array().when(
          ['prefabCapState', 'prefabCapVerion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        )
      } : {}),
  }),
};

export default state
