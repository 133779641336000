import * as Yup from 'yup';
import {
  FUEL_GAS,
  UNSATISFACTORY,
  YES,
  SATISFACTORY,
  VERSION_PRO,
  VERSION_LITE,
  UTILITY_CHIMNEY,
  FREESTANDING
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO
} from 'components/app/InspectionForm/stateConstants';

const state =  {
  initState: {
    gasPilotState: '',
    gasPilotThermocoupleReplace: '',
    gasPilotThermocouplePhotos: [],
    gasPilotThermopileReplace: '',
    gasPilotThermopilePhotos: [],
    gasPilotHoodAndTubingReplace: '',
    gasPilotHoodAndTubingPhotos: [],
    gasPilotIgnitorReplace: '',
    gasPilotIgnitorPhotos: [],
    gasPilotPhotos: [],
    gasPilotVersion: VERSION_PRO.value,
    gasPilotLiteIssues: [],
    gasPilotLitePhotos: [],
    gasPilotVoltageOff: '',
    gasPilotVoltageOn: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewStructureFuelType === FUEL_GAS.value
      && inspection.data.overviewApplianceType !== UTILITY_CHIMNEY.value
      && inspection.data.overviewApplianceType !== FREESTANDING.value
      ? {
        gasPilotState: Yup.string().required(REQUIRED),
        gasPilotLitePhotos: Yup.array().when(
          ['gasPilotState', 'gasPilotVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED),
            otherwise: Yup.array().notRequired()
          }
        ),
        gasPilotThermocoupleReplace: Yup.string().when(['gasPilotState', 'gasPilotVersion'],
          {
            is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired()
          }),
        gasPilotThermocouplePhotos: Yup.array().when(
          ['gasPilotState', 'gasPilotThermocoupleReplace', 'gasPilotVersion'],
          {
            is: (state, replace, version) =>
              state === UNSATISFACTORY.value && replace === YES.value && version === VERSION_PRO.value,
            then: Yup.array()
              .required(REQUIRED)
              .min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired()
          }
        ),
        gasPilotThermopileReplace: Yup.string().when(
          ['gasPilotState', 'gasPilotVersion'],
          {
            is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired()
          }),
        gasPilotThermopilePhotos: Yup.array().when(
          ['gasPilotState', 'gasPilotThermopileReplace', 'gasPilotVersion'],
          {
            is: (state, replace, version) =>
              state === UNSATISFACTORY.value && replace === YES.value && version === VERSION_PRO.value,
            then: Yup.array()
              .required(REQUIRED)
              .min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired()
          }
        ),
        gasPilotHoodAndTubingReplace: Yup.string().when(
          ['gasPilotState', 'gasPilotVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired()
        }),
        gasPilotHoodAndTubingPhotos: Yup.array().when(
          ['gasPilotState', 'gasPilotHoodAndTubingReplace', 'gasPilotVersion'],
          {
            is: (state, replace, version) =>
              state === UNSATISFACTORY.value && replace === YES.value && VERSION_PRO.value,
            then: Yup.array()
              .required(REQUIRED)
              .min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired()
          }
        ),
        gasPilotIgnitorReplace: Yup.string().when(
          ['gasPilotState', 'gasPilotVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired()
        }),
        gasPilotIgnitorPhotos: Yup.array().when(
          ['gasPilotState', 'gasPilotIgnitorReplace', 'gasPilotVersion'],
          {
            is: (state, replace, version) =>
              state === UNSATISFACTORY.value && replace === YES.value && version === VERSION_PRO.value,
            then: Yup.array()
              .required(REQUIRED)
              .min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired()
          }
        ),
        gasPilotPhotos: Yup.array().when(
          ['gasPilotState', 'gasPilotVersion'], {
          is: (state, version) => state === SATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        })
      }
      : {})
  })
};

export default state