import * as Yup from 'yup';

const state = {
    initState: {
        overallCondition: ''
    },
    validation: (inpsection) => ({
        overallCondition: Yup.string().notRequired(),
    })
};

export default state