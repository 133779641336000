
const styles = (theme) => ({
    root: {
        maxWidth: 300,
        margin: theme.spacing(0, 1),
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    media: {
        height: 140,
    },
    cardContent: {
        // backgroundColor: '#f4f4f4',
        height: '100%'
    },
});

export default styles