export const SERVICE_FIREBOX_REBUILD = {
  label: 'Rebuild Firebox',
  value: 'SERVICE_FIREBOX_REBUILD',
};
export const SERVICE_PARGE_LINTEL = {
  label: 'Parge Lintel',
  value: 'SERVICE_PARGE_LINTEL',
};
export const SERVICE_FIREBRICK_INSTALL = {
  label: 'Install Replacement Firebricks',
  value: 'SERVICE_FIREBRICK_INSTALL',
};
export const SERVICE_REPOINTING_FIREBOX = {
  label: 'Repointing Firebox',
  value: 'SERVICE_REPOINTING_FIREBOX'
};
