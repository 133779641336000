import * as Yup from 'yup';
import {
  INSERT,
  UNSATISFACTORY,
  VERSION_PRO,
} from 'components/app/InspectionForm/constants';
import { REQUIRED } from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    insertEpaState: '',
    insertEpaLiteIssues: [],
    insertEpaLitePhotos: [],
    insertEpaVersion: VERSION_PRO.value,
    insertEpaDataTagMissing: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === INSERT.value &&
    inspection.data.insertEpaVersion === VERSION_PRO.value
      ? {
          insertEpaState: Yup.string().required(REQUIRED),
          insertEpaDataTagMissing: Yup.string().when('insertEpaState', {
            is: UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }),
        }
      : {}),
  }),
};

export default state