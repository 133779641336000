import React from 'react';

import {
  Grid,
  Typography,
  makeStyles,
  Container,
  Hidden
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';
import CalendlyPopUpComponent from '../components/CalendlyComponent/index.js';
import PopUpButtonComponent from '../components/popUpButtonComponent';
import './cssstyles.css';
import ContactFormForNewsletterComponent from '../ContactFormNewsletters';
const useStyles = makeStyles(styles);

const FrontPage = ({ ...rest }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.heroWrapper}>
        <Grid
          container
          justifycontent="center"
          alignitems="center"
          className={classes.hero}
        >
          <CalendlyPopUpComponent />

          <Container style={{position: 'relative'}}>
            <Grid container style={{position: 'relative', top: '25%'}}>
              <Grid item xs={12} lg={8}>
                <Typography
                  className={(classes.heroTagline)}
                  variant="h1"
                >
                  The Future of Inspecting Chimneys
                </Typography>
                <Typography className={classes.heroText} variant="h5" style={{ marginBottom: '20px', fontSize: '25px' }}>
                  Instant Reports and Proposals
                </Typography>

                {/* Button for calendly */}
                <PopUpButtonComponent />

              </Grid>
            </Grid>

          </Container>
        </Grid>
      </Grid>
      <Grid
        container
        justifycontent="center"
        alignitems="center"
        className={classes.darkBlueBg}
      >
        <Grid item xs={12} md={4} className='heightForChecklist'>
          <Typography className='subtitle'>
          ✓ Save Time
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className='borderLinesForCheckList'>
        <Typography className='subtitle'>
          ✓ Boost Sales
        </Typography>
        </Grid>
        <Grid item xs={12} md={4} className='heightForChecklist'>
        <Typography className='subtitle'>
          ✓ Engage Customers
        </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifycontent="center"
        alignitems="center"
      >
        <Container>
          <Grid container style={{ paddingTop: '40px', paddingBottom: '40px', alignItems: 'center' }} >
            <Grid item xs={12} sm={6}>
              <img
                className={classes.imageBHP}
                alt="Bear Hands Pro's owner Mike Fasy stressing over all the paper inspection reports, leading him to the idea of Bear Hands Pro!"
                src="/static/home/mikeBHP.png"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h2">Are You...</Typography>
              <Typography variant="h5" className={classes.tldr}>• Spending hours on inspection reports?</Typography>
              <Typography variant="h5" className={classes.tldr}>• Struggling to regulate your company's practices?</Typography>
              <Typography variant="h5" className={classes.tldr}>• Hurting your sales with mediocre proposals?</Typography>
              <br/>
              <PopUpButtonComponent/>
            </Grid>

          </Grid>
        </Container>
      </Grid>

      <Grid container className={classes.middleSection} style={{paddingTop: '40px', paddingBottom: '30px'}}>
        <Container align="center">
          <Typography variant="h2">You need the right tools for the job!</Typography>
          <br />
          <Typography variant="h5" style={{marginBottom: '5px'}}>•  Designed by chimney professionals with 20+ years of industry experience •</Typography>
          <Typography variant="h5" style={{marginBottom: '5px'}}>•  Full-time staff with the goal of helping you succeed •</Typography>
          <Typography variant="h5" style={{marginBottom: '5px'}}>•  Trusted by companies nationwide to perform tens of thousands of reports annually •</Typography>
        </Container>

        <Container
        justifycontent="space-between"
        alignitems="center">
        <Grid container justifycontent="center" alignitems="center">
          <Grid
            item
            container
            className={classes.whatIsBHPContainer}
            xs={12}
          >
            <Grid item xs={12} sm={4}>
              <img className={classes.imageBHP} alt="BHP Testimonial #1: Hands down one of the best decisions I have made in my company... The inspection forms look great and the customers love them. 5 star review from Logan R. Arkansas Chimney Tec and Masonry in Springdale, Ar" src="./static/home/TestimonialsBHP1.png" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <img className={classes.imageBHP} alt="BHP Testimonial #2: It really guides you through the process and gives you a great report at the end. Very thorough and really helps show the customers what exactly is going on in their system. 5 star review from Austin H. Mike's Mobile Screen and Chimney Service in Sacramento, CA." src="./static/home/TestimonialsBHP2.png" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <img className={classes.imageBHP} alt="BHP Testimonial #3: We do inspections on every call and Bear Hands Pro makes it easy, efficient, and professional. Customers love it. My office loves it. 5 Star Review from Kyle J. Alpine Chimney Service in Collinswood, NJ." src="./static/home/TestimonialsBHP3.png" />
            </Grid>

          </Grid>

          <Grid item xs={12} align="center" className='bottomPadding'>
            {/* Button for calendly */}
            <PopUpButtonComponent />
          </Grid>

        </Grid>
      </Container>


      </Grid>


      <Grid container className={classes.bradleyBear}>
        <Container justifycontent='center'>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Typography className={classes.darkBlueHeaderText}>Getting Started is Simple!</Typography>
                <Grid item xs={12} className={classes.step1}>
                  <div className={classes.sameSize}>
                    <PopUpButtonComponent />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <img className={classes.imageSteps} alt="Onboard Your Company! We'll set your team up for success!" src="./static/home/step2success.png" />
                </Grid>
                <Grid item xs={12} className={classes.extraMarginBottom}>
                  <img className={classes.imageSteps} alt="Let the software do the hard work! More free time = Happiness!" src="./static/home/step3Smiles.png" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>


      <Grid container className={classes.darkBlueBgwithPadding}>
        <Container>
          <Hidden smDown>
          <Grid container>
            <Grid item xs={12} md={6} style={{marginTop:'15px'}}>
              <Typography variant="h3" className={classes.tldrHealineh3} style={{paddingRight: '10px'}}>
                How BHP will help your company!
              </Typography>
              <Typography variant="h5" className={classes.tldrWhite}>
              Check out this video for more information on why so many companies are switching to Bear Hands Pro.
              </Typography>
              <Typography variant="h5" className={classes.tldrWhite} style={{marginTop: '40px', marginBottom: '40px'}}>
              Check out an example <RouterLink target="_blank" to='/static/guides/Example_Report.pdf' style={{textDecoration: 'underline', color: '#FFFFFF'}}>report</RouterLink> and <RouterLink target="_blank" to='/static/guides/Example_Proposal.pdf' style={{textDecoration: 'underline', color: '#FFFFFF'}}>proposal</RouterLink>!
              </Typography>

              <PopUpButtonComponent />
            </Grid>
            <Grid item justifycontent='center' xs={12} md={6}>
              <iframe width='100%' height="300" src="https://www.youtube.com/embed/2Q1TfLlv42g" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Grid>
          </Grid>
          </Hidden>

          <Hidden mdUp>
          <Grid container style={{marginTop:'15px', marginBottom: '20px'}}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.tldrHealineh3} style={{paddingRight: '10px'}}>
                How BHP will help your company!
              </Typography>
              <Typography variant="h5" className={classes.tldrWhite}>
              Check out this video for more information on why so many companies are switching to Bear Hands Pro.
              </Typography>
            </Grid>
            <Grid item justifycontent='center' xs={12}>
              <iframe width='100%' height="300" src="https://www.youtube.com/embed/2Q1TfLlv42g" title="YouTube video player" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h5" className={classes.tldrWhite} style={{textAlign: 'center'}}>
              Check out an example <RouterLink target="_blank" to='/static/guides/Example_Report.pdf' style={{textDecoration: 'underline', color: '#FFFFFF'}}>report</RouterLink> and <RouterLink target="_blank" to='/static/guides/Example_Proposal.pdf' style={{textDecoration: 'underline', color: '#FFFFFF'}}>proposal</RouterLink>.
              </Typography>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <PopUpButtonComponent />
            </Grid>

          </Grid>
          </Hidden>
        </Container>
      </Grid>

      <Container>
        <Grid container>
          <Grid item xs={12} className="center" style={{paddingTop: '20px'}}>
            <Typography variant='h4'>Sign up for our Newsletter to stay up to date with industry news, new features and FREE chimney resources!</Typography>
          </Grid>
          <Grid item xs={12}>
            <ContactFormForNewsletterComponent />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default publicComponent(FrontPage);
