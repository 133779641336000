// Changes to this file need to be mirrored in `server/common/rbac/permissions.js`
import actions from './actions';
import roles from './roles';

const { SUPER_ADMIN, ADMINISTRATOR, OFFICE, TECHNICIAN } = roles;

const {
  ORGANIZATION_CREATE,
  ORGANIZATION_EDIT,
  ORGANIZATION_VIEW,
  ADMIN_SETTINGS_EDIT,
  ADMIN_SETTINGS_VIEW,
  INSPECTION_ARCHIVE,
  INSPECTION_CREATE,
  INSPECTION_EDIT,
  INSPECTION_VIEW,
  USER_CREATE,
  DASHBOARD_VIEW,
  USER_EDIT,
  USER_VIEW,
  PULL_SHEET_VIEW,
  SEND_COPY_CLIENT,
  ADD_CLIENT,
  EDIT_RESPONSE,
  NOT_SUPER,
  TECH_VIEW,
  BILLING_VIEW,
  CREATE_TECH,
} = actions;

// These are initial values and more research and discussion needs to be done to finalize the roles of different employees
const permissions = {
  [SUPER_ADMIN.value]: {
    [ORGANIZATION_CREATE]: true,
    [ORGANIZATION_EDIT]: true,
    [ORGANIZATION_VIEW]: true,
    [ADMIN_SETTINGS_EDIT]: true,
    [ADMIN_SETTINGS_VIEW]: true,
    [INSPECTION_ARCHIVE]: true,
    [INSPECTION_CREATE]: true,
    [INSPECTION_EDIT]: true,
    [INSPECTION_VIEW]: true,
    [USER_CREATE]: true,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: true,
    [USER_VIEW]: true,
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: true,
    [ADD_CLIENT]: true,
    [EDIT_RESPONSE]: true,
    [NOT_SUPER]: false,
    [TECH_VIEW]: true,
    [BILLING_VIEW]: true,
    [CREATE_TECH]: true,
  },
  [ADMINISTRATOR.value]: {
    [ORGANIZATION_CREATE]: false,
    [ORGANIZATION_EDIT]: false,
    [ORGANIZATION_VIEW]: false,
    [ADMIN_SETTINGS_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [ADMIN_SETTINGS_VIEW]: true,
    [INSPECTION_ARCHIVE]: true,
    [INSPECTION_CREATE]: true,
    [INSPECTION_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [INSPECTION_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_CREATE]: true,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [BILLING_VIEW]: true,
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: true,
    [ADD_CLIENT]: true,
    [EDIT_RESPONSE]: true,
    [NOT_SUPER]: true,
    [TECH_VIEW]: true,
    [CREATE_TECH]: true,
  },
  [OFFICE.value]: {
    [ORGANIZATION_CREATE]: false,
    [ORGANIZATION_EDIT]: false,
    [ORGANIZATION_VIEW]: false,
    [ADMIN_SETTINGS_EDIT]: false,
    [ADMIN_SETTINGS_VIEW]: false,
    [INSPECTION_ARCHIVE]: true,
    [INSPECTION_CREATE]: false,
    [INSPECTION_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [INSPECTION_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_CREATE]: false,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: false,
    [USER_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [BILLING_VIEW]: false,
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: true,
    [ADD_CLIENT]: true,
    [EDIT_RESPONSE]: true,
    [NOT_SUPER]: true,
    [TECH_VIEW]: false,
    [CREATE_TECH]: false,
  },
  [TECHNICIAN.value]: {
    [ORGANIZATION_CREATE]: false,
    [ORGANIZATION_EDIT]: false,
    [ORGANIZATION_VIEW]: false,
    [ADMIN_SETTINGS_EDIT]: false,
    [ADMIN_SETTINGS_VIEW]: false,
    [INSPECTION_ARCHIVE]: false,
    [INSPECTION_CREATE]: true,
    [INSPECTION_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [INSPECTION_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_CREATE]: false,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: false,
    [USER_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: true,
    [ADD_CLIENT]: false,
    [EDIT_RESPONSE]: false,
    [NOT_SUPER]: true,
    [TECH_VIEW]: true,
    [BILLING_VIEW]: false,
    [CREATE_TECH]: false,
  },
};

export default permissions;
