import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  OPEN_FIREBOX,
  INSERT,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryGrateState: '',
    masonryGrateVersion: VERSION_PRO.value,
    masonryGrateLiteIssues: [],
    masonryGrateLitePhotos: [],
    masonryGratePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && inspection.data.fledge === false
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryGrateState: Yup.string().required(REQUIRED),
        masonryGratePhotos: Yup.array().when(
          [
            'masonryGrateVersion',
            'masonryGrateState',
          ],
          {
            is: (version, state,) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryGrateLitePhotos: Yup.array().when(
          ['masonryGrateState', 'masonryGrateVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state