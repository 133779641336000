import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const Clients = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: Adding Your Client List</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4">This video is part of our series of getting started videos! In this video we cover how to add your client list<br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/gjDU52x0C2c" title="Getting started: Adding your client list." frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/gjDU52x0C2c" title="Getting Started: Adding your client list." frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: How to Add your client list.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                On the client page you can add one a single client by filling out form on the left
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add clients by filling out form on left.' src='/static/tutorials/clients/singleClient.png' width='100%' />

                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                Or you can upload your client list as a CSV (Comma Seperated Value) sheet by dragging it or clicking on box on right. You need to follow the directions.
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add CLients by csv' src='/static/tutorials/clients/uploadCSV.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                You need to make sure the headers of your csv file match the headers specified.
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add CLients by csv' src='/static/tutorials/clients/headersMustMatch.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                Your CSV file should look something like this; make sure your headers match. (this one only has 5 clients yours can have 50 thousand +, but it will take longer, longer the list is.)
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add CLients by csv' src='/static/tutorials/clients/exampleHeaders.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                To Get a CSV you can upload an xcell document into google sheets and then download as CSV.
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add CLients by csv' src='/static/tutorials/clients/selectCSV.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default Clients;