import React from 'react';

import {
    Grid,
    makeStyles,
    Typography,
    Card,

    CardContent,
} from '@material-ui/core';

import StarIcon from '@material-ui/icons/Star';

import styles from './styles';

const useStyles = makeStyles(styles);


const ReviewCard = ({ review, owner, business, location }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container justifyContent='center' alignItems='center' direction='column'>
                    <Grid className={classes.items} item>
                        <Typography className={classes.cardText} variant='body1'>
                            {review}
                        </Typography>
                    </Grid>
                    <Grid className={classes.starItems} item>
                        <StarIcon className={classes.stars} /><StarIcon className={classes.stars} /><StarIcon className={classes.stars} /><StarIcon className={classes.stars} /><StarIcon className={classes.stars} />
                    </Grid>
                    <hr className={classes.whiteLine} />
                    <Grid item>
                        <Typography className={classes.cardText} variant='subtitle1'>
                            {owner}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.cardText2} variant='subtitle2'>
                            {business}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.cardText2} variant='subtitle2'>
                            {location}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReviewCard;