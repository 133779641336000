export const PRODUCT_CHASE_CAP = {
    label: 'Chase Cap',
    value: 'PRODUCT_CHASE_CAP'
};
export const PRODUCT_CHASE_CAP_PLUS_CHASE = {
    label: 'Chase Cap w/ Chase Cover',
    value: 'PRODUCT_CHASE_CAP_PLUS_CHASE'
};
export const PRODUCT_CHASE_CAP_PLUS_CHASE_DIFF = {
    label: 'Chase Cap w/ Chase Cover',
    value: 'PRODUCT_CHASE_CAP_PLUS_CHASE_DIFF'
};