import colors from 'theme/palette';

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(15),
    marginBotton: theme.spacing(10),
    fontWeight: 800,
    lineHeight: '4.2rem',
    fontSize: '4rem',
  },
  contentBlock: {
    marginTop: theme.spacing(5),
  },
  image: {
    maxWidth: '100%',
  },
  contentBlockLeft: {
    marginTop: theme.spacing(5),
    backgroundColor: colors.lightBlue,
    marginBottom: theme.spacing(5),
    maxWidth: '605px',
  },
  contentBlockRight: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    backgroundColor: colors.white,
    maxWidth: "100%"
  },
  menu: {
    marginTop: theme.spacing(5),
  },
  menuItem: {
    margin: theme.spacing(2),
    cursor: "pointer",
  },
  menuItemSelected: {
    margin: theme.spacing(2),
    cursor: "pointer",
    textDecoration: "underline"
  },
  titleBox: {
    marginBottom: theme.spacing(2),
  },
  boxTextLeft: {
    color: colors.white,
  },
  boxTextRight: {
    color: colors.darkBlue,
    margin: theme.spacing(0, 0, 0, 2)
  },
  boxTextRightTitle: {
    marginTop: theme.spacing(3),
    color: colors.darkBlue,
  },
  boxTextRightSubtext: {
    marginBottom: theme.spacing(2),
    color: colors.darkBlue,
  },
  faqContentBlock: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    backgroundColor: colors.white,
  },
  titleBar: {
    backgroundColor: colors.darkBlue,
    padding: theme.spacing(2),
    height: '65px',

  },
  titleBarTan: {
    backgroundColor: colors.lightTan,
    padding: theme.spacing(2),
    height: '65px',
    [theme.breakpoints.down('sm')]: {
      height: '90px'
    }
  },
  titleBarBiggerMobile: {
    backgroundColor: colors.darkBlue,
    padding: theme.spacing(2),
    height: '65px',
    [theme.breakpoints.down('sm')]: {
      height: '90px'
    }
  },
  faqText: {
    color: colors.white,
  },
  faqTextTan: {
    color: colors.black,
  },
  faqQuestion: {
    padding: theme.spacing(2),
  },
  horizontalList: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  }
});

export default styles