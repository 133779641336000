export const SERVICE_CROWN_REBUILD = {
  label: 'Crown Rebuild',
  value: 'SERVICE_CROWN_REBUILD',
};
export const SERVICE_CROWN_SEAL = {
  label: '1/8" Crown Coat',
  value: 'SERVICE_CROWN_SEAL',
};
export const SERVICE_LOWER_CROWN_REBUILD = {
  label: 'Lower Crown Rebuild',
  value: 'SERVICE_LOWER_CROWN_REBUILD',
};
