import React from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';

const CreateOrg = ({ showSignUpComp, handleClose, classes, company, handleChangeCompany, handleBack, enableNext, handleNext }) => {


    return (
        <Dialog open={showSignUpComp} onClose={handleClose} fullWidth aria-labelledby='sign-up-company-form-title'>
            <DialogTitle className={classes.dialogTitleContainer} id='sign-up-company-form-title'><Typography className={classes.dialogTitle} variant='h2' component='p'>Create Your Organization 2/4</Typography></DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    Company public contact info. (All this information is changable inside of the app.)
          </DialogContentText>
                <Typography variant='h3'>Company</Typography>
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='company_name'
                    name='name'
                    label='Name'
                    value={company.name}
                    className={classes.formFeild}
                    onChange={handleChangeCompany}
                    type='text'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='company_email'
                    name='email'
                    value={company.email}
                    className={classes.formFeild}
                    onChange={handleChangeCompany}
                    label='Email Address'
                    type='email'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='company_phone'
                    name='phone'
                    value={company.phone}
                    className={classes.formFeild}
                    onChange={handleChangeCompany}
                    label='Phone Number'
                    type='tel'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='company_address'
                    name='address'
                    value={company.address}
                    className={classes.formFeild}
                    onChange={handleChangeCompany}
                    label='Address'
                    type='text'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='company_city'
                    name='city'
                    value={company.city}
                    className={classes.formFeild}
                    onChange={handleChangeCompany}
                    label='City'
                    type='text'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='company_state'
                    name='state'
                    value={company.state}
                    className={classes.formFeild}
                    onChange={handleChangeCompany}
                    label='State'
                    type='text'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='company_zip'
                    name='zip'
                    value={company.zip}
                    className={classes.formFeild}
                    onChange={handleChangeCompany}
                    label='Zip'
                    type='number'
                    fullWidth
                />

            </DialogContent>
            <DialogActions>
                <Button variant='outlined' id='toAdmin' onClick={handleBack} color='primary'>
                    back
          </Button>
                <Button variant='outlined' disabled={!enableNext} id='toTerms' onClick={handleNext} color='primary'>
                    Next
          </Button>
            </DialogActions>
        </Dialog>
    );
};
export default CreateOrg;