import * as Yup from 'yup';
import { FREESTANDING } from 'components/app/InspectionForm/constants';
import { REQUIRED } from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    freestandingVentPipeProState: '',
    freestandingVentPipeProVersion: '',
    freestandingVentPipeProLiteIssues: [],
    freestandingVentPipeProLitePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === FREESTANDING.value
      ? {
          freestandingVentPipeProState: Yup.string().required(REQUIRED),
        }
      : {}),
  }),
};

export default state;
