import React from 'react';


const Terms = () => {


    return (
        <div>
            <h1>Terms page</h1>
        </div>
    )
};


export default Terms;