
const styles = (theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 'calc(100vh - 64px)',
        flexGrow: 1,
    },
    SideMenu: {

        minHeight: 'calc(100vh - 64px)',
    }

});

export default styles