export const SERVICE_BUILD_BRICKWORK = {
  label: 'Build Brickwork',
  value: 'SERVICE_BUILD_BRICKWORK',
};
export const SERVICE_REPOINT_BRICKWORK = {
  label: 'Repoint Brickwork',
  value: 'SERVICE_REPOINT_BRICKWORK',
};
export const SERVICE_STRUCTURE_REMOVAL_EASY = {
  label: 'Structural Removal Level I',
  value: 'SERVICE_STRUCTURE_REMOVAL_EASY',
};
export const SERVICE_STRUCTURE_REMOVAL_HARD = {
  label: 'Structural Removal Level II',
  value: 'SERVICE_STRUCTURE_REMOVAL_HARD',
};
export const SERVICE_STACK_SEAL = {
  label: 'Seal Stack Cracks',
  value: 'SERVICE_STACK_SEAL',
};
export const SERVICE_EFFLORESCENCE_REMOVAL = {
  label: 'Remove Efflorescence Deposits',
  value: 'SERVICE_EFFLORESCENCE_REMOVAL',
};

export const SERVICE_CHIMNEY_STACK_DEMO = {
  label: 'Demo & Disposal of chimney stack',
  value: 'SERVICE_CHIMNEY_STACK_DEMO',
};

export const SERVICE_CHIMNEY_STACK_WATERPROOF = {
  label: 'Waterproof chimney exterior',
  value: 'SERVICE_CHIMNEY_STACK_WATERPROOF'
}
