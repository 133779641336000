import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const CreateUsers = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: Creating Users</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4">This video is the part of our series of getting started videos. In this we cover how to create, drop, and edit user accounts, and what they do.  <br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/WSSfm6Vbl04" title="Getting Started: Creating Users" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/WSSfm6Vbl04" title="Getting Started: Creating Users" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: Everything you need to know about user accounts!
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                            <Typography variant='h6'>
                                On your admin settings page, the user tile will show you all the user accounts your company has.
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h4'>
                                Bear Hans Pro has three types of user accounts:
                            </Typography>
                            <ul className={classes.nested}>
                                <Typography variant='h4' component='li'>Admin</Typography>
                                <ul className={classes.nestedDouble}>
                                    <li>Your company gets one with your dashboard</li>
                                    <li>Unrestricted can do anything</li>
                                    <li>Only account that can access admin settings page</li>
                                </ul>
                                <Typography variant='h4' component='li'>Office</Typography>
                                <ul className={classes.nestedDouble}>
                                    <li>Create as many as you want they are free!</li>
                                    <li>Can view inspections</li>
                                    <li>Can add, edit, and delete responses</li>
                                    <li>Can add clients</li>
                                    <li>Can't edit or start inspections</li>
                                </ul>
                                <Typography variant='h4' component='li'>Technician</Typography>
                                <ul className={classes.nestedDouble}>
                                    <li>Once out of trial period costs $49 a month</li>
                                    <li>Start and edit inspections</li>
                                </ul>
                            </ul>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='create your admin account' src='/static/tutorials/creatingUsers/userTile.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                To create a new user account click the plus in the top right corner, or it will be under the three dots in top right corner. You have to set a password for the account your creating, they can change that password once they log in. And don't worry all this info is editable.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='create your organization' src='/static/tutorials/creatingUsers/AddUser.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                You can: edit any user information, send a password reset, and delete the user account by clicking the three dots under actions.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='You can edit the user account by clicking the three dots.' src='/static/tutorials/creatingUsers/edit-delete-user.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                This section will show you have many tech accounts you have and how many tech accounts you have paid for. At the end of your pay period it won't charge you for any excess tech accounts the number on right will match the number on the left.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='this is where you can see your number of tech accounts paid for.' src='/static/tutorials/creatingUsers/yourTechAccounts.png' width='100%' />
                        </Grid>
                        <Grid item lg={12} className={classes.endMessage}>
                            <Typography align='center' variant='h3'>
                                Remember you can create as many tech accounts as you want durring your trial period, but once that ends you have to pay for them.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default CreateUsers;