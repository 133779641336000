import React from 'react';
import { Grid, Typography, makeStyles, Container } from '@material-ui/core';
import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';

const useStyles = makeStyles(styles);

const OurStory = () => {
  const classes = useStyles();

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={12}>
          <Typography variant="h1" align="center" className={classes.title}>
            Our Story
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-around"
          lg={12}
          className={classes.contentBlock}
        >
          <Grid item container justifyContent="center" alignItems="center" lg={5}>
            <Typography align="center" variant="h4">
              BHP was founded out of necessity.
            </Typography>
            <Typography align="center" variant="h5">
              As we grew from a single operator to a multi-truck company it
              created more work and it created more problems. We found ourselves
              spending long nights going over chimney inspection reports which
              drastically cut into our profit margins. We were doing more work
              than ever but making less money.
            </Typography>
            <Typography align="center" variant="h5">
              To keep our company consistent our techs would have to carry
              around a stack of carbon paper invoices, a chimney reference
              guide, a vendor book, a price list and somehow juggle their tools
              too. This created a near-impossible situation for a tech to
              complete their job efficiently and correctly-- more time spent at
              a job shuffling through paperwork and tools meant less jobs in a
              day.
            </Typography>
          </Grid>
          <Grid item lg={5}>
            <img
              className={classes.image}
              alt="person with stack of paper head in hands"
              src="/static/landing/manWithPaper.jpg"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-around"
          lg={12}
          className={classes.contentBlock}
        >
          <Grid item container alignItems="center" lg={5}>
            <img
              className={classes.image}
              alt="person with stack of paper head in hands"
              src="/static/landing/stackPapers.jpg"
            />
          </Grid>
          <Grid item container alignItems="center" lg={5}>
            <Typography align="center" variant="h5">
              There many times we underquoted a liner, ordered the wrong size
              liner or didn’t even provide a quote for one because our tech was
              too confused to figure it out. So, at the end of the day, we would
              have stacks of barely legible, soot-smudged reports that were
              almost impossible to make sense of. There was no reference to fix
              these reports as pictures can’t be put on a paper form and
              accompanying measurements always seemed to be separate or missing
              from the report.
            </Typography>
            <Typography align="center" variant="h5">
              We were certain we couldn’t grow doing business like this-- there
              had to be a better way! After countless hours searching and
              testing different options from inspection report companies, we
              found there was not a better way. In reality, most options were no
              better than the paper forms we were trying to leave behind.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-around"
          lg={12}
          className={classes.contentBlock}
        >
          <Grid item container alignItems="center" lg={5}>
            <Typography align="center" variant="h5">
              If there was no better way, we had to create a better way. We
              hired a full time web developer and through long nights, many
              iterations, constant feedback from technicians and thousands of
              REAL reports in the field we eventually cultivated Bear Hands Pro.
            </Typography>
            <Typography align="center" variant="h5">
              Since 2014 we’ve been lucky enough to grow our revenue at 40%
              every year. We have 17 trucks and we are grossing $4 million this
              year with plans to grow another 40% in 2020! The best part is, we
              spend 0 hours a month fixing chimney inspection reports.
            </Typography>
          </Grid>
          <Grid item lg={5}>
            <img
              className={classes.image}
              alt="person with stack of paper head in hands"
              src="/static/landing/techWithPad.jpg"
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default publicComponent(OurStory);
