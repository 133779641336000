import * as Yup from "yup";
import {
  PREFAB,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
} from "components/app/InspectionForm/constants";
import { REQUIRED, VALID_ONE_PHOTO } from "components/app/InspectionForm/stateConstants";

const state = {
  initState: {
    prefabCrawlSpaceState: "",
    prefabCrawlSpaceVersion: VERSION_PRO.value,
    prefabCrawlSpaceLiteIssues: [],
    prefabCrawlSpacePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
    inspection.data.prefabCrawlSpaceVersion === VERSION_LITE.value
      ? {
          prefabCrawlSpaceState: Yup.string().required(REQUIRED),
          prefabCrawlSpacePhotos: Yup.array().when("prefabCrawlSpaceState", {
            is: UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }),
        }
      : {}),
  }),
};

export default state;
