import * as Yup from 'yup';
import {
  PREFAB,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  YES,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabHearthState: '',
    prefabHearthVersion: VERSION_PRO.value,
    prefabHearthLiteIssues: [],
    prefabHearthLitePhotos: [],
    prefabHearthPhotos: [],
    prefabHearthRepoint: '',
    prefabHearthRepointArea: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.prefabHearthVersion === VERSION_PRO.value
      ? {
        prefabHearthState: Yup.string().required(REQUIRED),
        prefabHearthPhotos: Yup.array().when(
          ['prefabHearthVersion', 'prefabHearthState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabHearthRepoint: Yup.string().when(
          ['prefabHearthVersion', 'prefabHearthState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabHearthRepointArea: Yup.string().when(
          ['prefabHearthVersion', 'prefabHearthState', 'prefabHearthRepoint'],
          {
            is: (version, state, repoint) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              repoint === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.prefabHearthVersion === VERSION_LITE.value ?
        {
          prefabHearthState: Yup.string().required(REQUIRED),
          prefabHearthLitePhotos: Yup.array().when(
            ['prefabHearthState', 'prefabHearthVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          )
        } : {}),
  }),
};

export default state