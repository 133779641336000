export const PRODUCT_THERMOCOUPLE = {
  label: 'Thermocouple',
  value: 'PRODUCT_THERMOCOUPLE'
};
export const PRODUCT_THERMOPILE = {
  label: 'Thermopile',
  value: 'PRODUCT_THERMOPILE'
};
export const PRODUCT_GAS_LIMITER = {
  label: 'Gas Limiter',
  value: 'PRODUCT_GAS_LIMITER'
}
export const PRODUCT_TRANSFORMER = {
  label: 'Transformer',
  value: 'PRODUCT_TRANSFORMER'
}
export const PRODUCT_PILOT_ASSEMBLY = {
  label: 'Pilot Assembly',
  value: 'PRODUCT_PILOT_ASSEMBLY'
}
export const PRODUCT_WALL_SWITCH = {
  label: 'Wall Switch',
  value: 'PRODUCT_PILOT_ASSEMBLY'
}