export const BOTH = {
  label: 'Both',
  value: 'BOTH',
};
export const NA = {
  label: 'N/A',
  value: 'NA',
};
export const NOT_ACCESSIBLE = {
  label: 'N/Accessible',
  long: 'Not Accessible',
  value: 'NOT_ACCESSIBLE',
};
// export const YES = {
//   label: 'Yes',
//   value: 'YES'
// };
export const NO = {
  label: 'No',
  value: 'NO',
};
export const OTHER = {
  label: 'Other',
  value: 'OTHER',
};
export const NEITHER = {
  label: 'Neither',
  value: 'NEITHER',
};
export const NONE = {
  label: 'None',
  value: 'NONE',
};
export const ADDITIONAL = {
  label: 'Additional',
  value: 'ADDITIONAL',
};

export const EASY = {
  label: 'Easy',
  value: 'EASY',
};
export const MEDIUM = {
  label: 'Medium',
  value: 'MEDIUM',
};
export const HARD = {
  label: 'Hard',
  value: 'HARD',
};
export const EVERYTHING = {
  label: 'Everything',
  value: 'EVERYTHING',
};
export const AS_NEEDED = {
  label: 'As Needed',
  value: 'AS_NEEDED',
};
export const REAR = {
  label: 'Rear',
  value: 'REAR',
};
export const LEFT = {
  label: 'Left',
  value: 'LEFT',
};
export const RIGHT = {
  label: 'Right',
  value: 'RIGHT',
};
export const BOTTOM = {
  label: 'Bottom',
  value: 'BOTTOM',
};
export const TOP = {
  label: 'Top',
  value: 'TOP',
};
export const ONE = {
  label: 'One',
  value: 'ONE',
};
export const TWO = {
  label: 'Two',
  value: 'TWO',
};
export const OPEN = {
  label: 'Open',
  value: 'OPEN',
};
export const CLOSED = {
  label: 'Closed',
  value: 'CLOSED',
};
export const UNKNOWN = {
  label: 'Unknown',
  value: 'UNKNOWN',
};
export const VERSION_PRO = {
  label: 'Pro',
  value: 'VERSION_PRO',
};
export const VERSION_LITE = {
  label: 'Lite',
  value: 'VERSION_LITE',
};
export const VERSION_CHECKARTEC = {
  label: 'Ar Chim Tec Check',
  value: 'VERSION_CHECKARTEC',
};
export const VERSION_DASHARAMA = {
  label: 'Dasharama',
  value: 'VERSION_DASHARAMA',
};
export const CEILING = {
  label: 'Ceiling',
  value: 'CEILING',
};
export const WALL = {
  label: 'Wall',
  value: 'WALL',
};
export const FIRST_FLOOR = {
  label: 'First',
  value: 'FIRST_FLOOR',
};
export const SECOND_FLOOR = {
  label: 'Second',
  value: 'SECOND_FLOOR',
};
export const BASEMENT = {
  label: 'Basement',
  value: 'BASEMENT',
};

export const TWO_FEET = {
  label: '2 feet',
  value: '2',
};

export const FIVE_FEET = {
  label: '5 feet',
  value: '5',
};

export const TEN_FEET = {
  label: '10 feet',
  value: '10',
};
