import React from 'react';

import PropTypes from 'prop-types';

import {
  AppBar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Toolbar,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Menu as MenuIcon } from '@material-ui/icons';


import styles from './styles';
import { dismissNotification, readNotification } from 'actions/notifications';

const useStyles = makeStyles(styles);

const Topbar = () => {
  const classes = useStyles();

  const [anchorEl, setActiveEl] = React.useState(null);
  const showMenu = !!anchorEl;

  const handleMenuOpen = (event) => {
    setActiveEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setActiveEl(null);
  };

  return (
    <>
      <AppBar position='sticky' className={classes.root}>
        <Container>
          <Toolbar disableGutters>
            <img src="/static/logo_512.png" className={classes.logo} alt="bhp logo" />
            <RouterLink to="/">
              <Typography variant="h2" className={classes.logoText}>
                Bear Hands Pro
              </Typography>
              <Typography align='center' variant="caption" className={classes.logoSubText}>
                Chimney Inspection Software
              </Typography>
            </RouterLink>
            <div className={classes.flexGrow} />
            <Hidden smDown>
              <RouterLink to="/tutorials">
                <Button className={classes.navLink} color="inherit">
                  Tutorials
                </Button>
              </RouterLink>
              <RouterLink to="/resources">
                <Button className={classes.navLink} color="inherit">
                  Resources
                </Button>
              </RouterLink>
              <RouterLink to="/pricing">
                <Button className={classes.navLink} color="inherit">
                  Pricing
                </Button>
              </RouterLink>
              <RouterLink to="/login">
                <Button className={classes.navLink} color="inherit">
                  Login
                </Button>
              </RouterLink>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                className={classes.rightButton}
                color="inherit"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={showMenu}
            onClose={handleMenuClose}
          >
            <RouterLink to='/tutorials'>
              <MenuItem>Tutorials</MenuItem>
            </RouterLink>
            <RouterLink to='/resources'>
              <MenuItem>Resources</MenuItem>
            </RouterLink>
            <RouterLink to="/signup">
              <MenuItem>Pricing</MenuItem>
            </RouterLink>
            <RouterLink to="/login">
              <MenuItem>Login</MenuItem>
            </RouterLink>
          </Menu>
        </Container>
      </AppBar>
    </>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Topbar.propTypes = {
    className: PropTypes.string,
  };
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.app,
});

export default connect(mapStateToProps, {
  dismissNotification,
  readNotification,
})(Topbar);
