import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiDialogContent from './MuiDialogContent';
import MuiGridListTileBar from './MuiGridListTileBar';


const overrides = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiDialogContent,
  MuiGridListTileBar,
};

export default overrides