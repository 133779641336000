import * as Yup from 'yup';
import {
  DAMAGED,
  DAMAGED_BRICK,
  CRACKS,
  EXTERIOR,
  MASONRY,
  MISSING,
  UNSATISFACTORY,
  VERSION_PRO,
  YES,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryLowerStackState: '',
    masonryLowerStackVersion: VERSION_PRO.value,
    masonryLowerStackGroundUpPhotos: [],
    masonryLowerStackLiteIssues: [],
    masonryLowerStackLitePhotos: [],
    masonryLowerStackEfflorescenceFound: '',
    masonryLowerStackEfflorescencePhotos: [],
    masonryLowerStackEfflorescenceDimensions: [{ length: '', width: '' }],
    masonryLowerStackCrownStatus: '',
    masonryLowerStackCrownPhotos: [],
    masonryLowerStackCrownSize: '',
    masonryLowerStackBrickworkProposal: '',
    masonryLowerStackPhotos: [],
    masonrylowerStackBrickworkRebuildFeet: '',
    masonryLowerStackRepointArea: '',
    masonryStackLift: '',
    masonryStackScaffoldingDays: 0,
    masonryStackBrickworkDemoCost: '',
    masonryAshDumpCleanoutStatus: '',
    masonryAshDumpCleanoutPhotos: [],
    masonryAshDumpCleanoutLengthDimension: '',
    masonryAshDumpCleanoutWidthDimension: '',
    masonryLowerStackFoundationCracksFound: '',
    masonryLowerStackFoundationCrackLength1: 0,
    masonryLowerStackFoundationCrackLength2: 0,
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryLowerStackState: Yup.string().required(REQUIRED),
        masonryLowerStackGroundUpPhotos: Yup.array()
          .required(REQUIRED)
          .min(1, VALID_ONE_PHOTO),
        masonryLowerStackEfflorescenceFound: Yup.string().when(
          ['masonryLowerStackVersion', 'masonryLowerStackState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLowerStackEfflorescencePhotos: Yup.array().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'masonryLowerStackEfflorescenceFound',
          ],
          {
            is: (version, state, efflorescence) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              efflorescence === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryLowerStackEfflorescenceDimensions: Yup.array().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'masonryLowerStackEfflorescenceFound',
          ],
          {
            is: (version, state, efflorescence) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              efflorescence === YES.value,
            then: Yup.array().required(REQUIRED),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryLowerStackCrownStatus: Yup.string().when(
          ['masonryLowerStackVersion', 'masonryLowerStackState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLowerStackCrownPhotos: Yup.array().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'masonryLowerStackCrownStatus',
          ],
          {
            is: (version, state, lowerCrownState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [DAMAGED_BRICK, CRACKS].includes(lowerCrownState),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryLowerStackCrownSize: Yup.string().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'masonryLowerStackCrownStatus',
          ],
          {
            is: (version, state, lowerCrownState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [DAMAGED_BRICK, CRACKS].includes(lowerCrownState),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryAshDumpCleanoutStatus: Yup.string().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'overviewStackType',
          ],
          {
            is: (version, state, stackType) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              stackType === EXTERIOR.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryAshDumpCleanoutPhotos: Yup.array().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'overviewStackType',
            'masonryAshDumpCleanoutStatus',
          ],
          {
            is: (version, state, stackType, cleanoutState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              stackType === EXTERIOR.value &&
              [DAMAGED.value, MISSING.value].includes(cleanoutState),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryAshDumpCleanoutLengthDimension: Yup.number().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'overviewStackType',
            'masonryAshDumpCleanoutStatus',
          ],
          {
            is: (version, state, stackType, cleanoutState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              stackType === EXTERIOR.value &&
              [DAMAGED.value, MISSING.value].includes(cleanoutState),
            then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryAshDumpCleanoutWidthDimension: Yup.number().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'overviewStackType',
            'masonryAshDumpCleanoutStatus',
          ],
          {
            is: (version, state, stackType, cleanoutState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              stackType === EXTERIOR.value &&
              [DAMAGED.value, MISSING.value].includes(cleanoutState),
            then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryLowerStackFoundationCracksFound: Yup.string().when(
          [
            'masonryLowerStackVersion',
            'overviewStackType',
            'masonryLowerStackState',
          ],
          {
            is: (version, stackType, state) =>
              version === VERSION_PRO.value &&
              stackType === EXTERIOR.value &&
              state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryLowerStackFoundationCrackLength1: Yup.number().when(
          [
            'masonryLowerStackVersion',
            'masonryLowerStackState',
            'masonryLowerStackFoundationCracksFound',
          ],
          {
            is: (version, state, cracksFound) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              cracksFound === YES.value,
            then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryLowerStackFoundationCrackLength2: Yup.number()
          .typeError(VALID_NUMBER)
          .notRequired(),
        masonryLowerStackLitePhotos: Yup.array().when(
          ['masonryLowerStackState', 'masonryLowerStackVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state