import React from 'react';
import {
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core';

import styles from './styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(styles);


const CTABox = () => {
    const classes = useStyles();
    return (
        <Grid
            item
            container
            justifyContent="space-between"
            alignItems='center'
            className={classes.cTAContainer}
            lg={8}
        >
            <Grid item lg={6} xs={12}>
                <Typography align='center' variant="h1" className={classes.ctaText}>
                    Get Started Today!
                </Typography>
            </Grid>
            <Grid item container justifyContent='center' lg={6} xs={12} className={classes.ctaButtonHolder}>
                <RouterLink to="/signup">
                    <Typography className={classes.title} align='center' variant='h2'>FREE 7 Day Trial</Typography>
                    <Typography className={classes.subText} align='center' variant="h6">No Credit Card Needed!</Typography>
                    <Typography className={classes.subText} align='center' variant="h6">Click Here to Learn More</Typography>
                </RouterLink>

            </Grid>
        </Grid>
    );
};

export default CTABox;