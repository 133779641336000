import * as Yup from 'yup';

import {
  FUEL_GAS,
  YES,
  VERSION_PRO,
  SATISFACTORY,
  UNSATISFACTORY,
  VERSION_LITE,
  UTILITY_CHIMNEY,
  FREESTANDING,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    gasOverviewGasType: '',
    gasOverviewVersion: VERSION_PRO.value,
    gasOverviewPhotos: [],
    gasOverviewLiteIssues: [],
    gasOverviewApplianceType: { value: '' },
    gasOverviewTCmVPre: '',
    gasOverviewTPmVPreNoLoad: '',
    gasOverviewTPmVPreLoad: '',
    gasReplaceBatterySender: '',
    gasReplaceBatteryReceiver: '',
    gasWallSwitchTest: '',
    gasThermostatTest: '',
    gasCleanGlass: '',
    gasCleanRefractory: '',
    gasPositionLogs: '',
    gasEmberMaterials: '',
    gasAirExchangers: '',
    gasPrimaryAirOpeningPilot: '',
    gasBurnerVenturi: '',
    gasOverviewInletWC: '',
    gasOverviewInletTurnedOnWC: '',
    gasOverviewOutletWC: '',
    gasOverviewOutletTurnedOnWC: '',
    gasOverviewTCmVPost: '',
    gasOverviewTPmVPostNoLoad: '',
    gasOverviewTPmVPostLoad: '',
    gasOverviewDataTag: '',
    gasOverviewDataTagPhotos: [],
    gasOverviewElectronicControlModule: '',
    gasOverviewElectronicControlModulePhotos: [],
    gasOverviewThermoCouplePresent: '',
    gasOverviewThermoCouplePresentPhotos: [],
    gasOverviewThermopilePresent: '',
    gasOverviewThermopilePresentPhotos: [],
    gasOverviewSparkIgniter: '',
    gasOverviewSparkIgniterPhotos: [],
    gasOverviewFlameSensor: '',
    gasOverviewFlameSensorPhotos: [],
    gasOverviewSwitches: '',
    gasOverviewSwitchesPhotos: [],
    gasOverviewLimitors: '',
    gasOverviewLimitorsPhotos: [],
    gasOverviewTransformer: '',
    gasOverviewTransformerPhotos: [],
    gasOverviewBlowerFan: '',
    gasOverviewBlowerFanPhotos: [],
    gasOverviewModelNumber: '',
    gasOverviewManufactorer: '',
    gasOverviewLimitorsOHM: '',
    gasOverviewWireContinuity: '',
    gasOverviewWireContinuityPhotos: [],
    gasOverviewWallSwitchContinuity: '',
    gasOverviewgasOverviewWallSwitchContinuityyPhotos: [],
    gasOverviewTransIn: '',
    gasOverviewTransOut: '',
    gasOverviewEcmIn: '',
    gasOverviewEcmOut: '',
    gasOverviewLimitorsBridge: '',
    gasOverviewBurnerSystem: '',
    gasApplianceState: '',
    gasOverviewLitePhotos: [],
    gasAppliancePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewStructureFuelType === FUEL_GAS.value &&
    inspection.data.overviewApplianceType !== UTILITY_CHIMNEY.value &&
    inspection.data.overviewApplianceType !== FREESTANDING.value
      ? //state for pro version
        inspection.data.gasOverviewVersion === VERSION_PRO.value
        ? {
            gasOverviewGasType: Yup.string().required(REQUIRED),
            gasOverviewApplianceType: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            gasReplaceBatterySender: Yup.string().required(REQUIRED),
            gasReplaceBatteryReceiver: Yup.string().required(REQUIRED),
            gasWallSwitchTest: Yup.string().required(REQUIRED),
            gasThermostatTest: Yup.string().required(REQUIRED),
            gasCleanGlass: Yup.string().required(REQUIRED),
            gasCleanRefractory: Yup.string().required(REQUIRED),
            gasPositionLogs: Yup.string().required(REQUIRED),
            gasEmberMaterials: Yup.string().required(REQUIRED),
            gasAirExchangers: Yup.string().required(REQUIRED),
            gasPrimaryAirOpeningPilot: Yup.string().required(REQUIRED),
            gasBurnerVenturi: Yup.string().required(REQUIRED),
            gasOverviewInletWC: Yup.string().required(REQUIRED),
            gasOverviewInletTurnedOnWC: Yup.string().when(
              'gasOverviewStayLit',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewOutletWC: Yup.string().required(REQUIRED),
            gasOverviewOutletTurnedOnWC: Yup.string().when(
              'gasOverviewStayLit',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewDataTag: Yup.string().required(REQUIRED),
            gasOverviewDataTagPhotos: Yup.array().when('gasOverviewDataTag', {
              is: YES.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }),
            gasOverviewModelNumber: Yup.string().when('gasOverviewDataTag', {
              is: YES.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }),
            gasOverviewManufactorer: Yup.string().when('gasOverviewDataTag', {
              is: YES.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }),
            gasOverviewElectronicControlModule: Yup.string().required(REQUIRED),
            gasOverviewElectronicControlModulePhotos: Yup.array().when(
              'gasOverviewElectronicControlModule',
              {
                is: (state) =>
                  state === YES.value ||
                  state === UNSATISFACTORY.value ||
                  state === SATISFACTORY.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
            gasOverviewThermoCouplePresent: Yup.string().required(REQUIRED),
            gasOverviewThermoCouplePresentPhotos: Yup.array().when(
              'gasOverviewThermoCouplePresent',
              {
                is: YES.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
            gasAppliancePhotos: Yup.array()
              .required(REQUIRED)
              .min(1, VALID_ONE_PHOTO),
            gasOverviewThermopilePresent: Yup.string().required(REQUIRED),
            gasOverviewThermopilePresentPhotos: Yup.array().when(
              'gasOverviewThermopilePresent',
              {
                is: YES.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
            gasOverviewSparkIgniter: Yup.string().required(REQUIRED),
            gasOverviewSparkIgniterPhotos: Yup.array().when(
              'gasOverviewSparkIgniter',
              {
                is: (state) =>
                  state === YES.value ||
                  state === UNSATISFACTORY.value ||
                  state === SATISFACTORY.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
            gasOverviewFlameSensor: Yup.string().required(REQUIRED),
            gasOverviewFlameSensorPhotos: Yup.array().when(
              'gasOverviewFlameSensor',
              {
                is: (state) =>
                  state === YES.value ||
                  state === UNSATISFACTORY.value ||
                  state === SATISFACTORY.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
            gasOverviewTCmVPost: Yup.string().when(
              'gasOverviewThermoCouplePresent',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewTCmVPre: Yup.string().when(
              'gasOverviewThermoCouplePresent',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewTPmVPostNoLoad: Yup.string().when(
              'gasOverviewThermopilePresent',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewTPmVPostLoad: Yup.string().when(
              'gasOverviewThermopilePresent',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewTPmVPreNoLoad: Yup.string().when(
              'gasOverviewThermopilePresent',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewTPmVPreLoad: Yup.string().when(
              'gasOverviewThermopilePresent',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewSwitches: Yup.string().required(REQUIRED),
            gasOverviewSwitchesPhotos: Yup.array().when('gasOverviewSwitches', {
              is: (state) =>
                state === YES.value ||
                state === UNSATISFACTORY.value ||
                state === SATISFACTORY.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }),
            gasOverviewLimitors: Yup.string().required(REQUIRED),
            gasOverviewLimitorsPhotos: Yup.array().when('gasOverviewLimitors', {
              is: YES.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }),
            gasOverviewLimitorsOHM: Yup.number().when(
              ['gasOverviewLimitors', 'gasOverviewStayLit'],
              {
                is: (diagnostic, state) =>
                  diagnostic === YES.value && state === YES.value,
                then: Yup.number().required(REQUIRED),
                otherwise: Yup.number().notRequired(),
              }
            ),
            gasOverviewTransformer: Yup.string().required(REQUIRED),
            gasOverviewTransformerPhotos: Yup.array().when(
              'gasOverviewTransformer',
              {
                is: YES.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
            gasOverviewBlowerFan: Yup.string().required(REQUIRED),
            gasOverviewBlowerFanPhotos: Yup.array().when(
              'gasOverviewBlowerFan',
              {
                is: YES.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
            gasOverviewTransIn: Yup.string().when(
              ['gasOverviewStayLit', 'gasOverviewTransformer'],
              {
                is: (diagnostic, state) =>
                  diagnostic === YES.value && state === YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewTransOut: Yup.string().when(
              ['gasOverviewStayLit', 'gasOverviewTransformer'],
              {
                is: (diagnostic, state) =>
                  diagnostic === YES.value && state === YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewWallSwitchContinuity: Yup.string().when(
              'gasOverviewStayLit',
              {
                is: YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewWireContinuity: Yup.string().when('gasOverviewStayLit', {
              is: YES.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }),
            gasOverviewEcmIn: Yup.string().when(
              ['gasOverviewStayLit', 'gasOverviewElectronicControlModule'],
              {
                is: (diagnostic, state) =>
                  diagnostic === YES.value && state === YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
            gasOverviewEcmOut: Yup.string().when(
              ['gasOverviewStayLit', 'gasOverviewElectronicControlModule'],
              {
                is: (diagnostic, state) =>
                  diagnostic === YES.value && state === YES.value,
                then: Yup.string().required(REQUIRED),
                otherwise: Yup.string().notRequired(),
              }
            ),
          }
        : // state for lite version
          {
            gasApplianceState: Yup.string().required(REQUIRED),
            gasOverviewDataTag: Yup.string().required(REQUIRED),
            gasOverviewBurnerSystem: Yup.string().required(REQUIRED),
            gasOverviewLitePhotos: Yup.array().when(
              ['gasApplianceState', 'gasOverviewVersion'],
              {
                is: (state, version) =>
                  state === UNSATISFACTORY.value &&
                  version === VERSION_LITE.value,
                then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                otherwise: Yup.array().notRequired(),
              }
            ),
          }
      : {}),
  }),
};

export default state;
