import masonryAshDumpState from './MasonryAshDumpForm/state';
import masonryCapState from './MasonryCapForm/state';
import masonryCrownState from './MasonryCrownForm/state';
import masonryDamperState from './MasonryDamperForm/state';
import masonryFireboxState from './MasonryFireboxForm/state';
import masonryFlashingState from './MasonryFlashingForm/state';
import masonryHearthState from './MasonryHearthForm/state';
import masonryHeightState from './MasonryHeightForm/state';
import masonryLinerState from './MasonryLinerForm/state';
import masonryLowerStackState from './MasonryLowerStackForm/state';
import masonryOverviewState from './MasonryOverviewForm/state';
import masonrySmokeChamberState from './MasonrySmokeChamberForm/state';
import masonrySparkScreenState from './MasonrySparkScreenForm/state';
import masonryStackState from './MasonryStackForm/state';
import masonryCompleteRebuildState from './MasonryCompleteRebuild/state';
import masonryWaterCheckState from './MasonryWaterCheck/state';
import masonryGrateState from './MasonryGrateForm/state';
import masonryLintelState from './MasonryLintelForm/state';
import masonryMantelState from './MasonryMantelForm/state';
import masonryAtticState from './MasonryAtticForm/state';
import masonryCrawlSpaceState from './MasonryCrawlSpaceForm/state';
import AdditionalUtilityApplianceFormState from './utilityApplianceForm/state';

const state = {
  initState: {
    ...masonryAshDumpState.initState,
    ...masonryCapState.initState,
    ...masonryCrownState.initState,
    ...masonryDamperState.initState,
    ...masonryFireboxState.initState,
    ...masonryGrateState.initState,
    ...masonryLintelState.initState,
    ...masonryFlashingState.initState,
    ...masonryHearthState.initState,
    ...masonryHeightState.initState,
    ...masonryLinerState.initState,
    ...masonryLowerStackState.initState,
    ...masonryOverviewState.initState,
    ...masonrySmokeChamberState.initState,
    ...masonrySparkScreenState.initState,
    ...masonryStackState.initState,
    ...masonryCompleteRebuildState.initState,
    ...masonryWaterCheckState.initState,
    ...masonryMantelState.initState,
    ...masonryAtticState.initState,
    ...masonryCrawlSpaceState.initState,
    ...AdditionalUtilityApplianceFormState.initState,
  },
  validation: (inspection) => ({
    ...masonryAshDumpState.validation(inspection),
    ...masonryCapState.validation(inspection),
    ...masonryCrownState.validation(inspection),
    ...masonryDamperState.validation(inspection),
    ...masonryFireboxState.validation(inspection),
    ...masonryGrateState.validation(inspection),
    ...masonryLintelState.validation(inspection),
    ...masonryFlashingState.validation(inspection),
    ...masonryHearthState.validation(inspection),
    ...masonryHeightState.validation(inspection),
    ...masonryLinerState.validation(inspection),
    ...masonryLowerStackState.validation(inspection),
    ...masonryOverviewState.validation(inspection),
    ...masonrySmokeChamberState.validation(inspection),
    ...masonrySparkScreenState.validation(inspection),
    ...masonryStackState.validation(inspection),
    ...masonryCompleteRebuildState.validation(inspection),
    ...masonryWaterCheckState.validation(inspection),
    ...masonryMantelState.validation(inspection),
    ...masonryAtticState.validation(inspection),
    ...masonryCrawlSpaceState.validation(inspection),
    ...AdditionalUtilityApplianceFormState.validation(inspection),
  }),
};

export default state;
