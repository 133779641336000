import { VERSION_LITE } from 'components/app/InspectionForm/constants';

const state = {
  initState: {
    additionalUtilityAppliancePhoto: [],
    additionalUtilityApplianceLiteIssues: [],
    additionalUtilityApplianceState: '',
    additionalUtilityApplianceVersion: VERSION_LITE.value,
  },
  validation: (inspection) => ({}),
};
export default state;
