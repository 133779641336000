import React from 'react';
import { Grid, Typography, makeStyles, Container } from '@material-ui/core';
import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';

const useStyles = makeStyles(styles);

const ProVsLite = () => {
  const classes = useStyles();

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={12}>
          <Typography align="center" className={classes.title} variant="h1">
            Pro vs Lite
          </Typography>
        </Grid>
        <Grid Item container lg={6} className={classes.contentBlock}>
          <img
            alt="pro vs lite chart"
            src="/static/landing/proVsLite.png"
            className={classes.image}
          />
        </Grid>
        <Grid item container lg={4} className={classes.contentBlock}>
          <Typography
            align="center"
            variant="h5"
            className={classes.contentText}
          >
            Bear Hands Pro flows seamlessly with the sweep process and guides
            technicians through every part of an inspection report-- taking all
            the guesswork out of technician hands. BHP is logical in every part
            of the reporting process. BHP has the technician start inside the
            house by measuring the firebox opening size. When the technician is
            on the roof and notices a cracked liner, they will take some
            pictures (EVERY issue requires a picture) to be attached to the
            report. They will also be guided to measure the inside of the flue
            liner. BHP will calculate the minimum cross-sectional area and
            generate available liners from the major manufacturers including
            Olympia and National that fit within the MCSA. BHP guides the tech
            to measuring the height of the liner, automatically adding 1’ to the
            length on the pull sheet as you never want to be short on a liner,
            and knows if the chimney is interior or exterior, It will also
            recommend an access door or other means to finish off the bottom of
            the liner.
          </Typography>
          <Typography
            align="center"
            variant="h5"
            className={classes.contentText}
          >
            Bear Hands Pro also offers a Lite version that has simple outputs
            that you can use as-is or re-write to fit your company's needs!
          </Typography>
          <Typography
            align="center"
            variant="h5"
            className={classes.contentText}
          >
            BHP is versatile as well. You can always simplify processes by
            easily switching between both Pro and Lite versions!
          </Typography>
          <Typography
            align="center"
            variant="h5"
            className={classes.contentText}
          >
            What if you like 80% of the logic in the Pro version but want to
            simplify a few things? No Problem! You can easily switch the logic
            on/off and revert each section back to the LITE version!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default publicComponent(ProVsLite);
