import * as Yup from 'yup';
import {
    DRYER_VENT, UNSATISFACTORY, VERSION_LITE
} from 'components/app/InspectionForm/constants'

import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        dryerventConnectorType: '',
        dryerventConnectorDiameter: '',
        dryerventConnectorState: '',
        dryerventConnectorVersion: VERSION_LITE.value,
        dryerventConnectorLiteIssues: [],
        dryerventConnectorLitePhotos: [],
    },
    validation: (inspection) => (
        inspection.data.overviewInspectionLevel === DRYER_VENT.value ?
            {
                dryerventConnectorState: Yup.string().required(REQUIRED),
                dryerventConnectorLitePhotos: Yup.array().when(
                    ['dryerventConnectorState'], {
                    is: (state) => state === UNSATISFACTORY.value,
                    then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                    otherwise: Yup.array().notRequired(),
                }
                )
            } : {}
    )
};

export default state