import colors from 'theme/palette';

const styles = (theme) => ({
    mainContainer: {
        width: '100%'
    },
    image: {
        maxWidth: '100%',
    },
    guideItem: {
        margin: theme.spacing(2, 0)
    },
    paper: {
        margin: theme.spacing(1),
    },
    intro: {
        margin: theme.spacing(5, 0)
    },
    breadCrumbs: {
        margin: theme.spacing(1)
    },
    titleBar: {
        padding: theme.spacing(3),
        backgroundColor: colors.darkBlue,
    },
    pictureHolder: {
        backgroundColor: colors.lightBlue,
        padding: theme.spacing(1)
    },
    stepHeading: {
        margin: theme.spacing(2)
    },
    stepHeadingDivider: {
        margin: theme.spacing(1),
        backgroundColor: colors.lightTan
    },
    stepText: {
        margin: theme.spacing(0, 5),
        padding: theme.spacing(0, 5)
    },
    stepPicture: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: colors.darkBlue
    },
    endMessage: {
        margin: theme.spacing(2)
    },
    nested: {
        margin: theme.spacing(0, 2)
    },
    nestedDouble: {
        margin: theme.spacing(0, 4)
    }
});

export default styles