import * as Yup from 'yup';
import {
  FUEL_GAS,
  UNSATISFACTORY,
  YES,
  VERSION_PRO,
  VERSION_LITE,
  FREESTANDING,
  UTILITY_CHIMNEY
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    gasInsulationState: '',
    gastInsulationPhotos: [],
    gasInsulationLiteIssues: [],
    gasInsulationLitePhotos: [],
    gasInsulationPhotos: [],
    gasInsulationVersion: VERSION_PRO.value,
    gasInsulationBlanketWorn: '',
    gasInsulationSurround: '',
    gasInsulationDoorWorn: '',
    gasInsulationGasketSizeDoor: { value: '' },

  },
  validation: (inspection) => ({
    ...(inspection.data.overviewStructureFuelType === FUEL_GAS.value
      && inspection.data.gasInsulationVersion === VERSION_LITE.value
      && inspection.data.overviewApplianceType !== UTILITY_CHIMNEY.value
      && inspection.data.overviewApplianceType !== FREESTANDING.value
      ? {
        gasInsulationState: Yup.string().required(REQUIRED),
        gastInsulationPhotos: Yup.array().when(
          ['gasInsulationState', 'gasInsulationVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }),
        gasInsulationBlanketWorn: Yup.string().when(
          ['gasInsulationState', 'gasInsulationVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        gasInsulationSurround: Yup.string().when(
          ['gasInsulationState', 'gasInsulationVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        gasInsulationDoorWorn: Yup.string().when(
          ['gasInsulationState', 'gasInsulationVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        gasInsulationGasketSizeDoor: Yup.object().when(
          ['gasInsulationState', 'gasInsulationDoorWorn', 'gasInsulationVersion'],
          {
            is: (insulationState, doorWorn, version) =>
              insulationState === UNSATISFACTORY.value &&
              doorWorn === YES.value &&
              version === VERSION_PRO.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        gasInsulationLitePhotos: Yup.array().when(
          ['gasInsulationState', 'gasInsulationVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        )
      }
      : {}),
  }),
};

export default state