import React from 'react';
import { Grid, Typography, makeStyles, Container, Divider, Button } from '@material-ui/core';
import publicComponent from 'components/helpers/publicComponent';
import CTABox from '../components/CTABox';
import styles from './styles';

const useStyles = makeStyles(styles);

const Proposals = () => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item lg={12}>
            <Typography align="center" className={classes.title} variant="h1">
              Instant Proposals
            </Typography>
            <Typography align='center' variant='h5' className={classes.subHeading}>
              After completing their inspections, your technicians will have instant proposals in-hand to review with customers, saving them time!
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item container lg={5} justifyContent='flex-end' >
            <Grid item>
              <img
                alt="Bear Hands Pro Proposals of repairs are designed with improved sales flow and language"
                src="/static/instantProposals/proposalTablet.png"
                className={classes.image}
              />
            </Grid>
          </Grid>
          <Grid item container lg={5} >
            <Typography
              align="left"
              variant="h3"
              className={classes.contentTitle}
            >
              Improved sales flow and language
            </Typography>
            <Typography
              align="left"
              variant="h6"
              className={classes.contentText}
            >
              Designed to easily convey the need for repairs to your customers!
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Container >
      <Grid container justifyContent='center' alignItems='center' className={classes.reportCardSection} >
        <Container >
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={5}>
              <Typography
                align="right"
                variant="h3"
                className={classes.contentTitleReportCard}
              >
                Recommended Repair pictures
              </Typography>
              <Typography
                align="right"
                variant="h6"
                className={classes.contentTextReportCard}
              >
                Show your customers what the repairs will look like after their finished!
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <img
                alt="Reccomded repair pictures make it easy to sell repairs"
                src="/static/instantProposals/reccomendedRepairPics.png"
                className={classes.image}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Divider />
      <Container>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={5}>
            <img
              alt="Offer financing to your customers right on the proposal"
              src="/static/instantProposals/propFinancing.png"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography
              align="left"
              variant="h3"
              className={classes.contentTitle}
            >
              Financing
            </Typography>
            <Typography
              align="left"
              variant="h6"
              className={classes.contentText}
            >
              Show your customers what financing your offer so price will never be an objection
            </Typography>
          </Grid>
        </Grid>
        <Container>
          <Grid container justifyContent='center'>
            <Grid item>
              <a href='/static/guides/Example_Proposal.pdf' target='_blank'>
                <Button color='primary' variant='outlined'>See an Example here!</Button>
              </a>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Grid container justifyContent="center" alignItems="center">
          <CTABox />
        </Grid>
      </Container>

    </>
  );
};

export default publicComponent(Proposals);
