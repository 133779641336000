import * as Yup from 'yup';


const state = {
    initState: {
        priceFormAppPropLineItms: [],
        priceFormTotal: '',
        approvalFormState: '',
        approvalClientSignature: { date: '', url: '' },
        approvalDisc: ''
    },
    validation: Yup.object().shape({

    })
};

export default state