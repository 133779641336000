
import axios from 'axios';

import { setCurrentUser } from '../auth/setCurrentUser';

//function to edit org
export const editOrg = (org, user) => async (dispatch) => {
  return axios
    .post('/api/admin/editorg', org)
    .then((res) => {
      if (!res.data.success) {
        throw new Error();
      }
      dispatch(setCurrentUser(user, org));
    })
    .catch((err) => {
      throw err.response.data.errors;
    });
};
