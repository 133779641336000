import colors from 'theme/palette';

const styles = (theme) => ({
    title: {
        marginTop: theme.spacing(15),
        marginBotton: theme.spacing(10),
        fontWeight: 800,
        lineHeight: '4.2rem',
        fontSize: '4rem',
    },
    hero: {
        backgroundColor: theme.palette.common.neutral,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/static/landing/businessHero.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    heroWrapper: {
        height: '80vh',
    },
    heroText: {
        color: colors.lightTan,
    },
    heroTagline: {
        color: colors.lightTan,
        fontWeight: 800,
        fontSize: '60px',
        lineHeight: '70px',
    },
    contentBlock: {
        marginTop: theme.spacing(5),
    },
    image: {
        maxWidth: '100%',
    },
    contentBlockLeft: {
        marginTop: theme.spacing(5),
        backgroundColor: colors.lightBlue,
        marginBottom: theme.spacing(5),
        maxWidth: '605px',
    },
    contentBlockRight: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        backgroundColor: colors.white,
        maxWidth: "100%"
    },
    menu: {
        marginTop: theme.spacing(5),
    },
    menuItem: {
        margin: theme.spacing(2),
        cursor: "pointer",
    },
    menuItemSelected: {
        margin: theme.spacing(2),
        cursor: "pointer",
        textDecoration: "underline"
    },
    titleBox: {
        marginBottom: theme.spacing(2),
        backgroundColor: colors.lightBlue
    },
    boxTextLeft: {
        color: colors.white,
    },
    boxTextRight: {
        color: colors.darkBlue,
        margin: theme.spacing(0, 0, 0, 2)
    },
    boxTextRightTitle: {
        marginTop: theme.spacing(3),
        color: 'white',
    },
    boxTextRightSubtext: {
        marginBottom: theme.spacing(2),
        color: 'white',
    },
    faqContentBlock: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        backgroundColor: colors.white,
        padding: theme.spacing(2),
    },
    faqText: {
        color: colors.black,
    },
    faqQuestion: {
        padding: theme.spacing(2),
    },
    tiles: {
        display: 'flex',
        margin: theme.spacing(.5, 0)
    },
    tileContainer: {
        padding: theme.spacing(1)
    }
});

export default styles