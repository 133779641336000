import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  OPEN_FIREBOX,
  INSERT,
  FREESTANDING,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryCapState: '',
    masonryCapVersion: VERSION_PRO.value,
    masonryCapLiteIssues: [],
    masonryCapLitePhotos: [],
    masonryCapNeedsLegs: '',
    masonryCapMissingOrDamaged: '',
    masonryCapPhotos: [],
    masonryCapType: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && inspection.data.overviewApplianceType !== FREESTANDING.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryCapState: Yup.string().required(REQUIRED),
        masonryCapNeedsLegs: Yup.string().when(
          ['masonryCapVersion', 'masonryCapState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryCapMissingOrDamaged: Yup.string().when(
          ['masonryCapVersion', 'masonryCapState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryCapType: Yup.string().when(
          ['masonryCapVersion', 'masonryCapState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryCapPhotos: Yup.array().when(
          ['masonryCapVersion', 'masonryCapState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryCapLiteIssues: Yup.array().when(
          ['masonryCapState', 'masonryCapVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryCapLitePhotos: Yup.array().when(
          ['masonryCapState', 'masonryCapVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state