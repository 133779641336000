import React from 'react';


const PaymentCanceled = () => {

    return (
        <div>
            <h1>Payment Canceled</h1>
        </div>
    )
};

export default PaymentCanceled;