export const BRICKWORK = {
  label: 'Brickwork',
  value: 'BRICKWORK'
};
export const SIDING = {
  label: 'Siding',
  value: 'SIDING'
};
export const STUCCO = {
  label: 'Stucco',
  value: 'STUCCO'
};
