import colors from 'theme/palette';

const styles = (theme) => ({
  root: {
    backgroundColor: colors.darkBlue,
    textColor: colors.white,
    zIndex: 100,
  },
  rootSmaller: {
    backgroundColor: colors.darkBlue,
    textColor: colors.white,
    position: 'static',
    width: '100%',
    margin: '0px 0px -215px 0px',
    zIndex: 100,
    bottom: -215,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logo: {
    color: colors.white,
    marginTop: theme.spacing(2),
  },
  footerNaveHeader: {
    color: colors.white,
  },
  footerNaveHeaderBreak: {
    color: colors.white,
    margin: '0px 10px'
  },
  footerNavContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  ExpansionPanel: {
    backgroundColor: colors.darkBlue,
  },
  icons: {
    color: colors.white,
    margin: theme.spacing(2),
    fontSize: '2em'
  },
  footerSpace: {
    marginBottom: '15px',
    textAlign: 'center',
  }
});

export default styles