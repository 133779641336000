import axios from 'axios';

// Triggered from form submission
// Throws errors
// Makes request with form data
export const updatePassword = (passwords) => async () => {
  return axios
    .post('/api/auth/update-password', passwords)
    .then((res) => {

      if (!res.data.success) {
        throw new Error();
      }
    })
    .catch((err) => {

      throw err.response.data.errors
    });
};
