import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const ChangingCompanyInfo = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: Company Info</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4"> This is a video in our series of getting started videos about everything you need to know about company info <br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/H57GmLft50U" title="Changing Company Info" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/H57GmLft50U" title="Changing Company Info" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: Changing Company Info.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                This is the company info tile, this holds all the information you entered when creating your organization.  The information in this tile should be all public facing information, except for the organization id which would just be used internally for support.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='your company info tile.' src='/static/tutorials/changingCompanyInfo/companyInfoSection.png' width='100%' />

                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                To edit or change anyone of your company's info just click on the three dots in top right corner of the tile, select edit org, and then fill out the form that pops up. Don't worry! If you make a mistake you can always fix it.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='edit your org settings by clicking on three dots in top right corner' src='/static/tutorials/changingCompanyInfo/editOrgSettings.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                You can preview how your company info would look on the top of your reports and proposals in the top right of the inspection tile.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='The inspection tile has a preview of what your company info looks like.' src='/static/tutorials/changingCompanyInfo/previewCompanyInfo.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={12} className={classes.endMessage}>
                            <Typography align='center' variant='h3'>
                                Don't worry we go over how to change your Logo in the next tutorial!
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default ChangingCompanyInfo;