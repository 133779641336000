export const VENT_TOP_OF_STACK = {
  label: 'Top of Stack',
  value: 'VENT_TOP_OF_STACK',
};
export const VENT_TO_FIRST_TILE_SEAL = {
  label: 'Below top of chimney',
  value: 'VENT_TO_FIRST_TILE_SEAL',
};
export const VENT_NOT_VENTED = {
  label: 'Not Vented',
  value: 'VENT_NOT_VENTED',
};
export const VENT_INSERT_3 = {
  label: '3" Insert Vent',
  value: 'VENT_INSERT_3',
};
export const VENT_INSERT_4 = {
  label: '4" Insert Vent',
  value: 'VENT_INSERT_4',
};
export const VENT_INSERT_6 = {
  label: '6" Insert Vent',
  value: 'VENT_INSERT_6',
};
export const VENT_INSERT_8 = {
  label: '8" Insert Vent',
  value: 'VENT_INSERT_8',
};
export const DIRECT_VENT = {
  label: 'Direct Vent',
  value: 'DIRECT_VENT',
};
export const B_VENT = {
  label: 'B-Vent',
  value: 'B_VENT',
};
export const L_VENT = {
  label: 'L-Vent',
  value: 'L_VENT',
};
export const VERTICAL = {
  label: 'Vertical',
  value: 'VERTICAL',
};
export const HORIZONTAL = {
  label: 'Horizontal',
  value: 'HORIZONTAL',
};
