import * as Yup from 'yup';
import {
  FUEL_WOOD,
  PREFAB,
  UNSATISFACTORY,
  VERSION_PRO,
  YES,
  FUEL_GAS,
  VERSION_LITE
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabSparkScreenState: '',
    prefabSparkScreenVersion: VERSION_PRO.value,
    prefabSparkScreenLiteIssues: [],
    prefabSparkScreenLitePhotos: [],
    prefabSparkScreenDamaged: '',
    prefabSparkScreenPhotos: [],
    prefabSparkScreenHeight: { value: '' },
    prefabSparkScreenRodKit: '',
    prefabGlassDoorDamaged: '',
    prefabGlassDoorPhotos: [],
    prefabGlassDoorDimensions: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.overviewStructureFuelType === FUEL_WOOD.value &&
      inspection.data.prefabSparkScreenVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        prefabSparkScreenState: Yup.string().required(REQUIRED),
        prefabSparkScreenDamaged: Yup.string().when(
          ['prefabSparkScreenVersion', 'prefabSparkScreenState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabSparkScreenPhotos: Yup.array().when(
          ['prefabSparkScreenState', 'prefabSparkScreenDamaged'],
          {
            is: (version, state, isDamaged) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              isDamaged === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabSparkScreenHeight: Yup.object().when(
          ['prefabSparkScreenState', 'prefabSparkScreenDamaged'],
          {
            is: (version, state, isDamaged) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              isDamaged === YES.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        prefabSparkScreenRodKit: Yup.string().when(
          ['prefabSparkScreenState', 'prefabSparkScreenDamaged'],
          {
            is: (version, state, isDamaged) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              isDamaged === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabGlassDoorDamaged: Yup.string().when(
          ['prefabSparkScreenVersion', 'prefabSparkScreenState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabGlassDoorPhotos: Yup.array().when(
          ['prefabSparkScreenState', 'prefabGlassDoorDamaged'],
          {
            is: (version, state, isDamaged) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              isDamaged === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabGlassDoorDimensions: Yup.string().when(
          ['prefabSparkScreenState', 'prefabGlassDoorDamaged'],
          {
            is: (version, state, isDamaged) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              isDamaged === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.overviewStructureFuelType === FUEL_WOOD.value &&
        inspection.data.prefabSparkScreenVersion === VERSION_LITE.value
        && inspection.data.overviewStructureFuelType !== FUEL_GAS.value ? {
        prefabSparkScreenState: Yup.string().required(REQUIRED),
        prefabSparkScreenLitePhotos: Yup.array().when(
          ['prefabSparkScreenState', 'prefabSparkScreenVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        )
      } : {}),
  }),
};

export default state