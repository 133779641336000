import React from 'react';
import { Route } from 'react-router-dom';
import Guide from '../pages/Guide';

// Accepts a Layout component and a child component and renders both
// Rendering this way prevents the layout from having to re-render on route change
const GuidLayoutRoute = ({ component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={(props) => <Layout {...props} component={Guide} page={component} />}
    />
);

export default GuidLayoutRoute;