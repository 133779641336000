import axios from 'axios';

export const getOrgs = (id) => async (dispatch) => {
  return axios.get('/api/admin/orgs').then((res) => {
    if (!res.data.success) {
      throw new Error();
    }

    return res.data;
  });
};
