import React from 'react';




const Privacy = () => {


    return (
        <div>
            <h1> Privacy page</h1>
        </div>
    )
}

export default Privacy;