export const GALERY_PICTURES = [
    {
        url: "Access-Door.png",
        title: "Access Door",
        type: ['masonry'],
    },
    {
        url: "Animal-Intrusion.png",
        title: "Animal Intrusion",
        type: ['masonry', 'other'],
    },
    {
        url: "Ash-Dump-Door.png",
        title: "Ash Dump Door",
        type: ['masonry'],
    },
    {
        url: "Cat-Combustor.png",
        title: "Catalytic Combustor",
        type: ['insert'],
    },
    {
        url: "Ceiling-Support-Box.png",
        title: "Ceiling Support Box",
        type: ['freestanding'],
    },
    {
        url: "Chase-Cover.png",
        title: "Chase Cover",
        type: ['prefab'],
    },
    {
        url: "chaseCap.png",
        title: "Chase Cap",
        type: ['prefab']
    },
    {
        url: "Clean-Flue.png",
        title: "Clean Flue",
        type: ['masonry']
    },
    {
        url: "Clean-Glass.png",
        title: "Clean Glass",
        type: ['freestanding', 'insert', 'gas'],
    },
    {
        url: "Crown-Cap.png",
        title: "Outside Mount Chimney Cap",
        type: ['masonry'],
    },
    {
        url: "Crowncoat-Lower-Crown.png",
        title: "Crown Coat Lower Crown",
        type: ['masonry'],
    },
    {
        url: "Crowncoat-Upper-Crown.png",
        title: "Crown Coat on Upper Stack",
        type: ['masonry']
    },
    {
        url: "Damper-Clamp.png",
        title: "Damper Clamp",
        type: ['gas', 'insert']
    },
    {
        url: "Demo-Hearth.png",
        title: "Demo Hearth",
        type: ['masonry']
    },
    {
        url: 'doubleWallStovePipe.png',
        title: 'Double Wall Stove Pipe',
        type: ['freestanding']
    },
    {
        url: 'extendChimneyPipe.png',
        title: 'Extend Chimney Pipe',
        type: ['freestanding']
    },
    {
        url: "Extension-Cap.png",
        title: "Extension Cap",
        type: ['masonry']
    },
    {
        url: "Fire-Bricks.png",
        title: "Replace Fire Bricks",
        type: ['freestanding', 'insert']
    },
    {
        url: "Firebox-Rebuild.png",
        title: "Firebox Rebuild",
        type: ['masonry']
    },
    {
        url: "fireplace-grate.png",
        title: "Replace Grate",
        type: ['masonry', 'prefab']
    },
    {
        url: "flueLinerReplace.png",
        title: "Stainless Steel Liner",
        type: ['masonry', 'insert']
    },
    {
        url: "Foundation-Repair.png",
        title: "Foundation Repair",
        type: ['masonry', 'insert']
    },
    {
        url: "freestanding-clearance-reduction.png",
        title: "Wall Protection",
        type: ['freestanding']
    },
    {
        url: "freestanding-stove-replace.png",
        title: "Replace Freestanding Stove",
        type: ['freestanding']
    },
    {
        url: "freestanding-stove-roof-flashing.png",
        title: "Freestanding Roof Flashing",
        type: ['freestanding']
    },
    {
        url: "Gas-Cleaning.png",
        title: "Clean Gas Logs",
        type: ['gas', 'insert']
    },
    {
        url: "Gas-Fireplace-Repair.png",
        title: "Gas Fireplace Repair",
        type: ['gas']
    },
    {
        url: "Gas-Log-Set.png",
        title: "Gas Log Set",
        type: ['gas']
    },
    {
        url: "Glass-Door-Set.png",
        title: "Glass Door Set",
        type: ['freestanding', 'insert', 'gas'],
    },
    {
        url: "Hearth-Rug.png",
        title: "Hearth Rug",
        type: ['freestanding', 'iprefab'],
    },
    {
        url: "heatShield.png",
        title: "Heat Shield",
        type: ['masonry'],
    },
    {
        url: "Insert-Surround-Insulation.png",
        title: "Surround Insulation",
        type: ['insert', 'gas'],
    },
    {
        url: "Insulation-Blanket.png",
        title: "Insulation Blank",
        type: ['insert', 'freestanding'],
    },
    {
        url: "Level-2-inspection.png",
        title: "Insulation Blank",
        type: ['insert', 'freestanding'],
    },
    {
        url: "Leymance-Damper.png",
        title: "Lock Top Damper",
        type: ['masonry']
    },
    {
        url: "Masonry-Cricket.png",
        title: "Masonry Cricket",
        type: ['masonry']
    },
    {
        url: "Masonry-Flashing.png",
        title: "Masonry Flashing",
        type: ['masonry']
    },
    {
        url: "Masonry-Stack-Cap.png",
        title: "Flue Cap",
        type: ['masonry']
    },
    {
        url: "masonryFlashSeal.png",
        title: "Flash Seal",
        type: ['masonry']
    },
    {
        url: "mettalicSilverChaseCover.png",
        title: "Silver Chase Cover",
        type: ['prefab',]
    },
    {
        url: "outSideMountCap.png",
        title: "Outside Mount cap",
        type: ['masonry',]
    },
    {
        url: "Parged-Lintel.png",
        title: "Parged Lintel",
        type: ['masonry'],
    },
    {
        url: "Parged-Smoke-Chamber.png",
        title: "Parged Smoke Chamber",
        type: ['masonry']
    },
    {
        url: "Pre-Fab-Cap.png",
        title: "Pre Fab Chimney Cap",
        type: ['prefab']
    },
    {
        url: "Pre-Fab-Cricket.png",
        title: "Pre Fab Cricket",
        type: ['prefab']
    },
    {
        url: "Pre-Fab-Damper.png",
        title: "Pre Fab Damper",
        type: ['prefab']
    },
    {
        url: "Pre-fab-Flashing.png",
        title: "Pre Fab Flashing",
        type: ['prefab']
    },
    {
        url: "Pre-Fab-Flashseal.png",
        title: "Pre Fab Flash Seal",
        type: ['prefab']
    },
    {
        url: "Pre-Fab-Hearth-Demo.png",
        title: "Demo Hearth",
        type: ['prefab']
    },
    {
        url: "Rain-Collar.png",
        title: "Rain Collar",
        type: ['prefab', 'freestanding']
    },
    {
        url: "Rebuild-Chase.png",
        title: "Rebuild Chase",
        type: ['prefab']
    },
    {
        url: "Rebuild-Masonry.png",
        title: "Rebuild Masonry Stack",
        type: ['masonry']
    },
    {
        url: "Refractory-Panel.png",
        title: "Refractory Panel",
        type: ['prefab'],
    },
    {
        url: "Remove-Efflorescense.png",
        title: "Remove Efflorescense",
        type: ['masonry'],
    },
    {
        url: "Replace-Firebox-Fireplace.png",
        title: "Replace Firebox",
        type: ['prefrab'],
    },
    {
        url: "replaceGlassDoor.png",
        title: "Replace Glass Door",
        type: ['gas', 'insert', 'freestanding'],
    },
    {
        url: "Repoint.png",
        title: "Repoint Brickwork",
        type: ['masonry'],
    },
    {
        url: "Rope-Gasket-Installation.png",
        title: "Replace Rope Gasket",
        type: ['insert', 'freestanding', 'gas'],
    },
    {
        url: "Scafolding.png",
        title: "Scafolding",
        type: ['masonry', 'prefab'],
    },
    {
        url: "Spark-Screen.png",
        title: "Spark Screen",
        type: ['masonry', 'prefab', 'insert'],
    },
    {
        url: "Stucco-Repair.png",
        title: "Stucco Repair",
        type: ['masonry', 'prefab'],
    },
    {
        url: "Top-Plate.png",
        title: "Top Plate",
        type: ['insert'],
    },
    {
        url: "Waterproofing.png",
        title: "Spray Seal Masonry",
        type: ['masonry'],
    },
    {
        url: "woodChaseTrim.png",
        title: "Replace Wood Chase Trim",
        type: ['prefab'],
    },
    {
        url: "Worker.png",
        title: "Labor",
        type: ['masonry', 'prefab', 'gas', 'insert', 'freestanding', 'other'],
    },
]