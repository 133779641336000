import freestandingOverviewState from './freestandingOverviewForm/state';
import FreestandingVentPipeState from './FreestandingVentPipe/state';
import freestandingFlashingState from './freestandingFlashing/state';
import FreestandingSupportBox from './FreestandingSupportBox/state';
import freestandingChimneyPipeState from './freestandingChimneyPipe/state';
import freestandingStormCollarState from './FreestandingStormCollar/state';
import freestandingChimneyCapState from './freestandingChimneyCap/state';
import FreestandingApplianceState from './FreestandingAppliance/state';
import FreestandingHeatShieldState from './FreestandingHeatShield/state';
import FreestandingHearthState from './FreestandingHearth/state';
import FreestandingThimbleState from './FreestandingThimble/state';
import FreestandingFeederState from './FreestandingFeeder/state';
import freestandingHeightState from './FreestandingHeight/state';
import freestandingVentPipeProState from './FreestandingVentPipePro/state';

const state = {
  initState: {
    ...freestandingOverviewState.initState,
    ...freestandingHeightState.initState,
    ...FreestandingVentPipeState.initState,
    ...freestandingFlashingState.initState,
    ...FreestandingSupportBox.initState,
    ...freestandingChimneyPipeState.initState,
    ...freestandingStormCollarState.initState,
    ...freestandingChimneyCapState.initState,
    ...FreestandingApplianceState.initState,
    ...FreestandingHeatShieldState.initState,
    ...FreestandingHearthState.initState,
    ...FreestandingThimbleState.initState,
    ...FreestandingFeederState.initState,
    ...freestandingVentPipeProState.initState,
  },
  validation: (inspection) => ({
    ...freestandingOverviewState.validation(inspection),
    ...freestandingHeightState.validation(inspection),
    ...FreestandingVentPipeState.validation(inspection),
    ...freestandingFlashingState.validation(inspection),
    ...FreestandingSupportBox.validation(inspection),
    ...freestandingChimneyPipeState.validation(inspection),
    ...freestandingStormCollarState.validation(inspection),
    ...freestandingChimneyCapState.validation(inspection),
    ...FreestandingApplianceState.validation(inspection),
    ...FreestandingHeatShieldState.validation(inspection),
    ...FreestandingHearthState.validation(inspection),
    ...FreestandingThimbleState.validation(inspection),
    ...FreestandingFeederState.validation(inspection),
    ...freestandingVentPipeProState.validation(inspection),
  }),
};

export default state;
