import colors from 'theme/palette';

const styles = (theme) => ({
    cTAContainer: {
        backgroundColor: colors.lightBlue,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
        padding: theme.spacing(2, 4)
    },
    ctaText: {
        color: 'white',
    },
    ctaButtonHolder: {
        backgroundColor: colors.darkBlue,
        padding: theme.spacing(3),
    },
    buttonCta: {
        margin: theme.spacing(2),
        backgroundColor: colors.lightBlue,
        color: 'white',
        border: colors.lightBlue,
    },
    title: {
        fontWeight: 'bolder',
        color: 'white'
    },
    subText: {
        color: 'white'
    },

});

export default styles