const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(15),
    marginBotton: theme.spacing(10),
    fontWeight: 800,
    lineHeight: '4.2rem',
    fontSize: '4rem',
  },
  contentBlock: {
    margin: theme.spacing(5),
  },
  image: {
    maxWidth: '100%',
  },
  contentText: {
    margin: theme.spacing(2),
  },
});

export default styles