import React from 'react';
import clsx from 'clsx';
import {
  Collapse,
  FormHelperText,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Warning } from '@material-ui/icons';

import arePropsEqual from '../arePropsEqual';
import Diagram from '../Diagram';
import styles from './styles';

import { useSelector } from 'react-redux';

const useStyles = makeStyles(styles);

export const TYPE = {
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
  POSITIVE: 'positive'
};

const CustomButtonGroup = ({
  classes: rootClasses,
  diagram,
  errors,
  exclusive = true,
  handleBlur,
  handleChange,
  hint,
  id,
  label,
  options,
  touched,
  values,
  chim,
  app,
  inspectionId
}) => {
  const classes = useStyles();
  const onChange = (e, value) => {
    // Assign value to empty string as opposed to null
    handleChange({
      target: { id, value: value || '', chim, app }
    }).then(() => handleBlur({ target: { id } }));
  };
  let mfData = useSelector((state) => state.app.inspections[inspectionId].mfData);

  const STYLES = {
    [TYPE.NEGATIVE]: {
      root: classes.buttonNegative,
      selected: classes.buttonNegativeSelected
    },
    [TYPE.POSITIVE]: {
      root: classes.buttonPositive,
      selected: classes.buttonPositiveSelected
    },
    [TYPE.NEUTRAL]: {
      root: classes.buttonNeutral,
      selected: classes.buttonNeutralSelected
    }
  };

  const showError = (touched[id] || !!values[id]) && !!errors[id];

  return (
    <Grid
      container
      className={clsx(classes.root, rootClasses.field)}
      justifyContent="center"
      id={id + "Link"}
    >
      {options && options.length > 3 ? <>
        {label && (
          <Grid alignItems="center" justifyContent="center" container item xs={12} sm={4} md={6}>
            <Typography variant="h5" align="center" className={classes.buttonLabel}>
              {label}
            </Typography>
          </Grid>
        )}
        <Grid container alignItems="center" justifyContent='center' item xs={12} >
          <ToggleButtonGroup
            className={clsx(classes.buttonGroup, {
              [classes.buttonGroupNoLabel]: !label
            })}
            exclusive={exclusive}
            onChange={onChange}
            value={chim ? app ? mfData[chim][app].data[id] : mfData[chim].data[id] : values[id]}
          >
            {options.map(
              (
                { disabled = false, label, type = TYPE.NEUTRAL, value },
                index
              ) => (
                <ToggleButton
                  key={index}
                  classes={STYLES[type]}
                  disabled={disabled}
                  size="large"
                  value={value}
                  variant="outlined"
                >
                  {label}
                  {showError && !!errors[id] && options.length - 1 === index && (
                    <Warning color="error" />
                  )}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        </Grid> </> : <>
        {label && (
          <Grid item xs={12} sm={4} md={6}>
            <Typography variant="h5" className={classes.buttonLabel}>
              {label}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={8} md={6}>
          <ToggleButtonGroup
            className={clsx(classes.buttonGroup, {
              [classes.buttonGroupNoLabel]: !label
            })}
            exclusive={exclusive}
            onChange={onChange}
            value={chim ? app ? mfData[chim][app].data[id] : mfData[chim].data[id] : values[id]}
          >
            {options.map(
              (
                { disabled = false, label, type = TYPE.NEUTRAL, value },
                index
              ) => (
                <ToggleButton
                  key={index}
                  classes={STYLES[type]}
                  disabled={disabled}
                  size="large"
                  value={value}
                  variant="outlined"
                >
                  {label}
                  {showError && !!errors[id] && options.length - 1 === index && (
                    <Warning color="error" />
                  )}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        </Grid>
      </>}
      {hint && (
        <Grid item xs={12}>
          <FormHelperText
            className={clsx(rootClasses.helperText, classes.hintText)}
          >
            {hint}
          </FormHelperText>
        </Grid>
      )}
      {diagram && <Diagram justifyContent="center" {...diagram} />}
      <Grid item xs={12}>
        <Collapse in={showError}>
          <Grid container justifyContent="center">
            {label && <Grid item xs={false} sm={6} />}
            <Grid item xs={6}>
              <FormHelperText
                className={clsx(rootClasses.helperText, classes.buttonGroup, {
                  [classes.buttonGroupNoLabel]: !label
                })}
                error
              >
                {errors[id]}
              </FormHelperText>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomButtonGroup, arePropsEqual);
