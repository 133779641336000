import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const SigningInAndTour = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: Signing In and Tour</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4"> This is a video in our series of getting started videos about how to sign in and a tour of the app <br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/-mteqKcenj0" title="Getting Started: Signing in and tour" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/-mteqKcenj0" title="Getting Started: Signing in and tour" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: Signing In.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                Bear Hands Pro will remember your login on that device, and automaticly redirect you to the dashboard, so don't be alarmed if you don't see the front end anymore.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='create your admin account' src='/static/tutorials/signingIn/loginScreen.png' width='100%' />

                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                The Dashboard has 6 main sections.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                            <ol>
                                <li>The side bar will have links to your other locations. (only shows up on desktop)</li>
                                <li>The filters let you search for specific inspections types</li>
                                <li>The main dashboard wher you will find your companies inspections</li>
                                <li>You can search for specific inspections here, by name, tech name, or address</li>
                                <li>This will have your notifications and the cog will have how to change your account information and password (plus everything from sidebar on mobile) </li>
                                <li>This is where you can change pages or the number of pages your displaying.</li>
                            </ol>
                        </Grid>
                        <Grid item lg={6} className={classes.stepPicture} xs={12}>
                            <img alt='create your organization' src='/static/tutorials/SigningIn/dashboard.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                The Admin settings page has 3 sections
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                            <ol>
                                <li>The Org Info Tile</li>
                                <ul className={classes.nested}>
                                    <li>View your companies information</li>
                                    <li>Set up your company settings</li>
                                    <li>Set up financing</li>
                                </ul>
                                <li>The Users Tile</li>
                                <ul className={classes.nested}>
                                    <li>See all your companies user's accounts</li>
                                    <li>Create Accounts</li>
                                    <li>Edit Accounts</li>
                                    <li>Delete Accounts</li>
                                </ul>
                                <li>The Inspection Tile</li>
                                <ul className={classes.nested}>
                                    <li>Set your logo for reports and proposals</li>
                                    <li>Preview how your company info looks on reports</li>
                                    <li>Change your inspection order</li>
                                </ul>
                            </ol>
                        </Grid>
                        <Grid item lg={6} className={classes.stepPicture} xs={12}>
                            <img alt='create your organization' src='/static/tutorials/SigningIn/adminSettings.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={12} className={classes.endMessage}>
                            <Typography align='center' variant='h3'>
                                Be sure to check out our other sections where we cover responses and the proposal
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default SigningInAndTour;