import * as Yup from 'yup';
import {
  CUSTOM,
  NA,
  NO,
  PREFAB,
  SATISFACTORY,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  YES,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabChaseCoverState: '',
    prefabChaseCoverVersion: VERSION_PRO.value,
    prefabChaseCoverLiteIssues: [],
    prefabChaseCoverLitePhotos: [],
    prefabChaseCoverDamaged: '',
    prefabChaseCoverPhotos: [],
    prefabChaseCoverHoleLocation: '',
    prefabChaseCoverLength: { label: '', value: '' },
    prefabChaseCoverWidth: { label: '', value: '' },
    prefabChaseCoverLengthTwo: { label: '', value: '' },
    prefabChaseCoverWidthTwo: { label: '', value: '' },
    prefabChaseCoverCollarHeightA: { label: '', value: '' },
    prefabChaseCoverSkirtHeight: { label: '', value: '' },
    prefabChaseCoverDiameterA: { label: '', value: '' },
    prefabChaseCoverLengthAOne: { label: '', value: '' },
    prefabChaseCoverLengthATwo: { label: '', value: '' },
    prefabChaseCoverLengthAThree: { label: '', value: '' },
    prefabChaseCoverLengthAFour: { label: '', value: '' },
    prefabChaseCoverDiamterB: { label: '', value: '' },
    prefabChaseCoverLengthBOne: { label: '', value: '' },
    prefabChaseCoverLengthBTwo: { label: '', value: '' },
    prefabChaseCoverLengthBThree: { label: '', value: '' },
    prefabChaseCoverLengthBFour: { label: '', value: '' },
    prefabChaseCoverHelpNeeded: '',
    prefabChaseCoverMaterial: { value: '' },
    prefabChaseCoverColorDescription: '',
    prefabTrimDamaged: '',
    prefabChaseCoverGalvanizedColor: '',
    prefabTrimPhotos: [],
    prefabTrimColor: '',
    prefabTrimSize: { value: '' },
    prefabTrimCustomSize: '',
    prefabChaseShroud: '',
    prefabShroudOverhang: '',
    prefabChaseCapHeight: { label: '', value: '' },
    prefabChaseCoverOverviewPhoto: [],
    prefabChaseHeightOfCap: { label: '', value: '' },
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
    inspection.data.prefabChaseCoverVersion === VERSION_PRO.value
      ? //pro version validation
        {
          prefabChaseCoverState: Yup.string().required(REQUIRED),
          prefabChaseCoverDamaged: Yup.string().when(
            ['prefabChaseCoverVersion', 'prefabChaseCoverState'],
            {
              is: (version, state) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          prefabChaseCoverPhotos: Yup.array().when(
            [
              'prefabChaseCoverVersion',
              'prefabChaseCoverState',
              'prefabChaseCoverDamaged',
              'prefabChaseShroud',
              'prefabShroudOverhang',
            ],
            {
              is: (version, state, isDamaged, shroud, overhang) =>
                (version === VERSION_PRO.value &&
                  state === UNSATISFACTORY.value &&
                  isDamaged === YES.value) ||
                (version === VERSION_PRO.value &&
                  state === SATISFACTORY.value &&
                  shroud === YES.value &&
                  overhang === NO.value),
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          prefabTrimDamaged: Yup.string().when(
            ['prefabChaseCoverVersion', 'prefabChaseCoverState'],
            {
              is: (version, state) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          prefabTrimPhotos: Yup.array().when(
            [
              'prefabChaseCoverVersion',
              'prefabChaseCoverState',
              'prefabTrimDamaged',
            ],
            {
              is: (version, state, isDamaged) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                isDamaged === YES.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          prefabTrimColor: Yup.string().when(
            [
              'prefabChaseCoverVersion',
              'prefabChaseCoverState',
              'prefabTrimDamaged',
            ],
            {
              is: (version, state, isDamaged) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                isDamaged === YES.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          prefabTrimSize: Yup.object().when(
            [
              'prefabChaseCoverVersion',
              'prefabChaseCoverState',
              'prefabTrimDamaged',
            ],
            {
              is: (version, state, isDamaged) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                isDamaged === YES.value,
              then: Yup.object()
                .shape({
                  value: Yup.string().required(REQUIRED),
                })
                .required(REQUIRED),
              otherwise: Yup.object().notRequired(),
            }
          ),
          prefabTrimCustomSize: Yup.string().when(
            [
              'prefabChaseCoverVersion',
              'prefabChaseCoverState',
              'prefabTrimDamaged',
              'prefabTrimSize',
            ],
            {
              is: (version, state, isDamaged, size) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                isDamaged === YES.value &&
                size.value === CUSTOM.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          prefabChaseCoverOverviewPhoto: Yup.array().when(
            ['prefabChaseCoverState'],
            {
              is: (state) => state !== NA.value,
              then: Yup.array().required(REQUIRED),
              otherwise: Yup.array().notRequired(),
            }
          ),
          //lite version validation
        }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.prefabChaseCoverVersion === VERSION_LITE.value
      ? {
          prefabChaseCoverState: Yup.string().required(REQUIRED),
          prefabChaseCoverOverviewPhoto: Yup.array().when(
            ['prefabChaseCoverState'],
            {
              is: (state) => state !== NA.value,
              then: Yup.array().required(REQUIRED),
              otherwise: Yup.array().notRequired(),
            }
          ),
          prefabChaseCoverLitePhotos: Yup.array().when(
            ['prefabChaseCoverState', 'prefabChaseCoverVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value &&
                version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED),
              otherwise: Yup.array().notRequired(),
            }
          ),
        }
      : {}),
  }),
};

export default state;
