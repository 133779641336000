import * as Yup from 'yup';
import {
  INSERT,
  REPLACE,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    insertTopPlateState: '',
    insertTopPlatePhotos: [],
    insertTopPlateLiteIssues: [],
    insertTopPlateLitePhotos: [],
    insertTopPlateVersion: VERSION_PRO.value,
    insertTopPlateProposal: '',
    insertTopPlateLength: '',
    insertTopPlateWidth: '',
    insertTopPlateHole: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === INSERT.value &&
      inspection.data.insertVentState === UNSATISFACTORY.value &&
      inspection.data.insertTopPlateVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        insertTopPlateState: Yup.string().required(REQUIRED),
        insertTopPlatePhotos: Yup.array().when('insertTopPlateState', {
          is: UNSATISFACTORY.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }),
        insertTopPlateProposal: Yup.string().when('insertTopPlateState', {
          is: UNSATISFACTORY.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        insertTopPlateLength: Yup.string().when(
          ['insertTopPlateState', 'insertTopPlateProposal'],
          {
            is: (plateState, proposal) =>
              plateState === UNSATISFACTORY.value &&
              proposal === REPLACE.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        insertTopPlateWidth: Yup.string().when(
          ['insertTopPlateState', 'insertTopPlateProposal'],
          {
            is: (plateState, proposal) =>
              plateState === UNSATISFACTORY.value &&
              proposal === REPLACE.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        insertTopPlateHole: Yup.string().when(
          ['insertTopPlateState', 'insertTopPlateProposal'],
          {
            is: (plateState, proposal) =>
              plateState === UNSATISFACTORY.value &&
              proposal === REPLACE.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state