import * as Yup from 'yup';
import {
    FREESTANDING, UNSATISFACTORY, VERSION_LITE
} from 'components/app/InspectionForm/constants';
import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        freestandingStormCollarState: "",
        freestandingStormCollarVersion: '',
        freestandingStormCollarLiteIssues: [],
        freestandingStormCollarLitePhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value ? {
            freestandingStormCollarState: Yup.string().required(REQUIRED),
            freestandingStormCollarLitePhotos: Yup.array().when(
                ['freestandingStormCollarState', 'freestandingStormCollarVersion'],
                {
                    is: (state, version) =>
                        state === UNSATISFACTORY.value
                        && version === VERSION_LITE.value,
                    then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                    otherwise: Yup.array().notRequired(),
                }
            )
        } : {}),
    }),
};

export default state