export const KICKSERV = {
  label: "Kickserv",
  value: "KICKSERV",
};

export const SERVICE_TITAN = {
  label: "Service Titan",
  value: "SERVICE_TITAN",
};

export const SERVICE_MONSTER = {
  label: "Service Monster",
  value: "SERVICE_MONSTER",
};

export const HOUSECALL_PRO = {
  label: "Housecall Pro",
  value: "HOUSECALL_PRO",
};

export const STRIVEN = {
  label: "Striven",
  value: "STRIVEN",
};
