const styles = (theme) => ({
    ticketContainer: {
        marginTop: theme.spacing(1, 2),
    },
    form: {
        width: "100%"
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    submitButton: {
        margin: theme.spacing(2),
    }
});

export default styles