import * as Yup from 'yup';
import {
  BOTH,
  BOTTOM,
  FUEL_WOOD,
  HEAT_DAMAGE,
  LARGE,
  LEFT,
  OTHER,
  PREFAB,
  REAR,
  RIGHT,
  SMALLER,
  STRUCTURE_DAMAGE,
  UNSATISFACTORY,
  VERSION_PRO,
  YES,
  FUEL_GAS,
  VERSION_LITE,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    prefabFireboxState: '',
    prefabFireboxVersion: VERSION_PRO.value,
    prefabFireboxLiteIssues: [],
    prefabFireboxLitePhotos: [],
    prefabFireboxPanelPhotos: [],
    prefabFireboxPanelPattern: { value: '' },
    prefabFireboxPanelDescription: '',
    prefabFireboxPanelDamage: '',
    prefabFireboxWhichPanels: [],
    prefabFireboxRearPanelWidth: '',
    prefabFireboxRearPanelHeight: '',
    prefabFireboxLeftPanelWidth: '',
    prefabFireboxLeftPanelHeight: '',
    prefabFireboxRightPanelWidth: '',
    prefabFireboxRightPanelHeight: '',
    prefabFireboxBottomPanelWidth: '',
    prefabFireboxBottomPanelHeight: '',
    prefabFireboxGrateNeeded: '',
    prefabFireboxWhichGrate: { value: '' },
    prefabFireboxDamage: '',
    prefabFireboxDamagePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.overviewStructureFuelType === FUEL_WOOD.value &&
      inspection.data.prefabFireboxVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        prefabFireboxState: Yup.string().required(REQUIRED),
        prefabFireboxPanelPhotos: Yup.array().when(
          ['prefabFireboxVersion', 'prefabFireboxState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabFireboxPanelPattern: Yup.string().when(
          ['prefabFireboxVersion', 'prefabFireboxState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFireboxPanelDescription: Yup.string().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelPattern',
          ],
          {
            is: (version, state, pattern) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              pattern === OTHER.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFireboxPanelDamage: Yup.string().when(
          ['prefabFireboxVersion', 'prefabFireboxState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFireboxWhichPanels: Yup.array().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
          ],
          {
            is: (version, state, damage) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage),
            then: Yup.array().required(REQUIRED),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabFireboxRearPanelWidth: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(REAR.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxRearPanelHeight: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(REAR.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxLeftPanelWidth: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(LEFT.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxLeftPanelHeight: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(LEFT.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxRightPanelWidth: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(RIGHT.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxRightPanelHeight: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(RIGHT.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxBottomPanelWidth: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(BOTTOM.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxBottomPanelHeight: Yup.number().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxPanelDamage',
            'prefabFireboxWhichPanels',
          ],
          {
            is: (version, state, damage, panels) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [LARGE.value, SMALLER.value].includes(damage) &&
              panels.includes(BOTTOM.value),
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        prefabFireboxGrateNeeded: Yup.string().when(
          ['prefabFireboxVersion', 'prefabFireboxState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFireboxWhichGrate: Yup.string().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxGrateNeeded',
          ],
          {
            is: (version, state, grateNeeded) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              grateNeeded === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFireboxDamage: Yup.string().when(
          ['prefabFireboxVersion', 'prefabFireboxState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabFireboxDamagePhotos: Yup.string().when(
          [
            'prefabFireboxVersion',
            'prefabFireboxState',
            'prefabFireboxDamage',
          ],
          {
            is: (version, state, damage) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [
                BOTH.value,
                STRUCTURE_DAMAGE.value,
                HEAT_DAMAGE.value,
              ].includes(damage),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.overviewStructureFuelType === FUEL_WOOD.value &&
        inspection.data.prefabFireboxVersion === VERSION_LITE.value
        && inspection.data.overviewStructureFuelType !== FUEL_GAS.value ?
        {
          prefabFireboxState: Yup.string().required(REQUIRED),
          prefabFireboxLitePhotos: Yup.array().when(
            ['prefabFireboxState', 'prefabFireboxVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value && version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          )
        } : {}),
  }),
};

export default state