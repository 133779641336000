export const PRODUCT_MASONRY_CAP = {
  label: 'Chimney Cap',
  value: 'PRODUCT_MASONRY_CAP',
};
export const PRODUCT_CAP_EXTENSION_LEGS = {
  label: 'Chimney Cap Extender Legs',
  value: 'PRODUCT_CAP_EXTENSION_LEGS',
};
export const PRODUCT_CAP_LOCKTOP = {
  label: 'Top Mount Damper',
  value: 'PRODUCT_CAP_LOCKTOP',
};
// Prefab Cap Types
export const PRODUCT_CAP_AIRINSULATED = {
  label: 'Air Insulated',
  value: 'PRODUCT_CAP_AIRINSULATED',
};
export const PRODUCT_CAP_SOLIDPACK = {
  label: 'Solid Pack',
  value: 'PRODUCT_CAP_SOLIDPACK',
};
// Common Prefab Chimney Cap Sizes
export const PRODUCT_CAP_6R = {
  label: '6" Round',
  value: 'PRODUCT_CAP_6R',
};
export const PRODUCT_CAP_8R = {
  label: '8" Round',
  value: 'PRODUCT_CAP_8R',
};
export const PRODUCT_CAP_10R = {
  label: '10" Round',
  value: 'PRODUCT_CAP_10R',
};
// Common Masonry Chimney Cap Sizes
export const PRODUCT_CAP_8_8 = {
  label: '8" x 8"',
  value: 'PRODUCT_CAP_8_8',
};
export const PRODUCT_CAP_8_13 = {
  label: '8" x 13"',
  value: 'PRODUCT_CAP_8_13',
};
export const PRODUCT_CAP_8_17 = {
  label: '8" x 17"',
  value: 'PRODUCT_CAP_8_17',
};
export const PRODUCT_CAP_8_19 = {
  label: '8" x 19"',
  value: 'PRODUCT_CAP_8_19',
};
export const PRODUCT_CAP_12_12 = {
  label: '12" x 12"',
  value: 'PRODUCT_CAP_12_12',
};
export const PRODUCT_CAP_13_13 = {
  label: '13" x 13"',
  value: 'PRODUCT_CAP_13_13',
};
export const PRODUCT_CAP_13_17 = {
  label: '13" x 17"',
  value: 'PRODUCT_CAP_13_17',
};
export const PRODUCT_FULL_COVERAGE_CAP = {
  label: 'Full Coverage Cap',
  value: 'PRODUCT_FULL_COVERAGE_CAP',
};
