import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  ArrowForwardIos,
  Cancel,
  Code,
  Info,
  PriorityHigh,
} from '@material-ui/icons';
import {
  // Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import styles from './styles';

const icons = {
  error: {
    icon: <PriorityHigh />,
    color: 'red',
  },
  info: {
    icon: <Info />,
    color: 'blue',
  },
  feature: {
    icon: <Code />,
    color: 'purple',
  },
};

const useStyles = makeStyles(styles);

const NotificationList = ({
  className,
  notifications,
  notificationsTotal,
  onClickNotification,
  handleReloadApp,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {notifications.length > 0 ? (
        <>
          <div className={classes.header}>
            <Typography variant="h6">Notifications</Typography>
            <Typography className={classes.subtitle} variant="body2">
              {notificationsTotal} total
            </Typography>
          </div>
          <div className={classes.content}>
            <List component="div">
              {notifications.map((notification) => (
                <RouterLink key={notification.id} to={notification.route}>
                  <ListItem
                    className={classes.listItem}
                    component="div"
                    onClick={() => onClickNotification(notification.id)}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ color: icons[notification.type].color }}
                    >
                      {icons[notification.type].icon}
                    </ListItemIcon>
                    <ListItemText
                      classes={{ secondary: classes.listItemTextSecondary }}
                      primary={notification.title}
                      secondary={notification.when.toRelative()}
                    />
                    {notification.title ===
                      'Updated app. click below to refresh and update.' && (
                      <ListItemText
                        classes={{ secondary: classes.listItemTextSecondary }}
                        primary={
                          <React.Fragment>
                            <Button
                              onClick={() => handleReloadApp()}
                              color="primary"
                            >
                              Reload App
                            </Button>
                          </React.Fragment>
                        }
                      ></ListItemText>
                    )}

                    {notification.route === '#' ? (
                      <Cancel className={classes.listItemActionIcon} />
                    ) : (
                      <ArrowForwardIos className={classes.listItemActionIcon} />
                    )}
                  </ListItem>
                  {/* <Divider /> */}
                </RouterLink>
              ))}
            </List>
          </div>
        </>
      ) : (
        <div className={classes.empty}>
          <Typography variant="h4">All caught up!</Typography>
        </div>
      )}
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  NotificationList.propTypes = {
    className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    notificationsTotal: PropTypes.number.isRequired,
    onClickNotification: PropTypes.func.isRequired,
  };
}

export default NotificationList;
