import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Container,
} from '@material-ui/core';


import styles from './styles';
import TutorialCard from '../components/TutorialCard';


const useStyles = makeStyles(styles);

const Tutorials = ({ ...rest }) => {

    const classes = useStyles();


    return (
        <Grid container>
            <Container>

                <Grid container justifyContent="space-between" direction="row">
                    <Grid
                        item
                        container
                        justifyContent="space-around"
                        alignItems="flex-start"
                        lg={12}
                        xs={12}
                        className={classes.contentBlockRight}
                    >
                        <Grid item xs={12} className={classes.titleBox}>
                            <Typography
                                align="center"
                                className={classes.boxTextRightTitle}
                                variant="h2"
                            >
                                Tutorials
                            </Typography>
                            <Typography
                                align="center"
                                className={classes.boxTextRightSubtext}
                                variant="h4"
                            >
                                Become an expert in no time
                            </Typography>
                        </Grid>

                        <Grid item container justifyContent="flex-start" alignItems="flex-start" lg={6} xs={12}>
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    className={classes.boxTextRight}
                                    variant="h4"
                                >
                                    <span style={{ textDecoration: 'underline' }}>Admin/Office</span>
                                </Typography>
                            </Grid>
                            <Grid item container className={classes.tileContainer} xs={12}>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title='Getting Started (1): Signing Up'
                                        image='/static/tutorials/creatingYourCompany/signingUp.jpg'
                                        description="How to sign up for Bear Hands Pro"
                                        link='/guide/signing-up'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title='Getting Started (2): Signing In and Tour of App'
                                        image='/static/tutorials/signingIn/signingInThumb.jpg'
                                        description="How to sign into the app(Tour of Bear Hands Pro Application)."
                                        link='/guide/sign-in-and-tour'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Getting Started (3): Changing Your Company's Info"
                                        image='/static/tutorials/changingCompanyInfo/changingCompanyInfoTile.jpg'
                                        description="How to change company info."
                                        link='/guide/changing-company-info'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Getting Started (4): Org Settings and Adding Logo"
                                        image='/static/tutorials/orgSettings/orgSettingsTile.jpg'
                                        description="How to update organization settings and add your company logo."
                                        link='/guide/org-settings'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Getting Started (5): Creating and managing accounts"
                                        image='/static/tutorials/creatingUsers/creatingUsers.jpg'
                                        description="How to set up user accounts"
                                        link='/guide/creating-users'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Getting Started (6): Inspection order and how reports work"
                                        image='/static/tutorials/inspectionOrder/inspectionOrderTile.jpg'
                                        description="How to change your inspection order, and how the report process works."
                                        link='/guide/inspection-order'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Getting Started (7): Responses, everything you need to know"
                                        image='/static/tutorials/responses/responsesTile.jpg'
                                        description="How to use the responses system in bhp."
                                        link='/guide/responses'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Getting Started With Bear Hands Pro (8): Adding your client list"
                                        image='/static/tutorials/clients/clientTile.jpg'
                                        description="How to add a client list to Bear Hands Pro"
                                        link='/guide/clients'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Client Table"
                                        image='/static/guides/clientTable/clientTableTile.jpg'
                                        description="How to use the new client table."
                                        link='/guide/clienttable'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Kickserv Integraion"
                                        image='/static/tutorials/kickservIntegration/kickservIntegrationTile.jpg'
                                        description="How to setup and use our new Kickserv Integration."
                                        link='/guide/kickserv-integration'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Send Copy of Report to an email"
                                        image='/static/tutorials/sendCopytoEmail/sendCopytoEmailTile.jpg'
                                        description="How to send a copy of the report to an email."
                                        link='/guide/send-copy-to-email'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container justifyContent="flex-start" alignItems="flex-start" lg={6} xs={12}>
                            <Grid item xs={12}>
                                <Typography
                                    align="center"
                                    className={classes.boxTextRight}
                                    variant="h4"
                                >
                                    <span style={{ textDecoration: 'underline' }}>Technician</span>
                                </Typography>
                            </Grid>
                            <Grid item container className={classes.tileContainer} xs={12}>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Bear Hands Pro - How To Do an Inspection (1)"
                                        image='/static/tutorials/inspectionHowTo/inspectionHowTo.jpg'
                                        description="How to perform an inspection with Bear Hands Pro(Part One)"
                                        link='/guide/inspection-how-to'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Bear Hands Pro - How to Do an Inspection (2) Report and proposal"
                                        image='/static/tutorials/reportsAndPropsHowTo/reportsAndPropsHowToTile.jpg'
                                        description="How to do an inspection with Bear Hands Pro(Part Two). This video covers the Report and Proposal section"
                                        link='/guide/reports-proposals-how-to'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Bear Hands Pro - How to Edit Reports"
                                        image='/static/tutorials/editReports/HowToEditReportsTile.jpg'
                                        description="How to find, edit, and download reports."
                                        link='/guide/how-to-edit-reports'
                                    />
                                </Grid>
                                <Grid item className={classes.tiles} md={4} xs={6}>
                                    <TutorialCard
                                        title="Bear Hands Pro - Downloading to your device"
                                        image='/static/tutorials/downloadingBHP/tableTile.jpg'
                                        description="How to download Bear Hands Pro onto an Android or IOS device."
                                        link='/guide/download-bhp'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default Tutorials;
