import { NO } from 'components/app/InspectionForm/constants';

const state = {
  initState: {
    addAdditionalPhotos: NO.value,
    additionalPhotos: [],
  },
  validation: () => ({}),
};

export default state;
