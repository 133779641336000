import * as Yup from 'yup';

import { REQUIRED } from '../stateConstants';
import { YES, DRYER_VENT } from 'components/app/InspectionForm/constants';

const state = {
  initState: {
    previewTechSignature: { date: '', url: '' },
    previewClientSignature: { date: '', url: '' },
    previewDamperStatus: '',
    previewSendReportToClient: YES.value,
    previewReportEmail: '',
    previewPropType: ['fullProp'],
  },
  validation: Yup.object().shape({
    previewDamperStatus: Yup.string().when(['overviewInspectionLevel'],
      {
        is: (level) => level !== DRYER_VENT.value,
        then: Yup.string().required(REQUIRED),
        otherwise: Yup.string().notRequired()
      }),
    previewSendReportToClient: Yup.string().required(REQUIRED),
    previewReportEmail: Yup.string().when('previewSendReportToClient', {
      is: YES.value,
      then: Yup.string().required(REQUIRED),
      otherwise: Yup.string().notRequired()
    })
  })
};

export default state