import * as Yup from 'yup';
import {
    FREESTANDING, FUEL_PELLET, UNSATISFACTORY, VERSION_LITE,
} from 'components/app/InspectionForm/constants';
import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        freestandingFeederState: "",
        freestandingFeederVersion: '',
        freestandingFeederLiteIssues: [],
        freestandingFeederPipState: '',
        freestandingFeederLitePhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value && inspection.data.overviewStructureFuelType === FUEL_PELLET.value ? {
            freestandingFeederState: Yup.string().required(REQUIRED),
            freestandingFeederLitePhotos: Yup.array().when(
                ['freestandingFeederVersion', 'freestandingFeederState'],
                {
                    is: (version, state) =>
                        version === VERSION_LITE.value && state === UNSATISFACTORY.value,
                    then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                    otherwise: Yup.array().notRequired(),
                }
            )
        } : {}),
    }),
};

export default state