import * as Yup from 'yup';
import {
    DRYER_VENT, YES
} from 'components/app/InspectionForm/constants'

import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        dryerventOverviewDryerPhotos: [],
        dryerventOverviewDryerDataPlate: '',
        dryerventOverviewDataPlatePhoto: [],
        dryerventOverviewMake: '',
        dryerventOverviewModel: '',
        dryerventOverviewEnergySource: '',
        dryerventOverviewExhaustLocation: '',
        dryerventOverviewAVBFDRYER: ''

    },
    validation: (inspection) => (
        inspection.data.overviewInspectionLevel === DRYER_VENT.value ?
            {
                dryerventOverviewDryerPhotos: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                dryerventOverviewDryerDataPlate: Yup.string().required(REQUIRED),
                dryerventOverviewDataPlatePhoto: Yup.array().when(
                    ['dryerventOverviewDryerDataPlate'], {
                    is: (plate) => plate === YES.value,
                    then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                    otherwise: Yup.array().notRequired(),
                }
                )
            } : {})
};

export default state