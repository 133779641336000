import React from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    DialogTitle,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Divider,
} from '@material-ui/core';

const TermsAndConditionsDialog = ({ showTerms, handleClose, classes, handleBack, handleNext, enableNext, terms, setTerms }) => {
    const handleConfirmTerms = () => {
        setTerms(!terms);
    }

    return (
        <Dialog open={showTerms} onClose={handleClose} fullWidth aria-labelledby='sign-up-terms-form-title'>
            <DialogTitle className={classes.dialogTitleContainer} id='sign-up-terms-form-title'><Typography className={classes.dialogTitle} variant='h2' component='p'>Terms And Conditions 3/4</Typography></DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.formFeild} align='center' variant='h6'>
                    BEAR HANDS PRO, INC. CHIMNEY INSPECTION APP <br />TERMS AND CONDITIONS
                </Typography>
                <Typography className={classes.formFeild} variant='body1'>
                    Bear Hands Pro, Inc.’s Chimney Inspection App (the “App”) is intended to act as a guide for chimney and hearth inspectors when conducting chimney and hearth inspections. This App is intended to work in conjunction with NFPA 211 (2019 edition). The User acknowledges and agrees that this App is just a guide and that the User is obligated to follow all applicable local codes, regulations and laws when conducting their chimney and hearth inspections. If there are any differences between this App and the User’s applicable local codes, regulations and laws, then the User shall follow the local codes, regulations and laws that apply. This App is not a substitute for the applicable codes, regulations and laws or the applicable product manuals. Please contact your building department or code officials (Authority Having Jurisdiction) for the codes they have adopted and follow.
                </Typography>
                <Typography className={classes.formFeild} variant="body1">
                    The App also is not intended to identify every potential issue that the User may encounter during their chimney and hearth inspection. Small differences in individual circumstances can be very important in resolving chimney and hearth problems and the general guidance provided by this App cannot take into account all such differences. There are also many variables (i.e. usage, weather, fuel, etc.) that can change quickly and the timeliness and accuracy of the information contained in the App cannot be and is not guaranteed.
                </Typography>
                <Typography className={classes.formFeild} variant="body1">
                    The chimney and hearth inspector should use their best judgment in determining whether the chimney or hearth requires any repair or service and should not rely solely on the App. The information gathered on site, along with the applicable local codes, regulations and laws, the product manuals and all other applicable information should be consulted when providing opinions or recommendations regarding a client’s hearth and chimney system.
                </Typography>
                <Typography className={classes.formFeild} variant="body1">
                    Bear Hands Pro, Inc. has no control over how, when or why the App is used by the User and no such control is implied. The User of the App accepts any and all liability associated with their use of the App.
                </Typography>
                <Typography className={classes.formFeild} variant="body1">
                    <span style={{ textDecoration: 'underline' }}>
                        The User of the App expressly agrees to defend, indemnify and hold Bear Hands Pro, Inc. harmless to the fullest extent permitted by law, from any and all allegations, claims, actions, suits, demands, damages, liabilities, obligations, losses, settlements, judgments, costs and expenses (including, without limitation, actual attorneys’ fees and costs) which, in any way arise out of, relate to or result from any use of the App.
                    </span>
                </Typography>
                <Typography className={classes.formFeild} variant="body1">
                    Bear Hands Pro, Inc. makes no guaranty as to the accuracy or completeness of the information on the App and the User must rely on their own professional judgment in conjunction with the applicable local codes, regulations and laws, the product manuals and all other applicable information when conducting their chimney and hearth inspection or offering any opinions or recommendations following the same. Bear Hands Pro, Inc. maintains the right to change or amend the App as it sees fit. The App is the intellectual property of Bear Hands Pro, Inc. and the User’s use of the App is limited to its license purchased. All warranties, express or implied, are specifically disclaimed.
                </Typography>
                <Typography className={classes.formFeild} variant="body1">
                    Bear Hands Pro, Inc. shall not be responsible or liable to any company or person in any manner for any claims, suits or alleged suits for damages brought by any third party against said company or persons arising out of the use of the App. Bear Hands Pro, Inc. reserves the right to terminate a User’s access or use to the App at any time and in its sole discretion.
                </Typography>
                <Divider />
                <Grid container justifyContent='center'>
                    <FormGroup className={classes.confirmTerms} row>
                        <FormControlLabel
                            control={<Checkbox checked={terms} color='primary' onChange={handleConfirmTerms} name='confirm terms' />}
                            label='I have read and accept all terms and conditions'
                        />
                    </FormGroup>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button variant='outlined' id='toCompany' onClick={handleBack} color='primary'>
                    back
                </Button>
                <Button variant='outlined' disabled={!enableNext} onClick={handleNext} id='toPromo' color='primary'>
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default TermsAndConditionsDialog;