import { colors } from '@material-ui/core';
import colorPallet from 'theme/palette';

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(3),
    marginBotton: theme.spacing(10),
    fontWeight: 800,
    lineHeight: '4.2rem',
    fontSize: '4rem',
  },
  contentBlock: {
    margin: theme.spacing(5),
  },

  image: {
    maxWidth: '100%',
  },
  textSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  tabPanel: {
    maxWidth: '100%',
    margin: 'Auto',
  },
  versionTile: {
    maxWidth: '100%',
    minWidth: 275,
  },
  paper: {
    height: '100%',
  },
  versionTileTitle: {
    backgroundColor: colorPallet.lightBlue,
    padding: theme.spacing(2),
    MarginBottom: theme.spacing(2),
    color: 'white',
  },
  versionTilePrice1: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  versionTilePrice2: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  checkMark: {
    color: colors.green[500],
  },
  ctaTitle: {
    margin: theme.spacing(0, 4),
    paddingTop: theme.spacing(2),
    fontWeight: 800,
    color: colorPallet.lightBlue,
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
  dialogTitleContainer: {
    backgroundColor: colorPallet.darkBlue,
  },
  dialogTitle: {
    color: colorPallet.white,
  },
  dialogContent: {
    height: '100%',
    margin: theme.spacing(3),
  },
  formFeild: {
    margin: theme.spacing(1, 0)
  },
  confirmTerms: {
    margin: 'auto'
  },
  nested: {
    margin: theme.spacing(0, 2)
  },
  warning: {
    color: colors.red[400]
  }
});

export default styles