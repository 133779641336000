import colors from 'theme/palette';

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(15),
    marginBotton: theme.spacing(10),
    fontWeight: 800,
    lineHeight: '4.2rem',
    fontSize: '4rem',
  },
  contentBlock: {
    margin: theme.spacing(5),
  },
  image: {
    maxWidth: '100%',
  },
  contentTitle: {
    margin: theme.spacing(2, 2, 1, 2)
  },
  contentText: {
    margin: theme.spacing(0, 2, 0, 2),
  },
  subHeading: {
    margin: theme.spacing(2)
  },
  reportCardSection: {
    backgroundColor: colors.lightBlue,
  },
  contentTitleReportCard: {
    margin: theme.spacing(2, 2, 1, 2),
    color: 'white'
  },
  contentTextReportCard: {
    margin: theme.spacing(0, 2, 0, 2),
    color: 'white'
  },
});

export default styles