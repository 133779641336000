import { useState } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';
import useFormState from 'utils/forms/useFormState';
import { resetPasswordRequest } from 'actions/auth';

const useStyles = makeStyles(styles);

const PasswordResetForm = ({ isLoading, resetPasswordRequest }) => {
  const classes = useStyles();

  const [hasSubmitted, setSubmitted] = useState(false);

  const {
    state: { values, errors },
    handleChange,
    handleError,
    handleSuccess,
  } = useFormState(
    {
      email: '',
    },
    'email'
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPasswordRequest(values)
      .then(() => {
        setSubmitted(true);
        // Clear errors
        handleSuccess();
      })
      .catch(handleError);
  };

  return (
    <form className={classes.root} noValidate onSubmit={handleSubmit}>
      <Typography className={classes.title} variant="h2">
        Reset Password
      </Typography>
      <div className={classes.fields}>
        <TextField
          id="email"
          label="Email address"
          autoComplete="email"
          autoFocus
          required
          type="email"
          className={classes.textField}
          variant="outlined"
          onChange={handleChange}
          value={values.email}
        />
        <Typography className={classes.fieldError} variant="body2">
          {errors.email}
        </Typography>
      </div>
      <Typography className={classes.submitInfo} variant="body2">
        {hasSubmitted &&
          'An email will be sent shortly if an account exists with that email'}
      </Typography>
      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          disabled={isLoading || hasSubmitted}
          onClick={handleSubmit}
          color="primary"
          className={classes.submitButton}
          size="large"
          variant="contained"
        >
          Reset Password
        </Button>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
      {/* Removed because we currently dont offer a signup */}
      {/* <Typography className={classes.subText} variant="body1">
        Don't have an account?{' '}
        <Link
          className={classes.subTextUrl}
          to="/signup"
          component={RouterLink}
        >
          Sign Up
        </Link>
      </Typography> */}
      <Typography className={classes.subText} variant="body1">
        Have an account?{' '}
        <Link className={classes.subTextUrl} to="/login" component={RouterLink}>
          Sign In
        </Link>
      </Typography>
    </form>
  );
};

if (process.env.NODE_ENV !== 'production') {
  PasswordResetForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    resetPasswordRequest: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

export default compose(
  connect(mapStateToProps, { resetPasswordRequest }),
  publicComponent
)(PasswordResetForm);
