import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';
const state = {
  initState: {
    masonryCompleteRebuildState: '',
    masonryCompleteRebuildVersion: VERSION_LITE.value,
    masonryCompleteRebuildLiteIssues: [],
    masonryCompleteRebuildLitePhotos: [],
    masonryFaceState: '',
    masonryCompleteRebuildStackheight: '',
    masonryGetQuoteFromOffice: '',
    masonryCompleteRebuildShoulderHeight: '',
    masonryCompleteRebuildShoulderWidth: '',
    masonryCompleteRebuildPhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && inspection.data.masonryCompleteRebuildVersion === VERSION_PRO.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryCompleteRebuildState: Yup.string().required(REQUIRED),
        masonryFaceState: Yup.string().when(
          ['overviewChimneyType', 'masonryCompleteRebuildState', ',masonryCompleteRebuildVersion'],
          {
            is: (type, state, version) =>
              type === MASONRY.value && state === UNSATISFACTORY.value && version === VERSION_PRO.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryCompleteRebuildStackheight: Yup.string().when(
          ['overviewChimneyType', 'masonryCompleteRebuildState'],
          {
            is: (type, state) =>
              type === MASONRY.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryGetQuoteFromOffice: Yup.string().when(
          ['overviewChimneyType', 'masonryCompleteRebuildState'],
          {
            is: (type, state) =>
              type === MASONRY.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryCompleteRebuildShoulderHeight: Yup.string().when(
          ['overviewChimneyType', 'masonryCompleteRebuildState'],
          {
            is: (type, state) =>
              type === MASONRY.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryCompleteRebuildShoulderWidth: Yup.string().when(
          ['overviewChimneyType', 'masonryCompleteRebuildState'],
          {
            is: (type, state) =>
              type === MASONRY.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryCompleteRebuildPhotos: Yup.array().when(
          ['overviewChimneyType', 'masonryCompleteRebuildstate'],
          {
            is: (type, state) =>
              type === MASONRY.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryCompleteRebuildLitePhotos: Yup.array().when(
          ['masonryCompleteRebuildState', 'masonryCompleteRebuildVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        )
      }
      : {}),
  }),
};

export default state