export const SERVICE_INSULATION_BLANKET = {
  label: 'Install Ceramic Fiber Insulation Blanket',
  value: 'SERVICE_INSULATION_BLANKET'
};
export const SERVICE_DOOR_INSULATION = {
  label: 'Install Door Insulation',
  value: 'SERVICE_DOOR_INSULATION'
};
export const SERVICE_SURROUND_INSULATION = {
  label: 'Install Surround Insulation',
  value: 'SERVICE_SURROUND_INSULATION'
};
