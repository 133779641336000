import debounce from 'lodash.debounce';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';

import * as Sentry from '@sentry/react';

import rootReducer from './reducers';
import { saveAllInspections } from 'actions/inspections';

const middleware = [thunk];

//Sentry Redux Monitoring
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  sentryReduxEnhancer
);
const store = createStore(rootReducer, enhancer);

const saveStore = () => {
  store.dispatch(saveAllInspections(store.getState().app));
};

store.subscribe(debounce(saveStore, 2500));

export default store;
