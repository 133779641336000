import * as Yup from 'yup';
import {
    FREESTANDING,
    FREESTANDING_VENT_PIPE_CLEARANCE,
    APPLIANCE_PROTECTED_SIDEWALL_CLEARANCE,
    APPLIANCE_UNPROTECTED_SIDEWALL_CLEARANCE,
    FREESTANDING_VENT_ATTIC_CLEARANCE,
    FREESTANDING_AIR_SPACE_SUPPORT,
    FREESTANDING_LOADING_SIDE_CLEARANCE,
    CEILING,
} from 'components/app/InspectionForm/constants'

import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';


const state = {
    initState: {
        freestandingOverviewInteriorOverviewPhotos: [],
        freestandingCombustableWalls: '',
        freestandingCombustableFloor: '',
        freestandingHeatSheild: '',
        freestandingDataTag: '',
        freestandingClearanceLoadingSide: FREESTANDING_LOADING_SIDE_CLEARANCE,
        freestandingClearanceToProtectedSideWall: APPLIANCE_PROTECTED_SIDEWALL_CLEARANCE,
        freestandingClearanceToUnprotectedSideWall: APPLIANCE_UNPROTECTED_SIDEWALL_CLEARANCE,
        freestandingClearanceAttic: FREESTANDING_VENT_ATTIC_CLEARANCE,
        freestandingClearanceAirSpace: FREESTANDING_AIR_SPACE_SUPPORT,
        freestandingDataTagPhotos: [],
        freestandingClearanceOfVentPipe: FREESTANDING_VENT_PIPE_CLEARANCE,
        freestandingApplianceManufactorer: '',
        freestandingApplianceModelNum: '',
        freestandingPipeSize: { label: '', value: '' },
        freestandingPipeSizeOuter: { label: '', value: '' },
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value
            ? {
                freestandingOverviewInteriorOverviewPhotos: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                freestandingCombustableWalls: Yup.string().required(REQUIRED),
                freestandingCombustableFloor: Yup.string().required(REQUIRED),
                freestandingHeatSheild: Yup.string().required(REQUIRED),
                freestandingPipeSize: Yup.object().shape({
                    value: Yup.string().when(['overviewApplianceType', 'overviewFreestandingVentType'],
                        {
                            is: (state, ventType) =>
                                state === FREESTANDING.value && ventType === CEILING.value,
                            then: Yup.string().required(REQUIRED),
                            otherwise: Yup.string().notRequired(),
                        })
                })
            }
            : {}),
    }),
};

export default state