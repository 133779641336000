export const FREESTANDING_VENT_PIPE_CLEARANCE = {
    label: '18"',
    value: 18
}
export const APPLIANCE_PROTECTED_SIDEWALL_CLEARANCE = {
    label: '18"',
    value: 18
}
export const APPLIANCE_UNPROTECTED_SIDEWALL_CLEARANCE = {
    label: '36"',
    value: 36
}
export const FREESTANDING_VENT_ATTIC_CLEARANCE = {
    label: '2"',
    value: 2
}
export const FREESTANDING_AIR_SPACE_SUPPORT = {
    label: '1"',
    value: 1
}
export const FREESTANDING_LOADING_SIDE_CLEARANCE = {
    label: '18"',
    value: 18,
}