import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const InspectionOrder = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: Inspection Order</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4"> This video is one of our series of getting started videos about changing your inspection order and how reports work. <br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/pvtmizZUswk" title="Getting Started: Inspection Order" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/pvtmizZUswk" title="Getting Started: Inspection Order" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: Changing Inspection Order.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={10} className={classes.stepText}>
                            <Typography variant='h6'>
                                Bear Hands Pro inspections are built out of component, where you tech must select satisfactory, defect or NA for each one.  On default these components follow a work flow where your tech would start
                                by setting up inside the home at firebox, then going up on the roof and finishing inside the home at the firebox. You can change this order to what ever makes more sense for you company.
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={6} className={classes.stepText}>
                            <Typography variant='h6'>
                                To view your inspection order use the switches at the top to select what type of inspection your are doing. These will change out the components to the order your tech would see in the feild.
                            </Typography>
                        </Grid>
                        <Grid item lg={3} className={classes.stepPicture} xs={12}>
                            <img alt='Select what type of inspection is you would like to change the order of.' src='/static/tutorials/inspectionOrder/inspectionSelector.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={6} className={classes.stepText}>
                            <Typography variant='h6'>
                                The components will list out as tiles in the order they show up durring an inspection. All these tiles are dragable, drop them where you would like in the inspection.
                            </Typography>
                        </Grid>
                        <Grid item lg={3} className={classes.stepPicture} xs={12}>
                            <img alt='The components will list out as tiles in the order they show up durring an inspection. ' src='/static/tutorials/inspectionOrder/componentSection.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={6} className={classes.stepText}>
                            <Typography variant='h6'>
                                At the bottom you can save your changes, cancel changes you made, or reset to default. Cancel: sets your inspection order back to what it was saved as for your company. Reset to default: Will set it back to the order it comes with on your account creation. Once you have reset to default we can't recover your old order.
                            </Typography>
                        </Grid>
                        <Grid item lg={3} className={classes.stepPicture} xs={12}>
                            <img alt="Don't forget to save your changes" src='/static/tutorials/inspectionOrder/controls.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={12} className={classes.endMessage}>
                            <Typography align='center' variant='h3'>
                                What's the best order for your company?
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default InspectionOrder;