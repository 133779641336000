import * as Yup from 'yup';
import {
  FREESTANDING,
  FUEL_GAS,
  UNSATISFACTORY,
  UTILITY_CHIMNEY,
  VERSION_LITE,
  VERSION_PRO,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    gasFireboxState: '',
    gasFireboxVersion: VERSION_PRO.value,
    gasFireboxLiteIssues: [],
    gasFireboxLitePhotos: [],
    gasFireboxPhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewStructureFuelType === FUEL_GAS.value
      && inspection.data.overviewApplianceType !== UTILITY_CHIMNEY.value
      && inspection.data.overviewApplianceType !== FREESTANDING.value
      ? {
        gasFireboxState: Yup.string().required(REQUIRED),
        gasFireboxPhotos: Yup.array().when(['gasFireboxState', 'gasFireboxVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }),
        gasFireboxLitePhotos: Yup.array().when(
          ['gasFireboxState', 'gasFireboxVersion'], {
          is: (state, version) =>
            state === UNSATISFACTORY.value && version === VERSION_LITE.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        },
        )
      }
      : {}),
  }),
};

export default state
