import React from 'react';
import AppsIcon from '@material-ui/icons/AppsOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import InputIcon from '@material-ui/icons/Input';
import SettingsIcon from '@material-ui/icons/Settings';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ChatIcon from '@material-ui/icons/Chat';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FireplaceIcon from '@material-ui/icons/Fireplace';

import { VERSION_CHECKARTEC } from 'components/app/InspectionForm/constants';

const {
  ADMIN_SETTINGS_VIEW,
  DASHBOARD_VIEW,
  ADD_CLIENT,
  EDIT_RESPONSE,
  TECH_VIEW,
  BILLING_VIEW,
} = require('common/rbac/actions');

const pages = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <AppsIcon />,
    action: DASHBOARD_VIEW,
  },
  {
    title: 'Add Clients',
    href: '/addclient',
    icon: <GroupAddIcon />,
    action: ADD_CLIENT,
  },
  {
    title: 'Responses',
    href: '/responses',
    icon: <ChatIcon />,
    action: EDIT_RESPONSE,
  },
  {
    title: 'Inserts',
    href: '/Inserts',
    icon: <FireplaceIcon />,
    action: ADMIN_SETTINGS_VIEW,
  },
  {
    title: 'Truck Check',
    href: '/truckcheck',
    icon: <LocalShippingIcon />,
    version: VERSION_CHECKARTEC.value,
    action: TECH_VIEW,
  },
  {
    title: 'Refractory Panel Disclaimer',
    href: '/refractorypaneldisclaimer',
    version: VERSION_CHECKARTEC.value,
    icon: <AssignmentIcon />,
    action: TECH_VIEW,
  },
  {
    title: 'Refractory Panel Curring Instructions',
    href: '/refractorypanelcuringinstructions',
    version: VERSION_CHECKARTEC.value,
    icon: <AssignmentIcon />,
    action: TECH_VIEW,
  },
  {
    title: 'Admin Settings',
    href: '/admin',
    icon: <SupervisorAccountIcon />,
    action: ADMIN_SETTINGS_VIEW,
  },
  {
    title: 'Resources',
    href: '/resources',
    icon: <BusinessCenterIcon />,
    action: DASHBOARD_VIEW,
  },
  {
    title: 'Tutorials',
    href: '/tutorials',
    icon: <MenuBookIcon />,
    action: DASHBOARD_VIEW,
  },
  {
    title: 'Support',
    href: '/support',
    icon: <HelpOutlineIcon />,
    action: DASHBOARD_VIEW,
  },
  {
    title: 'Billing',
    href: '/billing',
    icon: <CreditCardIcon />,
    action: BILLING_VIEW,
  },
  {
    title: 'User Settings',
    href: '/settings',
    icon: <SettingsIcon />,
    mobileOnly: true,
    action: DASHBOARD_VIEW,
  },
  {
    title: 'Sign Out',
    href: '/logout',
    icon: <InputIcon />,
    mobileOnly: true,
    action: DASHBOARD_VIEW,
  },
];

export default pages;
