// Changes to this file need to be mirrored in `server/common/rbac/roles.js`
const roles = {
  SUPER_ADMIN: {
    label: 'Super Admin',
    value: 'SUPER_ADMIN'
  },
  ADMINISTRATOR: {
    label: 'Administrator',
    value: 'ADMINISTRATOR'
  },
  OFFICE: {
    label: 'Office',
    value: 'OFFICE'
  },
  TECHNICIAN: {
    label: 'Technician',
    value: 'TECHNICIAN'
  }
};

export default roles