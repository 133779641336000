import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const SigningUp = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: Signing Up</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4">Welcome to Bear Hands pro! This video is the first in our series of getting started videos about signing up for Bear Hands Pro. <br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/G4Ls-b5Wxt4" title="Getting Started With Bear Hands Pro: Signing up!" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/G4Ls-b5Wxt4" title="Getting Started With Bear Hands Pro: Signing up!" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: Signing up for Bear Hands Pro.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                First you need to make an admin account. This will be your company's main admin account, the login will be the email used for this. All this information can be changed once signed into the app.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='create your admin account' src='/static/tutorials/creatingYourCompany/CreatingAdminAccount.png' width='100%' />

                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                Next we need your company's info. This is going to be your company's public facing contact info. It is all changeable once your signed into the app.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='create your organization' src='/static/tutorials/creatingYourCompany/creatingOrg.png' width='100%' />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography variant='h6'>
                                Let us know how you heard about us! This is skippable.
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='Let us know how your heard about use and enter promo code.' src='/static/tutorials/creatingYourCompany/promoSection.png' width='100%' />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={12} className={classes.endMessage}>
                            <Typography align='center' variant='h3'>
                                Your all set, congratulations on signing up for Bear Hands Pro!
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default SigningUp;