import * as Yup from 'yup';
import {
  CRACKS,
  HEAT_DAMAGE,
  LOOSE_BRICK,
  MASONRY,
  NO,
  SERVICE_REPOINT_BRICKWORK,
  SPALLING,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  INSERT,
  OPEN_FIREBOX,
  FUEL_PELLET,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryFireboxState: '',
    masonryFireboxHasCreosote: '',
    masonryFireboxHasCreosotePhotos: [],
    masonryFireboxVersion: VERSION_PRO.value,
    masonryFireboxLiteIssues: [],
    masonryFireboxLitePhotos: [],
    masonryFireboxHeatDamage: '',
    masonryFireboxPhotos: [],
    masonryFireboxCracksExtend: '',
    masonryFireboxProposal: '',
    masonryFireboxRepointArea: '',
    masonryFireboxBadDrafting: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value &&
    inspection.data.overviewStructureFuelType !== FUEL_GAS.value &&
    inspection.data.overviewStructureFuelType !== FUEL_PELLET.value &&
    (inspection.data.overviewApplianceType === OPEN_FIREBOX.value ||
      inspection.data.overviewApplianceType === INSERT.value)
      ? {
          masonryFireboxState: Yup.string().required(REQUIRED),
          masonryFireboxHeatDamage: Yup.string().when(
            ['masonryFireboxVersion', 'masonryFireboxState'],
            {
              is: (version, state) =>
                version === VERSION_PRO.value && state === UNSATISFACTORY.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryFireboxPhotos: Yup.array().when(
            [
              'masonryFireboxVersion',
              'masonryFireboxState',
              'masonryFireboxHeatDamage',
            ],
            {
              is: (version, state, damage) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                [
                  CRACKS.value,
                  HEAT_DAMAGE.value,
                  LOOSE_BRICK.value,
                  SPALLING.value,
                ].includes(damage),
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
          masonryFireboxCracksExtend: Yup.string().when(
            [
              'masonryFireboxVersion',
              'masonryFireboxState',
              'masonryFireboxHeatDamage',
            ],
            {
              is: (version, state, damage) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                damage === CRACKS.value,
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryFireboxProposal: Yup.string().when(
            [
              'masonryFireboxVersion',
              'masonryFireboxState',
              'masonryFireboxHeatDamage',
              'masonryFireboxCracksExtend',
            ],
            {
              is: (version, state, damage, cracksExtend) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                ([HEAT_DAMAGE.value, SPALLING.value].includes(damage) ||
                  (damage === CRACKS.value && cracksExtend === NO.value)),
              then: Yup.string().required(REQUIRED),
              otherwise: Yup.string().notRequired(),
            }
          ),
          masonryFireboxRepointArea: Yup.number().when(
            [
              'masonryFireboxVersion',
              'masonryFireboxState',
              'masonryFireboxHeatDamage',
              'masonryFireboxCracksExtend',
              'masonryFireboxProposal',
            ],
            {
              is: (version, state, damage, cracksExtend, proposal) =>
                version === VERSION_PRO.value &&
                state === UNSATISFACTORY.value &&
                ([
                  HEAT_DAMAGE.value,
                  SPALLING.value,
                  LOOSE_BRICK.value,
                ].includes(damage) ||
                  (damage === CRACKS.value && cracksExtend === NO.value)) &&
                proposal === SERVICE_REPOINT_BRICKWORK.value,
              then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
              otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
            }
          ),
          masonryFireboxLitePhotos: Yup.array().when(
            ['masonryFireboxState', 'masonryFireboxVersion'],
            {
              is: (state, version) =>
                state === UNSATISFACTORY.value &&
                version === VERSION_LITE.value,
              then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
              otherwise: Yup.array().notRequired(),
            }
          ),
        }
      : {}),
  }),
};

export default state;
