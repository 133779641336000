import * as Yup from 'yup';

import {
  INSERT,
  UNSATISFACTORY,
  VENT_TO_FIRST_TILE_SEAL,
  VERSION_PRO,
  FUEL_GAS,
  FUEL_PELLET
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';
const state = {
  initState: {
    insertVentState: '',
    insertVentLiteIssues: [],
    insertVentLitePhotos: [],
    insertVentVersion: VERSION_PRO.value,
    insertVentAccessDoor: '',
    insertVentTo: '',
    insertVentPipeState: '',
    insertVentPipePhotos: [],
    insertVentSize: { value: '' },
    insertVentLength: { label: '', value: '' },
    insertVentOffsetBoxNeeded: '',
    insertPelletTee: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === INSERT.value &&
      inspection.data.insertVentVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        insertVentState: Yup.string().required(REQUIRED),
        insertVentAccessDoor: Yup.string().when('insertVentState', {
          is: UNSATISFACTORY.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        insertVentTo: Yup.string().when('insertVentState', {
          is: UNSATISFACTORY.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        insertVentPipeState: Yup.string().when('insertVentState', {
          is: UNSATISFACTORY.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        insertVentPipePhotos: Yup.array().when(
          ['insertVentState', 'insertVentPipeState'],
          {
            is: (ventState, pipeState) =>
              ventState === UNSATISFACTORY.value &&
              pipeState === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        insertVentSize: Yup.object().when(
          ['insertVentState', 'insertVentPipeState', 'insertVentTo'],
          {
            is: (ventState, pipeState, ventedTo) =>
              ventState === UNSATISFACTORY.value &&
              (pipeState === UNSATISFACTORY.value ||
                ventedTo === VENT_TO_FIRST_TILE_SEAL.value),
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        insertVentLength: Yup.object().when(
          ['insertVentState', 'insertVentPipeState', 'insertVentTo'],
          {
            is: (ventState, pipeState, ventedTo) =>
              ventState === UNSATISFACTORY.value &&
              (pipeState === UNSATISFACTORY.value ||
                ventedTo === VENT_TO_FIRST_TILE_SEAL.value),
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        insertVentOffsetBoxNeeded: Yup.string().when(
          ['insertVentState', 'insertVentPipeState', 'insertVentTo'],
          {
            is: (ventState, pipeState, ventedTo) =>
              ventState === UNSATISFACTORY.value &&
              (pipeState === UNSATISFACTORY.value ||
                ventedTo === VENT_TO_FIRST_TILE_SEAL.value),
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        insertPelletTee: Yup.string().when(
          ['insertVentState', 'overviewStructureFuelType'],
          {
            is: (ventState, fuelType) =>
              ventState === UNSATISFACTORY.value && fuelType === FUEL_PELLET.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        )
      }
      : {}),
  }),
};

export default state