export const PAINTABLE = {
  label: 'Paintable',
  value: 'PAINTABLE'
};
export const GALVANIZED = {
  label: 'Galvanized',
  value: 'GALVANIZED'
};
export const STAINLESS_STEEL = {
  label: 'Stainless Steel',
  value: 'STAINLESS_STEEL'
};
export const ALUMINUM = {
  label: 'Aulminum',
  value: 'ALUMINUM'
};
