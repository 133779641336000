// import * as Yup from 'yup';
// import {
//     NO,
//     Yes
// } from 'components/app/InspectionForm/constants';

// import {
//     REQUIRED,
//     VALID_ONE_PHOTO,
// } from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        chaseCovMeasPD1: '',
        chaseCovMeasPD2: '',
        chaseCovMeasPD3: '',
        chaseCovMeasPD4: '',
        chaseCovMeasDD5: '',
        chaseCovMeasDD6: '',
        chaseCovMeasheightOfSkirt: '',
        chaseCovMeasDripEdge: '',
        chaseCovMeasCrossbreak: '',
        chaseCovMeasHole: '',
        chaseCovMeasDiameterHoleA: '',
        chaseCovMeasCenterSingleHole: '',
        chaseCovMeasHoleA1: '',
        chaseCovMeasHoleA2: '',
        chaseCovMeasHoleA3: '',
        chaseCovMeasHoleA4: '',
        chaseCovMeasCollar: '',
        chaseCovMeasHoleACollarA: '',
        chaseCovMeasCollarA: '',
        chaseCovMeasHoleACollar: '',
        chaseCovMeasHoleB1: '',
        chaseCovMeasHoleB2: '',
        chaseCovMeasHoleB3: '',
        chaseCovMeasHoleB4: '',
        chaseCovMeasCollarB: '',
        chaseCovMeasHoleBCollar: '',
    },
    validation: () => ({

    })
};
export default state