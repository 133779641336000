export const SERVICE_CHIMNEY_SWEEP = {
  label: 'Perform Chimney Sweep',
  value: 'SERVICE_CHIMNEY_SWEEP'
};
export const SERVICE_FLUE_INSTALL = {
  label: 'Install Flue Liner',
  value: 'SERVICE_FLUE_INSTALL'
};
export const SERVICE_MECHANICAL_DEGLAZING = {
  label: 'Perform Mechanical Deglazing',
  value: 'SERVICE_MECHANICAL_DEGLAZING'
};
export const SERVICE_ACCESS_DOOR_INSTALL = {
  label: 'Install Rear Access Door',
  value: 'SERVICE_ACCESS_DOOR_INSTALL'
};
export const SERVICE_FLUE_TILE_BREAK = {
  label: 'Break Out Existing Flue Tiles',
  value: 'SERVICE_FLUE_TILE_BREAK'
};
export const SERVICE_HEATSHIELD_INSTALL = {
  label: 'Install Heat Shield',
  value: 'SERVICE_HEATSHIELD_INSTALL'
};

