import * as Yup from 'yup';
import { REQUIRED } from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    miscState: '',
    miscPhotos: [],
    miscNotes: [{ whatsWrong: '', additional: '', repair: '', cost: '' }]
  },
  validation: () => ({
    miscState: Yup.string().required(REQUIRED)
  })
};

export default state