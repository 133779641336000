import prefabCapState from './PrefabCapForm/state';
import prefabChaseState from './PrefabChaseForm/state';
import prefabChaseCoverState from './PrefabChaseCoverForm/state';
import prefabDamperState from './PrefabDamperForm/state';
import prefabFireboxState from './PrefabFireboxForm/state';
import prefabFlashingState from './PrefabFlashingForm/state';
import prefabHearthState from './PrefabHearthForm/state';
import prefabHeightState from './PrefabHeightForm/state';
import prefabOverviewState from './PrefabOverviewForm/state';
import prefabSparkScreenState from './PrefabSparkScreenForm/state';
import prefabVentState from './PrefabVentForm/state';
import prefabAtticState from './PrefabAtticForm/state';
import prefabCrawlSpaceState from './PrefabCrawlSpaceForm/state';
import PrefabVentCheckMMState from './PrefabVentCheckMM/state';

const state = {
  initState: {
    ...prefabCapState.initState,
    ...prefabChaseState.initState,
    ...prefabChaseCoverState.initState,
    ...prefabDamperState.initState,
    ...prefabFireboxState.initState,
    ...prefabFlashingState.initState,
    ...prefabHearthState.initState,
    ...prefabHeightState.initState,
    ...prefabOverviewState.initState,
    ...prefabSparkScreenState.initState,
    ...prefabVentState.initState,
    ...prefabAtticState.initState,
    ...prefabCrawlSpaceState.initState,
    ...PrefabVentCheckMMState.initState,
  },
  validation: (inspection) => ({
    ...prefabCapState.validation(inspection),
    ...prefabChaseState.validation(inspection),
    // ...prefabChaseCoverState.validation(inspection),
    // ...prefabDamperState.validation(inspection),
    // ...prefabFireboxState.validation(inspection),
    // ...prefabFlashingState.validation(inspection),
    // ...prefabHearthState.validation(inspection),
    // ...prefabHeightState.validation(inspection),
    // ...prefabOverviewState.validation(inspection),
    // ...prefabSparkScreenState.validation(inspection),
    // ...prefabVentState.validation(inspection),
    // ...prefabAtticState.validation(inspection),
    // ...prefabCrawlSpaceState.validation(inspection),
    // ...PrefabVentCheckMMState.validation(inspection),
  })
};

export default state
