import * as Yup from 'yup';
import {
  DAMAGED,
  MASONRY,
  MISSING,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryAshDumpState: '',
    masonryAshDumpVersion: VERSION_PRO.value,
    masonryAshDumpLiteIssues: [],
    masonryAshDumpLitePhotos: [],
    masonryAshDumpStatus: '',
    masonryAshDumpPhotos: [],
    masonryAshDumpLengthDimension: '',
    masonryAshDumpWidthDimension: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && inspection.data.fledge === false
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryAshDumpState: Yup.string().required(REQUIRED),
        masonryAshDumpStatus: Yup.string().when(
          ['masonryAshDumpVersion', 'masonryAshDumpState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryAshDumpPhotos: Yup.array().when(
          [
            'masonryAshDumpVersion',
            'masonryAshDumpState',
            'masonryAshDumpStatus',
          ],
          {
            is: (version, state, ashDumpState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [DAMAGED.value, MISSING.value].includes(ashDumpState),
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryAshDumpLengthDimension: Yup.number().when(
          [
            'masonryAshDumpVersion',
            'masonryAshDumpState',
            'masonryAshDumpStatus',
          ],
          {
            is: (version, state, ashDumpState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [DAMAGED.value, MISSING.value].includes(ashDumpState),
            then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryAshDumpWidthDimension: Yup.number().when(
          [
            'masonryAshDumpVersion',
            'masonryAshDumpState',
            'masonryAshDumpStatus',
          ],
          {
            is: (version, state, ashDumpState) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              [DAMAGED.value, MISSING.value].includes(ashDumpState),
            then: Yup.number().typeError(VALID_NUMBER).required(REQUIRED),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        masonryAshDumpLitePhotos: Yup.array().when(
          [
            'masonryAshDumpState',
            'masonryAshDumpVersion',
          ],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        )
      }
      : {}),
  }),
};

export default state