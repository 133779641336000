import * as Yup from 'yup';

const state = {
    initState: {
        jobNumber: '',
        serviceMonsterOrdId: '',
    },
    validation: {
        jobNumber: Yup.string().notRequired(),
    }
};

export default state