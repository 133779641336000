import * as Yup from 'yup';
import {
    FREESTANDING, UNSATISFACTORY, VERSION_LITE
} from 'components/app/InspectionForm/constants';
import { REQUIRED, VALID_ONE_PHOTO } from 'components/app/InspectionForm/stateConstants';


const state = {
    initState: {
        freestandingFlashingState: "",
        freestandingFlashingVersion: '',
        freestandingFlashingLiteIssues: [],
        freestandingFlashingPhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value ? {
            freestandingFlashingState: Yup.string().required(REQUIRED),
            freestandingFlashingPhotos: Yup.array().when(
                ['freestandingFlashingVersion', 'freestandingFlashingState'],
                {
                    is: (version, state) =>
                        version === VERSION_LITE.value && state === UNSATISFACTORY.value,
                    then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                    otherwise: Yup.array().notRequired(),
                }
            )
        } : {}),
    }),
};

export default state