import * as Yup from "yup";
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT,
  VERSION_PRO,
} from "components/app/InspectionForm/constants";
import { REQUIRED, VALID_ONE_PHOTO } from "components/app/InspectionForm/stateConstants";

const state = {
  initState: {
    masonryCrawlSpaceState: "",
    masonryCrawlSpaceVersion: VERSION_PRO.value,
    masonryCrawlSpaceLiteIssues: [],
    masonryCrawlSpacePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value &&
    inspection.data.masonryCrawlSpaceVersion === VERSION_LITE.value &&
    inspection.data.fledge === false &&
    (inspection.data.overviewApplianceType === OPEN_FIREBOX.value ||
      inspection.data.overviewApplianceType === INSERT.value)
      ? {
          masonryCrawlSpaceState: Yup.string().required(REQUIRED),
          masonryCrawlSpacePhotos: Yup.array().when("masonryCrawlSpaceState", {
            is: UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }),
        }
      : {}),
  }),
};

export default state;
