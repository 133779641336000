import gasFireboxState from './GasFireboxForm/state';
import gasInsulationState from './GasInsulationForm/state';
import gasLogSetState from './GasLogSet/state';
import gasOverviewState from './GasOverviewForm/state';
import gasPilotState from './GasPilotForm/state';
import gasValveState from './GasValveForm/state';

const state = {
  initState: {
    ...gasFireboxState.initState,
    ...gasInsulationState.initState,
    ...gasLogSetState.initState,
    ...gasOverviewState.initState,
    ...gasPilotState.initState,
    ...gasValveState.initState,
  },
  validation: (inspection) => ({
    ...gasFireboxState.validation(inspection),
    ...gasInsulationState.validation(inspection),
    ...gasLogSetState.validation(inspection),
    ...gasOverviewState.validation(inspection),
    ...gasPilotState.validation(inspection),
    ...gasValveState.validation(inspection),
  }),
};

export default state;
