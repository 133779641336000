import utilityChimneyOverviewState from './UtilityChimneyOverview/state';
import utilityChimneyApplianceState from './UtilityChimneyAppliance/state';
import UtilityChimneyChimneyLinerState from './UtilityChimneyLiner/state';
import UtilityChimneyOutsideTerminationState from './UtililtyChimneyOutsideTermination/state';
import UtilityChimneyCapState from './UtilityChimneyCap/state';
import UtilityChimneyFlashingState from './UtilityChimneyFlashing/state';
import UtilityChimneyHeightState from './UtilityChimneyHeight/state';
import UtilityChimneyStructureState from './UtilityChimneyStructure/state';

const state = {
    initState: {
        ...utilityChimneyOverviewState.initState,
        ...utilityChimneyApplianceState.initState,
        ...UtilityChimneyChimneyLinerState.initState,
        ...UtilityChimneyOutsideTerminationState.initState,
        ...UtilityChimneyCapState.initState,
        ...UtilityChimneyFlashingState.initState,
        ...UtilityChimneyHeightState.initState,
        ...UtilityChimneyStructureState.initState,
    },
    validation: (inspection) => (
        {
            ...utilityChimneyOverviewState.validation(inspection),
            ...utilityChimneyApplianceState.validation(inspection),
            ...UtilityChimneyChimneyLinerState.validation(inspection),
            ...UtilityChimneyOutsideTerminationState.validation(inspection),
            ...UtilityChimneyCapState.validation(inspection),
            ...UtilityChimneyFlashingState.validation(inspection),
            ...UtilityChimneyHeightState.validation(inspection),
            ...UtilityChimneyStructureState.validation(inspection),
        }
    ),
};

export default state