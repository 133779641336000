import * as Yup from 'yup';
import {
  INSERT,
  UNSATISFACTORY,
  YES,
  VERSION_PRO,
  FUEL_GAS
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    insertInsulationState: '',
    insertInsulationPhotos: [],
    insertInsulationLiteIssues: [],
    insertInsulationLitePhotos: [],
    insertInsulationVersion: VERSION_PRO.value,
    insertInsulationBlanketWorn: '',
    insertInsulationSurround: '',
    insertInsulationDoorWorn: '',
    insertInsulationDoorCount: '',
    insertInsulationGasketSizeDoor1: { value: '' },
    insertInsulationGasketSizeDoor2: { value: '' },
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === INSERT.value &&
      inspection.data.insertBoxState === UNSATISFACTORY.value &&
      inspection.data.insertInsulationVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        insertInsulationState: Yup.string().required(REQUIRED),
        insertInsulationPhotos: Yup.array().when('insertInsulationState', {
          is: UNSATISFACTORY.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }),
        insertInsulationBlanketWorn: Yup.string().when(
          'insertInsulationState',
          {
            is: UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        insertInsulationSurround: Yup.string().when('insertInsulationState', {
          is: UNSATISFACTORY.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        insertInsulationDoorWorn: Yup.string().when('insertInsulationState', {
          is: UNSATISFACTORY.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        insertInsulationDoorCount: Yup.string().when(
          ['insertInsulationState', 'insertInsulationDoorWorn'],
          {
            is: (insulationState, doorWorn) =>
              insulationState === UNSATISFACTORY.value &&
              doorWorn === YES.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        insertInsulationGasketSizeDoor1: Yup.object().when(
          ['insertInsulationState', 'insertInsulationDoorWorn'],
          {
            is: (insulationState, doorWorn) =>
              insulationState === UNSATISFACTORY.value &&
              doorWorn === YES.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        insertInsulationGasketSizeDoor2: Yup.object().when(
          [
            'insertInsulationState',
            'insertInsulationDoorWorn',
            'insertInsulationDoorCount',
          ],
          {
            is: (insulationState, doorWorn, doorCount) =>
              insulationState === UNSATISFACTORY.value &&
              doorWorn === YES.value &&
              doorCount === 2,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state