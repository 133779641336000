import * as Yup from 'yup';
import {
    MASONRY,
    VERSION_LITE,
    VERSION_PRO,
    OPEN_FIREBOX,
    INSERT,
} from 'components/app/InspectionForm/constants';
import {
    REQUIRED,
    VALID_ONE_PHOTO,

} from 'components/app/InspectionForm/stateConstants';



const state =  {
    initState: {
        masonryWaterProofPhoto: [],
        masonryWaterProofCondition: '',
        masonryWaterProofVersion: VERSION_LITE.value,
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewChimneyType === MASONRY.value
            && inspection.data.overviewApplianceType === OPEN_FIREBOX.value
            && inspection.data.overviewApplianceType === INSERT.value
            && inspection.data.masonryWaterProofVersion === VERSION_PRO.value
            ? {
                masonryWaterProofPhoto: Yup.array().when(
                    'masonryWaterProofVersion',
                    {
                        is: VERSION_PRO.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                ),
                masonryWaterProofCondition: Yup.string().when(
                    ['masonryWaterProofVersion'],
                    {
                        is: (version) => version === VERSION_PRO.value,
                        then: Yup.string().required(REQUIRED),
                        otherwise: Yup.string().notRequired(),
                    }
                ),

            }
            : {}),
    })
}
export default state