import * as Yup from 'yup';
import {
  INSERT,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    insertRainCollarState: '',
    insertRainCollarLiteIssues: [],
    insertRainCollarLitePhotos: [],
    insertRainCollarVersion: VERSION_PRO.value,
    insertRainCollarProposal: '',
    insertRainCollarPhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === INSERT.value &&
      inspection.data.insertVentState === UNSATISFACTORY.value &&
      inspection.data.insertRainCollarVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        insertRainCollarState: Yup.string().required(REQUIRED),
        insertRainCollarProposal: Yup.string().when(
          'insertRainCollarState',

          {
            is: UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        insertRainCollarPhotos: Yup.array().when('insertRainCollarState', {
          is: UNSATISFACTORY.value,
          then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
          otherwise: Yup.array().notRequired(),
        }),
      }
      : {}),
  }),
};

export default state