import * as Yup from 'yup';
import { VERSION_LITE, UTILITY_CHIMNEY, DIRECT_VENT, UNSATISFACTORY } from 'components/app/InspectionForm/constants';
import { REQUIRED, VALID_ONE_PHOTO } from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        utilChimCapState: '',
        utilChimCapVersion: VERSION_LITE.value,
        utilChimCapLiteIssues: [],
        utilChimCapLitePhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === UTILITY_CHIMNEY.value
            && inspection.data.overviewVentType !== DIRECT_VENT.value
            ? {
                utilChimCapState: Yup.string().required(REQUIRED),
                utilChimCapLiteIssues: Yup.array().when(
                    ['utilChimCapState'],
                    {
                        is: (state) =>
                            state === UNSATISFACTORY.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                ),
                utilChimCapLitePhotos: Yup.array().when(
                    ['utilChimCapState'],
                    {
                        is: (state) =>
                            state === UNSATISFACTORY.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                )
            }
            : {}),
    }),
};
export default state