import * as Yup from 'yup';
import { VERSION_LITE, UTILITY_CHIMNEY, DIRECT_VENT, UNSATISFACTORY } from 'components/app/InspectionForm/constants';
import { REQUIRED, VALID_ONE_PHOTO } from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        utilChimHeightState: '',
        utilChimHeightVersion: VERSION_LITE.value,
        utilChimHeightLiteIssues: [],
        utilChimHeightLitePhotos: [],
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === UTILITY_CHIMNEY.value
            && inspection.data.overviewVentType !== DIRECT_VENT.value
            ? {
                utilChimHeightState: Yup.string().required(REQUIRED),
                utilChimHeightLiteIssues: Yup.array().when(
                    ['utilChimHeightState'],
                    {
                        is: (state) =>
                            state === UNSATISFACTORY.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                ),
                utilChimHeightLitePhotos: Yup.array().when(
                    ['utilChimHeightState'],
                    {
                        is: (state) =>
                            state === UNSATISFACTORY.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                ),
            }
            : {}),
    }),
};

export default state