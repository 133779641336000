export const PRODUCT_GRATE = {
  label: 'Grate',
  value: 'PRODUCT_GRATE'
};
export const PRODUCT_GRATE_18_16_12 = {
  label: '18" Front x 16" Back x 12" Depth',
  value: 'PRODUCT_GRATE_18_16_12'
};
export const PRODUCT_GRATE_24_20_12 = {
  label: '24" Front x 20" Back x 12" Depth',
  value: 'PRODUCT_GRATE_24_20_12'
};
export const PRODUCT_GRATE_24_20_15 = {
  label: '24" Front x 20" Back x 15" Depth',
  value: 'PRODUCT_GRATE_24_20_15'
};
export const PRODUCT_GRATE_27_23_15 = {
  label: '27" Front x 23" Back x 15" Depth',
  value: 'PRODUCT_GRATE_27_23_15'
};
export const PRODUCT_GRATE_30_26_15 = {
  label: '30" Front x 26" Back x 15" Depth',
  value: 'PRODUCT_GRATE_30_26_15'
};
