import { Divider, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import './Footer.css';
import styles from './styles';

const useStyles = makeStyles(styles);

const Footer = ({ className }) => {
  const classes = useStyles();
  const [build] = React.useState(process.env.REACT_APP_BUILD);

  return (
    <div className={clsx(classes.root, className)}>
      <Divider />
      <div className="footer">
        <Typography variant="body1">
          Copyright © 2015 - {new Date().getFullYear()} Bear Hands Pro, Inc.
        </Typography>
        <Typography variant="body2">Build: {build}</Typography>
      </div>
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Footer.propTypes = {
    className: PropTypes.string,
  };
}

export default Footer;
