import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const Clients = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Client Table</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Your Client Table</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4">We go over the new client table, where you can view your clients and delete them from Bear Hands Pro<br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>

                                <iframe width="560" height="315" src="https://www.youtube.com/embed/wfDRXQPC4Rw" title="Using the Client Table" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/wfDRXQPC4Rw" title="Using the client table" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Your Client Table
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                On the client page you can View Your Clients
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add clients by filling out form on left.' src='/static/guides/clientTable/clientTable.png' width='100%' />

                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                the controls are on the bottom of the table for changing how many clients per page.
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add CLients by csv' src='/static/guides/clientTable/clientTableControls.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                You can search by client name in the box on the top. It is not case sensitive and will try and find best match.
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add CLients by csv' src='/static/guides/clientTable/clientTableSearch.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                Finally you can delete clients from Bear Hands Pro by clicking the red trash can to the right of the table. Warning we will not be able to recover any client information that is deleted.
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepPicture} xs={12}>
                            <img alt='Add CLients by csv' src='/static/guides/clientTable/clientTableDelete.png' width='100%' />
                        </Grid>

                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default Clients;