import * as Yup from 'yup';
import {
    DRYER_VENT, UNSATISFACTORY, VERSION_LITE,
} from 'components/app/InspectionForm/constants'

import {
    REQUIRED,
    VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        dryerventDuctType: '',
        dryerventDuctDiameter: '',
        dryerventDuctState: '',
        dryerventDuctVersion: VERSION_LITE.value,
        dryerventDuctLiteIssues: [],
        dryerventDuctLitePhotos: [],
        dryerventDuctLength: '',
        dryerventDuctElbows: '',
    },
    validation: (inspection) => (
        inspection.data.overviewInspectionLevel === DRYER_VENT.value ?
            {
                dryerventDuctState: Yup.string().required(REQUIRED),
                dryerventDuctLitePhotos: Yup.array().when(
                    ['dryerventDuctState'], {
                    is: (state) => state === UNSATISFACTORY.value,
                    then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                    otherwise: Yup.array().notRequired(),
                }
                )
            } : {}
    )
};

export default state