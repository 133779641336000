import React from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';

import useDebounce from 'utils/useDebounce';

const Promo = ({ showPromo, handleClose, classes, promo, handleChangePromo, handleBack, handleSubmitForm }) => {
    const promoCodes = ['bc30', 'sweeping30', 'surefire30', 'free7', 'free30', 'spark30'];
    const [thisPromo, setThisPromo] = React.useState(promo.promoCode);

    const debouncedPromo = useDebounce(thisPromo, 500);

    const [promoFound, setPromoFound] = React.useState(true);

    const handleChange = (e) => {
        e.preventDefault();
        const { value } = e.currentTarget;
        setThisPromo(value);
        handleChangePromo(e)
    };

    const handleCheckPromo = (promo) => {
        return promoCodes.includes(promo?.toLowerCase());
    };

    React.useEffect(() => {
        if (debouncedPromo !== '') {
            setPromoFound(handleCheckPromo(debouncedPromo));
        } else {
            setPromoFound(true);
        }
        // eslint-disable-next-line
    }, [debouncedPromo])

    return (
        <Dialog open={showPromo} onClose={handleClose} fullWidth aria-labelledby='sign-up-promo-form-title'>
            <DialogTitle className={classes.dialogTitleContainer} id='sign-up-promo-form-title'><Typography className={classes.dialogTitle} variant='h2' component='p'>Promos 4/4</Typography></DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    Please take a second to let us know how you heard about us.
                </DialogContentText>
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='reference'
                    name='reference'
                    value={promo.reference}
                    className={classes.formFeild}
                    onChange={handleChangePromo}
                    label='Tell us how you heard about us'
                    type='text'
                    fullWidth
                />
                <TextField
                    variant='outlined'
                    margin='dense'
                    id='promoCode'
                    name='promoCode'
                    value={thisPromo}
                    className={classes.formFeild}
                    onChange={handleChange}
                    label='Promo Code'
                    type='text'
                    fullWidth
                />
                {!promoFound && (
                    <Typography className={classes.warning} variant='h6'>
                        Promo Code Not Found
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' id='toCompany' onClick={handleBack} color='primary'>
                    back
                </Button>
                <Button variant='outlined' onClick={handleSubmitForm} disabled={!promoFound} color='primary'>
                    Sign Up
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Promo