import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Container,
    Paper,
} from '@material-ui/core';

import styles from './styles';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import { Link as RouterLink } from 'react-router-dom';
import TicketComponent from './ticketComponent';

const useStyles = makeStyles(styles);

const Support = ({ ...rest }) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12} className={classes.heroWrapper}>
                <Grid container justifyContent="center" alignItems="flex-start" className={classes.hero}>
                    <Container>
                        <Grid container alignItems='flex-start'>
                            <Grid item>
                                <Typography className={classes.heroTitle} variant="h1">We're Here To Help</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            <Container className={classes.secondContainer}>
                <Paper>
                    <Grid container alignItems='center' justifyContent='center'>
                        <Grid item className={classes.ticketHeader} lg={12}>
                            <Typography align='center' variant="h2">Not getting the answers you need?</Typography>
                        </Grid>
                        <Grid item lg={12}>
                            <Typography align='center' className={classes.ticketSubHeader} variant="h4">Submit a ticket and we'll get back to you as soon as we can. Most issues are resolved in under 24 hours!</Typography>
                        </Grid>
                    </Grid>
                    <TicketComponent />
                </Paper>
            </Container>
        </Grid>
    )
}

export default Support;