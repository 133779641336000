import * as Yup from 'yup';
import {
  PREFAB,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  YES,

} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';


const state = {
  initState: {
    prefabChaseState: '',
    prefabChaseVersion: VERSION_PRO.value,
    prefabChaseLiteIssues: [],
    prefabChaseLitePhotos: [],
    prefabChasePhotos: [],
    prefabChaseMaterial: '',
    prefabChaseWoodTrim: '',
    prefabChaseRebuild: '',
    prefabChasePanelHeight: '',
    prefabChasePanelWidth: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === PREFAB.value &&
      inspection.data.prefabChaseVersion === VERSION_PRO.value 
      ? {
        prefabChaseState: Yup.string().required(REQUIRED),
        prefabChasePhotos: Yup.array().when(
          ['prefabChaseVersion', 'prefabChaseState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        prefabChaseMaterial: Yup.string().when(
          ['prefabChaseVersion', 'prefabChaseState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabChaseRebuild: Yup.string().when(
          ['prefabChaseState'],
          {
            is: (state) =>
              state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        prefabChasePanelHeight: Yup.object()
        .when(
          ['prefabChaseRebuild'],
          {
            is: (rebuild) =>
              rebuild === YES.value,
            then: Yup.object().shape({
              value: Yup.string().required(REQUIRED),
            }).required(REQUIRED),
            otherwise: Yup.object().notRequired,
          }
        ),
        prefabChasePanelWidth: Yup.object().when(
          ['prefabChaseRebuild'],
          {
            is: (rebuild) =>
              rebuild === YES.value,
            then: Yup.object().shape({
              value: Yup.string().required(REQUIRED),
            }).required(REQUIRED),
            otherwise: Yup.object().notRequired,
          }
        )
      }
      : inspection.data.overviewChimneyType === PREFAB.value &&
        inspection.data.prefabChaseVersion === VERSION_LITE.value ? {
        prefabChaseState: Yup.string().required(REQUIRED),
        prefabChaseLitePhotos: Yup.array().when(
          ['prefabChaseState', 'prefabChaseVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        )
      } : {}),
  }),
};

export default state