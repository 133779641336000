import axios from 'axios';

export const getOrg = (id) => async () => {
  return axios.post('/api/admin/org', { orgId: id }).then((res) => {
    if (!res.data.success) {
      throw new Error();
    }

    return res.data;
  });
};
