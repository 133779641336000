import levelTwoAtticCrawlSpaceState from './levelTwoAtticCrawlSpace/state';

const state = {
    initState: {
        ...levelTwoAtticCrawlSpaceState.initState
    },
    validation: (inspection) => ({
        ...levelTwoAtticCrawlSpaceState.validation(inspection),
    }),
};

export default state