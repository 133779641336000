import insertBoxState from './InsertBoxForm/state';
import insertCatalyticsState from './InsertCatalyticForm/state';
import insertEpaState from './InsertEpaStatusForm/state';
import insertInsulationState from './InsertInsulationForm/state';
import insertRainCollarState from './InsertRainCollarForm/state';
import insertTopPlateState from './InsertTopPlateForm/state';
import insertVentingState from './InsertVentingForm/state';

const state = {
  initState: {
    ...insertBoxState.initState,
    ...insertCatalyticsState.initState,
    ...insertEpaState.initState,
    ...insertInsulationState.initState,
    ...insertRainCollarState.initState,
    ...insertTopPlateState.initState,
    ...insertVentingState.initState
  },
  validation: (inspection) => ({
    ...insertBoxState.validation(inspection),
    ...insertCatalyticsState.validation(inspection),
    ...insertEpaState.validation(inspection),
    ...insertInsulationState.validation(inspection),
    ...insertRainCollarState.validation(inspection),
    ...insertTopPlateState.validation(inspection),
    ...insertVentingState.validation(inspection)
  })
};

export default state