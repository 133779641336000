export const SINGLE_HOLE = {
  label: 'Single Hole',
  value: 'SINGLE_HOLE'
};
export const TWO_HOLE = {
  label: 'Two Hole',
  value: 'TWO_HOLE'
};
export const GALVANIZED_BLACK = {
  label: 'Black',
  value: 'BLACK'
};
export const GALVANIZED_BROWN = {
  label: 'Brown',
  value: 'Brown'
};
export const GALVANIZED_GREY = {
  label: 'Grey',
  value: 'GREY'
};
