import * as Yup from 'yup';
import gasState from './GasForm/state';
import insertState from './InsertForm/state';
import masonryState from './MasonryForm/state';
import miscState from './MiscForm/state';
import prefabState from './PrefabForm/state';
import freestandingState from './FreestandingForm/state';
import levelTwoState from './levelTwo/state';
import utilityChimneyState from './UtilityChimney/state';
import overallConditionState from './OverallCondition/state';
import dryerVentState from './DryerVentForm/state';
import NoteFormState from './noteForm/state';
import AdditionalPhotos from './AdditionalPhotos/state';
import ChaseCoverMeasurementFormState from '../components/ChaseCoverMeasurementForm/state';
import insuranceClaimState from './InsuranceClaimForm/state';

const state = {
  initState: {
    ...gasState.initState,
    ...insertState.initState,
    ...masonryState.initState,
    ...miscState.initState,
    ...prefabState.initState,
    ...freestandingState.initState,
    ...levelTwoState.initState,
    ...utilityChimneyState.initState,
    ...overallConditionState.initState,
    ...dryerVentState.initState,
    ...NoteFormState.initState,
    ...ChaseCoverMeasurementFormState.initState,
    ...AdditionalPhotos.initState,
    ...insuranceClaimState.initState,
  },
  validation: (inspection) => {
    return Yup.object().shape({
      ...gasState.validation(inspection),
      ...insertState.validation(inspection),
      ...masonryState.validation(inspection),
      ...miscState.validation(inspection),
      ...prefabState.validation(inspection),
      ...freestandingState.validation(inspection),
      ...levelTwoState.validation(inspection),
      ...utilityChimneyState.validation(inspection),
      ...overallConditionState.validation(inspection),
      ...dryerVentState.validation(inspection),
      ...NoteFormState.validation(inspection),
      ...ChaseCoverMeasurementFormState.validation(inspection),
      ...AdditionalPhotos.validation(inspection),
      ...insuranceClaimState.validation(inspection),
    });
  },
};

export default state;
