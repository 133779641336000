import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_LITE,
  VERSION_PRO,
  OPEN_FIREBOX,
  INSERT,
  NA,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryCrownState: '',
    masonryCrownVersion: VERSION_PRO.value,
    masonryCrownLiteIssues: [],
    masonryCrownLitePhotos: [],
    masonryCrownUpperPhotos: [],
    masonryCrownRebuild: '',
    masonryCrownOverviewPhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryCrownState: Yup.string().required(REQUIRED),
        masonryCrownRebuild: Yup.string().when(
          ['masonryCrownState', 'masonryCrownVersion'], {
          is: (state, version) => state === UNSATISFACTORY.value && version === VERSION_PRO.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired()
        }
        ),
        masonryCrownUpperPhotos: Yup.array().when(
          ['masonryCrownVersion', 'masonryCrownState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryCrownOverviewPhotos: Yup.array().when(
          ['masonryCrownState'],
          {
            is: (state) => state !== NA.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryCrownLitePhotos: Yup.array().when(
          ['masonryCrownState', 'masonryCrownVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state