import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid';

import { APP_INFO } from './constants';

export const appInfo = ({
  id = uuidv4(),
  title = '',
  route = '#',
  when = DateTime.now()
} = {}) => {
  if (title) {
    return {
      type: APP_INFO,
      notification: {
        id,
        title,
        route,
        type: 'info',
        when,
        read: false
      }
    };
  }
};
