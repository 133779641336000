import { combineReducers } from 'redux';
import auth from './authenticationReducer';
import app from './inspectionsReducer';
import notifications from './notificationsReducer';
import dasharama from './dasharamaReducer';

export default combineReducers({
  auth,
  app,
  notifications,
  dasharama,
});
