import React from 'react';

import {
    Grid,
    makeStyles,
} from '@material-ui/core';


import styles from './styles';


const useStyles = makeStyles(styles);

const Guide = ({ page: Component, ...rest }) => {
    const classes = useStyles();


    return (
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item lg={11} sm={12} className={classes.content}>
                <Component classes={classes} />
            </Grid>
        </Grid>
    )
};

export default Guide;