import React from 'react';

import {
    Grid,
    Typography,
    makeStyles,
    Hidden,
    Breadcrumbs,
    Link,
    Container,
    Paper,
    Divider,
} from '@material-ui/core';
import styles from './styles'



const useStyles = makeStyles(styles);

const OrgSettings = () => {
    const classes = useStyles();


    return (
        <div className={classes.mainContainer} >
            <Container>
                <Grid container justifyContent="flex-start" alignItems='center'>
                    <Grid item className={classes.breadCrumbs}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link color='inherit' href='/'>
                                Bear Hands Pro
                            </Link>
                            <Link color='inherit' href='/tutorials'>
                                tutorials
                            </Link>
                            <Typography color='textPrimary'>Getting Started: org Settings</Typography>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid item container justifyContent="center" alignItems='center' xs={12}>
                        <Grid item xs={12} className={classes.titleBar}><Typography align='center' style={{ color: 'white' }} variant='h1'>Getting Started With Bear Hands Pro</Typography></Grid>
                        <Grid item lg={10}>
                            <Typography align='center' className={classes.intro} variant="h4">This video is part of our series of getting started videos about your org settings <br />Watch our video tutorial or follow our step by step guide below </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.pictureHolder} justifyContent="center" alignItems='center' xs={12}>
                        <Hidden mdDown>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/7_bzOl6_JjA" title="Getting started: Org Settings" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            <Grid item container justifyContent="center" lg={7}>
                                <iframe width="90%" height="250" src="https://www.youtube.com/embed/7_bzOl6_JjA" title="Getting started: Org Settings" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} className={classes.stepHeading}>
                            <Typography variant='h2'>
                                Getting Started: Your Organization Settings.
                            </Typography>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={10} className={classes.stepText}>
                            <Typography variant='h6'>
                                Company settings are global toggles that effect every inspection done on your organization account.
                            </Typography>
                        </Grid>
                        <Grid item lg={10} className={classes.stepPicture} xs={12}>
                            <img alt='company settings tile' src='/static/tutorials/orgSettings/company-settings.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography align='right' variant='h6'>
                                Would You like your reports to show price?
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepText} xs={12}>
                            <Typography variant='body1'>
                                This setting will turn off all prices in Bear Hands Pro. Your techs won't be able to add prices, and the proposal will never show up.
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography align='right' variant='h6'>
                                Would you like your techs to be able to edit prices?
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepText} xs={12}>
                            <Typography variant='body1'>
                                This setting allows your tech to edit the prices. If your techs are not alowed to change your companies prices in the field make sure this is turned off.
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={4} className={classes.stepText}>
                            <Typography align='right' variant='h6'>
                                Would you like your techs to be able to edit the responses wording?
                            </Typography>
                        </Grid>
                        <Grid item lg={5} className={classes.stepText} xs={12}>
                            <Typography variant='body1'>
                                If this is turned off your techs would no longer be able to change the wording on default responses. If you have set wording you never want changed, make sure this is turned off.
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                        <Grid item lg={5} className={classes.stepText}>
                            <Typography variant='h6'>
                                Make sure to change set your company logo by clicking the button on the right side.  It will get scaled down to fit on your reports but try a few different sizes to see what looks best!
                            </Typography>
                        </Grid>
                        <Grid item lg={4} className={classes.stepPicture} xs={12}>
                            <img alt='Change your company logo' src='/static/tutorials/orgSettings/changeLogo.png' width='100%' />
                        </Grid>
                        <Grid xs={12}>
                            <Divider className={classes.stepHeadingDivider} variant="middle" />
                        </Grid>
                    </Grid>
                    <Divider />
                </Paper>
            </Container>
        </div >
    )
}

export default OrgSettings;