
const state =  {
    initState: {
        levelTwoAtticCrawlSpaceState: "",
        levelTwoAtticCrawlSpaceVersion: '',
        levelTwoAtticCrawlSpaceLiteIssues: [],
        levelTwoAtticCrawlSpacePhotos: [],
    },
    validation: (inspection) => ({

    }
    ),
};

export default state