import * as Yup from 'yup';
import {
  CRACKED_LINER,
  MASONRY,
  MISSING_DETERIORATED_MORTAR,
  NO_EXISTING_LINER,
  UNSATISFACTORY,
  OPEN_FIREBOX,
  INSERT,
  FUEL_GAS,
  FUEL_PELLET,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryOverviewInteriorOverviewPhotos: [],
    masonryOverviewFireplaceOpeningWidth: { label: '', value: '' },
    masonryOverviewFireplaceOpeningHeight: { label: '', value: '' },
    masonryOverviewFireboxFrontWidth: { label: '', value: '' },
    masonryOverviewFireboxRearWidth: { label: '', value: '' },
    masonryOverviewFireboxDepth: { label: '', value: '' },
    masonryOverviewFireboxHeight: { label: '', value: '' },
    masonryOverviewFlueLength: { label: '', value: '' },
    masonryOverviewAppliancePhotos: [],
    masonryOverviewCapSize: { value: '' },
    masonryOverviewCustomCapRoofSide: { label: '', value: '' },
    masonryOverviewCustomCapLeftRoofSide: { label: '', value: '' },
    masonryOverviewCustomCapStreetSide: { label: '', value: '' },
    masonryOverviewCustomCapRightRoofSide: { label: '', value: '' },
    masonryOverviewStackWidth: { label: '', value: '' },
    masonryOverviewStackLength: { label: '', value: '' },
    masonryOverviewLinerIDSmall: { label: '', value: '' },
    masonryOverviewLinerIDLarge: { label: '', value: '' },
    masonryOverviewStackPhotos: [],
    masonryOverviewElectric: '',
    masonryOverviewGasLine: '',
    prefabOverviewGasLineEntry: { label: '', value: '' },
    gasOverviewStayLit: '',
    masonryOverviewGasLinePhotos: [],
    masonryOverviewGasLineEntry: { label: '', value: '' },
    masonryOverviewGasLinePro: { label: '', value: '' },
    masonryOverviewGasLineDist: { label: '', value: '' },
    masonryOverviewGasLineHeight: { label: '', value: '' },
    masonryOverviewGasLineDepth: { label: '', value: '' },
    masonryOverviewCustomCapSkirtLength: '',
    masonryOverviewCustomCapLegHeight: '',
    masonryOverviewCustomCapColor: '',
    masonryOverviewInsertMake: '',
    masonryOverviewInsertModel: '',
    masonryOverviewPelletInsertStrt: '',
    masonryOverviewPelletInsertControl: '',
    masonryOverviewPelletControlMake: '',
    masonryOverviewPelletControlModel: '',
    masonryOverviewPelletControlPhotos: [],
    masonryOverviewPelletInsertControlStatus: '',
    masonryOverviewStackWidthVerificationPhoto: [],
    masonryOverviewStackLengthVerificationPhoto: [],
    masonryMantelCombustibleProjection: '',
    masonryMantelCombustibleMaterials: '',
    masonryMantelState: '',
    masonryMantelClearancePhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value &&
    inspection.data.overviewStructureFuelType !== FUEL_GAS.value &&
    inspection.data.overviewStructureFuelType !== FUEL_PELLET.value &&
    (inspection.data.overviewApplianceType === OPEN_FIREBOX.value ||
      inspection.data.overviewApplianceType === INSERT.value)
      ? {
          masonryOverviewFireplaceOpeningWidth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          masonryOverviewFireplaceOpeningHeight: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          masonryOverviewFireboxFrontWidth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          masonryOverviewFireboxRearWidth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          masonryOverviewFireboxDepth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          masonryOverviewFireboxHeight: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          masonryOverviewAppliancePhotos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          masonryOverviewInteriorOverviewPhotos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          masonryOverviewStackWidth: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),
          masonryOverviewStackLength: Yup.object()
            .shape({
              value: Yup.string().required(REQUIRED),
            })
            .required(REQUIRED),

          masonryOverviewLinerIDSmall: Yup.object().when(
            ['masonryLinerState', 'masonryLinerStatus'],
            {
              is: (state, status) =>
                state === UNSATISFACTORY.value &&
                [
                  MISSING_DETERIORATED_MORTAR.value,
                  CRACKED_LINER.value,
                  NO_EXISTING_LINER.value,
                ].includes(status),
              then: Yup.object()
                .shape({
                  value: Yup.string().required(REQUIRED),
                })
                .required(REQUIRED),
              otherwise: Yup.object().notRequired(),
            }
          ),
          masonryOverviewLinerIDLarge: Yup.object().when(
            ['masonryLinerState', 'masonryLinerStatus'],
            {
              is: (state, status) =>
                state === UNSATISFACTORY.value &&
                [
                  MISSING_DETERIORATED_MORTAR.value,
                  CRACKED_LINER.value,
                  NO_EXISTING_LINER.value,
                ].includes(status),
              then: Yup.object()
                .shape({
                  value: Yup.string().required(REQUIRED),
                })
                .required(REQUIRED),
              otherwise: Yup.object().notRequired(),
            }
          ),
          masonryOverviewStackPhotos: Yup.array()
            .required(REQUIRED)
            .min(1, VALID_ONE_PHOTO),
          masonryOverviewElectric: Yup.string().required(REQUIRED),
          masonryOverviewGasLine: Yup.string().required(REQUIRED),
          masonryOverviewInsertModel: Yup.string().notRequired(),
          masonryOverviewInsertMake: Yup.string().notRequired(),
          masonryOverviewPelletInsertStrt: Yup.string().notRequired(),
        }
      : {}),
  }),
};

export default state;
