import React from 'react';
import { Grid, Typography, makeStyles, Container, Divider, Hidden, Button } from '@material-ui/core';
import publicComponent from 'components/helpers/publicComponent';
import styles from './styles';
import CTABox from '../components/CTABox';

const useStyles = makeStyles(styles);

const Reports = () => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item lg={12}>
            <Typography align="center" className={classes.title} variant="h1">
              Instant Reports
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <Typography align='center' className={classes.contentBlock} variant='h3'>
              Each report generated by Bear Hands Pro is comprised of three sections
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item container lg={5} justifyContent='flex-end' >
            <Grid item>
              <img
                alt="Overview section of BHP report"
                src="/static/instantReportsPage/reportStart.png"
                className={classes.image}
              />
            </Grid>
          </Grid>
          <Grid item container lg={5} >
            <Typography
              align="left"
              variant="h3"
              className={classes.contentTitle}
            >
              The Overview
            </Typography>
            <Typography
              align="left"
              variant="h6"
              className={classes.contentText}
            >
              This is the simplest way to review all of the most important information from your inspection!
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Container >
      <Grid container justifyContent='center' alignItems='center' className={classes.reportCardSection} >
        <Container >
          <Grid container justifyContent='center' alignItems='center'>
            <Hidden mdDown>
              <Grid item lg={5}>
                <Typography
                  align="right"
                  variant="h3"
                  className={classes.contentTitleReportCard}
                >
                  Chimney Health Report Card
                </Typography>
                <Typography
                  align="right"
                  variant="h6"
                  className={classes.contentTextReportCard}
                >
                  Your technicians can show customers what's wrong and where it is on there chimney
                </Typography>
              </Grid>
              <Grid item lg={5}>
                <img
                  alt="Chimney Health Report Card"
                  src="/static/instantReportsPage/reportOverview.png"
                  className={classes.image}
                />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item lg={5}>
                <img
                  alt="Chimney Health Report Card"
                  src="/static/instantReportsPage/reportOverview.png"
                  className={classes.image}
                />
              </Grid>
              <Grid item lg={5}>
                <Typography
                  align='center'
                  variant="h3"
                  className={classes.contentTitleReportCard}
                >
                  Chimney Health Report Card
                </Typography>
                <Typography
                  align='center'
                  variant="h6"
                  className={classes.contentTextReportCard}
                >
                  Your technicians can show customers what's wrong and where it is on there chimney
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Grid>

      <Divider />
      <Container>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={5}>
            <img
              alt="The Issues section"
              src="/static/instantReportsPage/reportComponent.png"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={5}>
            <Typography
              align="left"
              variant="h3"
              className={classes.contentTitle}
            >
              Individual Issues
            </Typography>
            <Typography
              align="left"
              variant="h6"
              className={classes.contentText}
            >
              Your technicians can review the specific details of each issue they discover during inspections!
            </Typography>
          </Grid>
        </Grid>
        <Container>
          <Grid container justifyContent='center'>
            <Grid item>
              <a href='/static/guides/Example_Report.pdf' target='_blank'>
                <Button color='primary' variant='outlined'>See an Example here!</Button>
              </a>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Grid container justifyContent="center" alignItems="center">
          <CTABox />
        </Grid>
      </Container>
    </>
  );
};

export default publicComponent(Reports);
