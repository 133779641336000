const state = {
  initState: {
    possibleInsuranceClaim: 'NO',
    insuranceCases: [],
    insurancePolicyInformation: {},
  },
  validation: () => ({}),
};

export default state;
