import * as Yup from 'yup';
import {
  CUSTOM,
  MASONRY,
  UNSATISFACTORY,
  VERSION_PRO,
  YES,
  FUEL_GAS,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonrySparkScreenState: '',
    masonrySparkScreenVersion: VERSION_PRO.value,
    masonrySparkScreenLiteIssues: [],
    masonrySparkScreenLitePhotos: [],
    masonrySparkScreenPhotos: [],
    masonrySparkScreenDamage: '',
    masonrySparkScreenDoorSize: { value: '' },
    masonrySparkScreenCustomSize: '',
    masonrySparkScreenAdtlDescription: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && inspection.data.fledge === false
      ? {
        masonrySparkScreenState: Yup.string().required(REQUIRED),
        masonrySparkScreenPhotos: Yup.array().when(
          ['masonrySparkScreenVersion', 'masonrySparkScreenState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonrySparkScreenDamage: Yup.string().when(
          ['masonrySparkScreenVersion', 'masonrySparkScreenState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonrySparkScreenDoorSize: Yup.object().when(
          [
            'masonrySparkScreenVersion',
            'masonrySparkScreenState',
            'masonrySparkScreenDamage',
          ],
          {
            is: (version, state, damaged) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              damaged === YES.value,
            then: Yup.object()
              .shape({
                value: Yup.string().required(REQUIRED),
              })
              .required(REQUIRED),
            otherwise: Yup.object().notRequired(),
          }
        ),
        masonrySparkScreenCustomSize: Yup.string().when(
          [
            'masonrySparkScreenVersion',
            'masonrySparkScreenState',
            'masonrySparkScreenDamage',
            'masonrySparkScreenDoorSize',
          ],
          {
            is: (version, state, damaged, size) =>
              version === VERSION_PRO.value &&
              state === UNSATISFACTORY.value &&
              damaged === YES.value &&
              size.value === CUSTOM.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonrySparkScreenAdtlDescription: Yup.string().notRequired(),
        masonrySparkScreenLitePhotos: Yup.array().when(
          ['masonrySparkScreenState', 'masonrySparkScreenVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED),
            otherwise: Yup.array().notRequired()
          }
        ),
      }
      : {}),
  }),
};

export default state