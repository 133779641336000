import { colors } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        maxWidth: '20rem',
        backgroundImage: 'url(/static/reviews/reviewBackground.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    items: {
        margin: theme.spacing(2, 2, 0, 2)
    },
    starItems: {
        margin: theme.spacing(0, 2, 2, 2)
    },
    stars: {
        color: colors.amber[400]
    },
    cardText: {
        color: 'White',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    cardText2: {
        color: 'white',
        fontSize: '16px',
    },
    whiteLine: {
        color: 'white',
        width: '50%'
    }
});

export default styles