
import { VERSION_LITE, UTILITY_CHIMNEY } from 'components/app/InspectionForm/constants';


const state =  {
    initState: {
        utilChimAppVersion: VERSION_LITE.value,
        utilChimAppliances: [],
    },
    validation: (inspection) => {



        return ({
            ...(inspection.data.overviewApplianceType === UTILITY_CHIMNEY.value
                ? {

                }
                : {}),
        })
    },
};

export default state