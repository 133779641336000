export const MASONRY = {
  label: 'Masonry',
  value: 'MASONRY'
};
export const PREFAB = {
  label: 'Prefab',
  value: 'PREFAB'
};
export const INTERIOR = {
  label: 'Interior',
  value: 'INTERIOR'
};
export const EXTERIOR = {
  label: 'Exterior',
  value: 'EXTERIOR'
};
export const ROOF = {
  label: 'Roof',
  value: 'ROOF'
};
export const UTILITY_ROOM = {
  label: 'Utility Room',
  value: 'UTILITY_ROOM'
};
export const INSERT = {
  label: 'Insert',
  value: 'INSERT'
};
export const FREESTANDING = {
  label: 'Freestanding',
  value: 'FREESTANDING'
};
export const WATER_HEATER = {
  label: 'Water Heater',
  value: 'WATER_HEATER'
};
export const BOILER = {
  label: 'Boiler',
  value: 'BOILER'
};
export const UTILITY_CHIMNEY = {
  label: 'Utility Chimney',
  value: 'UTILITY_CHIMNEY'
};
export const FURNACE = {
  label: "Furnace",
  value: 'FURNACE'
};
export const FIREPLACE = {
  label: 'Fireplace',
  value: 'FIREPLACE'
};
export const GAS_STARTER = {
  label: 'Gas Starter',
  value: 'GAS_STARTER'
};
export const GAS_LOG_SET = {
  label: 'Gas Log Set',
  value: 'GAS_LOG_SET'
};
export const OPEN_FIREBOX = {
  label: 'Open Firebox',
  value: 'OPEN_FIREBOX',
};
export const LEVEL_ONE = {
  label: 'Level One',
  value: 'LEVEL_ONE',
};
export const LEVEL_TWO = {
  label: 'Level Two',
  value: 'LEVEL_TWO',
};
export const APPLIANCE = {
  label: 'Appliance',
  value: 'APPLIANCE',
};
export const CHIMNEY = {
  label: 'Chimney',
  value: 'CHIMNEY'
};
export const VISUAL = {
  label: 'Visual',
  value: 'VISUAL'
}
