import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

//Sentry React App Monitoring
Sentry.init({
  dsn: 'https://5e64eccc4c3b485885e4301897ac80d5@o4503903182323712.ingest.sentry.io/4503903183437824',
  release: process.env.REACT_APP_BUILD,
  integrations: [new BrowserTracing()],

  tracesSampleRate: 0.8,
  autoSessionTracking: true,
});

ReactDOM.render(<App />, document.getElementById('root'));
