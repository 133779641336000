import applianceState from './ApplianceForm/state';
import overviewState from './OverviewForm/state';
import previewState from './Preview/state';
import priceFormState from './PriceForm/state';

const state = {
  initState: {
    ...overviewState.initState,
    ...applianceState.initState,
    ...previewState.initState,
    ...priceFormState.initState,
  },
  validation: (activeStep, inspection, org) => {
    switch (activeStep) {
      case 0:
        return overviewState.validation(inspection, org);
      case 1:
        return applianceState.validation(inspection);
      case 2:
        return previewState.validation;
      case 3:
        return priceFormState.validation;
      case 4:
        return priceFormState.validation;
      default:
        return [];
    }
  },
};

export default state;
