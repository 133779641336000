export const BOOMLIFT = {
  label: 'Boom Lift',
  value: 'BOOMLIFT'
};
export const SCISSORLIFT = {
  label: 'Scissor Lift',
  value: 'SCISSORLIFT'
};
export const SCAFFOLDING = {
  label: 'Scaffolding',
  value: 'SCAFFOLDING'
};
export const NOLIFT = {
  label: 'No Lift',
  value: 'NOLIFT'
};
