import React from 'react'
import { useParams } from 'react-router-dom';
import styles from './styles';
import SignUpButton from '../components/signUpButton';
import CheckIcon from '@material-ui/icons/Check';
import ReviewCard from '../components/reviewCard';

import publicComponent from 'components/helpers/publicComponent';

import {
    Grid,
    Typography,
    makeStyles,
    Container,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@material-ui/core';
const useStyles = makeStyles(styles);

const AdLanding = (props) => {
    const classes = useStyles();
    const { ad } = useParams()

    return (
        <Container className={classes.mainContainer}>
            <Grid container justifyContent="space-around" alignItems="center">
                <Grid item lg={10} className={classes.contentBlock}>
                    <Paper className={classes.cta} >
                        <Typography align='center' className={classes.ctaTitle} variant='h3'>Sign up now for a free 45 minute demo!</Typography>
                        <Typography align='center' className={classes.ctaTitle} variant='h3'>And first 30 days of Bear Hands Pro Free!</Typography>
                        <SignUpButton
                            promoCode={ad}
                            {...props}
                        />
                    </Paper>
                </Grid>
                <Grid item container justifyContent='space-around' lg={12}>
                    <ReviewCard
                        review='"BHP is easy in the field but the best selling point of ease to your clients with its layout, information you can show, images, pricing on separate report, and connect it to a CRM!
                        It will just make you money, period."'
                        owner='Chad Murray'
                        business='Masters Services Chimney and Masonry'
                        location='Dallas, TX'
                    />
                    <ReviewCard
                        review='"I use it ever day. I love it. It really guides you through the process and gives you a great report at the end. Very thorough and really helps show the customers what exactly is going on in their system."'
                        owner='Austin Hogan'
                        business="Mike's Mobile Screen & Chimney Service"
                        location="Sacramento, CA"
                    />
                    <ReviewCard
                        review='"I can not say enough good things about bear hands pro my new tech with less then 6 months of experience can do a lvl 1 or 2 inspection with ease and sell the job for repairs after the tech support is by far best in the business no other company comes close just try the free trial and you’ll see for your self"'
                        owner='Kyle Jermyn'
                        business='Alpine Chimney Service'
                        location='Collinswood, NJ'
                    />
                </Grid>
                <Grid item lg={12}>
                    <Typography align="center" className={classes.title} variant="h1">
                        Plans and Pricing
                    </Typography>
                </Grid>
                <Grid item className={classes.textSpacing} lg={10}>
                    <Grid item className={classes.versionTile}>
                        <Paper className={classes.paper} >
                            <Typography variant="h2" className={classes.versionTileTitle}>Bear Hands Pro </Typography>
                            <Typography variant="h3" className={classes.versionTilePrice1}>Dashboard: $249 /month</Typography>
                            <Typography variant="h3" className={classes.versionTilePrice2}>Tech Accounts: $49 each/month</Typography>
                            <Divider />
                            <Grid container>
                                <Grid item lg={6} xs={12}>
                                    <List>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Ready to go out of the box, no download required"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Digital reports and proposals generated instantly"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Professional and standardized reports and proposals"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Data automatically uploaded to the cloud"></ListItemText></ListItem>

                                    </List>
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <List>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Freestanding stove inspection reports"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Gas fireplace system reports"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Pre-set and customizable outputs"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Quotes instantly generated for clients"></ListItemText></ListItem>
                                    </List>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align='center' variant='h3'>
                                        Software that pays for itself many times over
                                    </Typography>
                                    <List>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Techs save time on each job: 20 minutes per inspection x 4 inspections a day = 80 minutes a day spent on inspections, saving an average of 6+ hours a week per tech!"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="Sales are increased through better flow and language used with the clients!"></ListItemText></ListItem>
                                        <ListItem><ListItemIcon><CheckIcon className={classes.checkMark} /></ListItemIcon><ListItemText primary="BHP is easy to use so even new techs will look and sound professional with much less training required!"></ListItemText></ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

        </Container>
    )
}

export default publicComponent(AdLanding)