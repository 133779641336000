export const SERVICE_FLASH_SEAL = {
  label: 'Seal Flashing',
  value: 'SERVICE_FLASH_SEAL',
};
export const SERVICE_FLASHING_INSTALL = {
  label: 'Install Flashing',
  value: 'SERVICE_FLASHING_INSTALL',
};

export const PRODUCT_CRICKET = {
  label: 'Cricket',
  value: 'PRODUCT_CRICKET',
};
