import * as Yup from 'yup';

import {
    FREESTANDING, UNSATISFACTORY, VERSION_LITE, VERSION_PRO,
} from 'components/app/InspectionForm/constants';

import {
    REQUIRED,
    VALID_ONE_PHOTO
} from 'components/app/InspectionForm/stateConstants';

const state = {
    initState: {
        freestandingHeight: '',
        freestandingHeightVersion: VERSION_LITE.value,
        freestandingHeightPhotos: [],
        freestandingHeightRebuild: '',
    },
    validation: (inspection) => ({
        ...(inspection.data.overviewApplianceType === FREESTANDING.value &&
            inspection.data.freestandingHeightVersion === VERSION_PRO.value)
            ? {
                freestandingHeight: Yup.string().required(REQUIRED),
                freestandingHeightPhotos: Yup.array().when(
                    'freestandingHeight',
                    {
                        is: UNSATISFACTORY.value,
                        then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
                        otherwise: Yup.array().notRequired(),
                    }
                ),
                freestandingHeightRebuild: Yup.number().when(
                    'freestandingHeight',
                    {
                        is: UNSATISFACTORY.value,
                        then: Yup.number().required(REQUIRED),
                        otherwise: Yup.number().notRequired()
                    }
                ),
            } : {},
    }),
};

export default state