import * as Yup from 'yup';
import {
  FUEL_GAS,
  UNSATISFACTORY,
  VERSION_LITE,
  FREESTANDING,
  UTILITY_CHIMNEY,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    gasLogSetState: '',
    gasLogSetGasType: '',
    gasLogSetVentType: '',
    gasLogSetStarter: '',
    gasLogSetLitePhotos: [],
    gasLogSetLiteIssues: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewStructureFuelType === FUEL_GAS.value &&
    inspection.data.gasInsulationVersion === VERSION_LITE.value &&
    inspection.data.overviewApplianceType !== UTILITY_CHIMNEY.value &&
    inspection.data.overviewApplianceType !== FREESTANDING.value
      ? {
          gasLogSetState: Yup.string().required(REQUIRED),
          gastInsulationPhotos: Yup.array().when(['gasLogSetState'], {
            is: (state) => state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }),
        }
      : {}),
  }),
};

export default state;
