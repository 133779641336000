import * as Yup from 'yup';
import {
  MASONRY,
  UNSATISFACTORY,
  VERSION_PRO,
  FUEL_GAS,
  VERSION_LITE,
  OPEN_FIREBOX,
  INSERT,
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    masonryDamperState: '',
    masonryDamperVersion: VERSION_PRO.value,
    masonryDamperLiteIssues: [],
    masonryDamperLitePhotos: [],
    masonryDamperMissingBroken: '',
    masonryDamperPhotos: [],
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewChimneyType === MASONRY.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      && (inspection.data.overviewApplianceType === OPEN_FIREBOX.value
        || inspection.data.overviewApplianceType === INSERT.value)
      ? {
        masonryDamperState: Yup.string().required(REQUIRED),
        masonryDamperMissingBroken: Yup.string().when(
          ['masonryDamperVersion', 'masonryDamperState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.string().required(REQUIRED),
            otherwise: Yup.string().notRequired(),
          }
        ),
        masonryDamperPhotos: Yup.array().when(
          ['masonryDamperVersion', 'masonryDamperState'],
          {
            is: (version, state) =>
              version === VERSION_PRO.value && state === UNSATISFACTORY.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryDamperLiteIssues: Yup.array().when(
          ['masonryDamperState', 'masonryDamperVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        masonryDamperLitePhotos: Yup.array().when(
          ['masonryDamperState', 'masonryDamperVersion'],
          {
            is: (state, version) =>
              state === UNSATISFACTORY.value && version === VERSION_LITE.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state