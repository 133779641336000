import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Admin from 'components/app/Admin';
import AppLayout from 'layouts/AppLayout';
import AuthLayout from 'layouts/AuthLayout';
import Dashboard from 'components/app/Dashboard';
import Inspect from 'components/app/Inspect';
import LayoutRoute from 'components/utils/LayoutRoute';
import GuideLayoutRoute from 'components/utils/GuideLayoutRoute';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import PasswordReset from 'components/auth/PasswordReset';
import Register from 'components/auth/Register';
import Settings from 'components/app/Settings';
// import SignUpButton from 'components/pages/components/signUpButton';
import CreateUser from 'components/app/components/CreateUser';
import CreateOrg from 'components/app/components/CreateOrganization';
import AddClient from 'components/app/AddClient';
import ResponseForm from 'components/app/ResponseForm';
import TruckCheck from 'components/app/TruckCheck';
import FrontEnd from 'layouts/FrontEnd';
import FrontPage from 'components/pages/FrontPage';
import OurStory from 'components/pages/OurStory';
import ProVsLite from 'components/pages/ProVsLite';
import RealtorsLove from 'components/pages/RealtorsLoveBhp';
import RefractoryPanelDisclaimer from 'components/app/RefractoryPanelDisclaimer';
import RefractoryPanelCuringInstructions from 'components/app/RefractoryPanelCuringInstructions';
import WhatIsBhp from 'components/pages/WhatIsBhp';
import Pricing from 'components/pages/Pricing';
import Resources from 'components/pages/Resources';
import Support from 'components/pages/Support';
import SigningUp from 'components/pages/Guide/Guides/SigningUp';
import SigningInAndTour from 'components/pages/Guide/Guides/SigningInAndTour';
import OrgSettings from 'components/pages/Guide/Guides/OrgSettings';
import ClientTable from 'components/pages/Guide/Guides/ClientTable';
import CreatingUsers from 'components/pages/Guide/Guides/CreateUsers';
import InspectionOrder from 'components/pages/Guide/Guides/InspectionOrder';
import Responses from 'components/pages/Guide/Guides/Responses';
import Clients from 'components/pages/Guide/Guides/Clients';
import InspectionHowTo from 'components/pages/Guide/Guides/InspectionHowTo';
import ReportsAndPropsHowTo from 'components/pages/Guide/Guides/ReportsAndPropsHowTo';
import HowToEditReports from 'components/pages/Guide/Guides/HowToEditReports';
import DownloadBHP from 'components/pages/Guide/Guides/DownloadBHP';
import Reset from 'components/auth/reset';
import Reports from 'components/pages/Reports';
import Proposals from 'components/pages/Proposals';
import Privacy from 'components/pages/privacy';
import Terms from 'components/pages/terms';
import PaymentSuccess from 'components/pages/paymentSuccess';
import PaymentCanceled from 'components/pages/PaymentCanceled';
import Payment from 'components/pages/payment';
import Billing from 'components/pages/billing';
import AccountNotActive from 'components/pages/AccountNotActive';
import KickservIntegration from 'components/pages/Guide/Guides/kickservIntegration';
import SendCopyToEmail from 'components/pages/Guide/Guides/sendCopytoEmail';
import InsertForm from 'components/app/InsertForm';

import Tutorials from 'components/pages/Tutorials';
import AdLanding from 'components/pages/ad-landing';

import { useSelector } from 'react-redux';
import ChangingCompanyInfo from 'components/pages/Guide/Guides/ChangingCompanyInfo';



const forwardToDashboard = () => <Redirect to="/dashboard" push={false} />;

const AppRouter = () => {
  const auth = useSelector((state) => state.auth)

  return (
    <BrowserRouter>
      <Switch>
        <LayoutRoute
          path="/"
          exact={true}
          component={FrontPage}
          layout={FrontEnd}
        />
        <LayoutRoute path="/our-story" component={OurStory} layout={FrontEnd} />
        <LayoutRoute path="/resources" component={Resources} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <LayoutRoute path="/tutorials" component={Tutorials} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <LayoutRoute path="/support" component={Support} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />

        <GuideLayoutRoute path="/guide/signing-up" component={SigningUp} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/sign-in-and-tour" component={SigningInAndTour} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/changing-company-info" component={ChangingCompanyInfo} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/org-settings" component={OrgSettings} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/creating-users" component={CreatingUsers} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/inspection-order" component={InspectionOrder} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/responses" component={Responses} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/inspection-how-to" component={InspectionHowTo} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/reports-proposals-how-to" component={ReportsAndPropsHowTo} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/how-to-edit-reports" component={HowToEditReports} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/clients" component={Clients} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path='/guide/clientTable' component={ClientTable} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/download-bhp" component={DownloadBHP} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/kickserv-integration" component={KickservIntegration} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <GuideLayoutRoute path="/guide/send-copy-to-email" component={SendCopyToEmail} layout={auth.isAuthenticated ? AppLayout : FrontEnd} />
        <LayoutRoute
          path="/realtors-love-bhp"
          component={RealtorsLove}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/privacy"
          component={Privacy}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/Terms"
          component={Terms}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/payment"
          component={Payment}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/billing"
          component={Billing}
          layout={AppLayout}
        />
        <LayoutRoute
          path="/accountnotactive"
          component={AccountNotActive}
          layout={AppLayout}
        />
        <LayoutRoute
          path="/success"
          component={PaymentSuccess}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/canceled"
          component={PaymentCanceled}
          layout={FrontEnd}
        />

        <LayoutRoute
          path="/signup/:ad"
          component={AdLanding}
          layout={FrontEnd}
        />

        {/* <LayoutRoute
          path="/signup"
          component={SignUpButton}
          layout={FrontEnd}
        /> */}
        <LayoutRoute
          path="/pricing"
          component={Pricing}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/what-is-bhp"
          component={WhatIsBhp}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/pro-vs-lite"
          component={ProVsLite}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/instant-reports"
          component={Reports}
          layout={FrontEnd}
        />
        <LayoutRoute
          path="/instant-proposals"
          component={Proposals}
          layout={FrontEnd}
        />
        <Route path="/logout" component={Logout} />
        {/* Public Routes */}
        <LayoutRoute path="/login" component={Login} layout={AuthLayout} />
        <LayoutRoute
          path="/password-reset"
          component={PasswordReset}
          layout={AuthLayout}
        />
        <LayoutRoute path='/reset/:token?' component={Reset} layout={AuthLayout} />
        <LayoutRoute path="/register" component={Register} layout={AuthLayout} />
        {/* Private Routes */}
        <LayoutRoute path="/dashboard" component={Dashboard} layout={AppLayout} />
        <LayoutRoute
          path="/createUser"
          component={CreateUser}
          layout={AppLayout}
        />
        <LayoutRoute
          path="/responses"
          component={ResponseForm}
          layout={AppLayout}
        />
        <LayoutRoute
          path="/inserts"
          component={InsertForm}
          layout={AppLayout}
        />
        <LayoutRoute
          path="/truckcheck"
          component={TruckCheck}
          layout={AppLayout}
        />
        <LayoutRoute
          path="/refractorypaneldisclaimer"
          component={RefractoryPanelDisclaimer}
          layout={AppLayout}
        />
        <LayoutRoute
          path="/refractorypanelcuringinstructions"
          component={RefractoryPanelCuringInstructions}
          layout={AppLayout}
        />
        <LayoutRoute path="/createorg" component={CreateOrg} layout={AppLayout} />
        <LayoutRoute
          path="/search/:filter?"
          component={Dashboard}
          layout={AppLayout}
        />
        <LayoutRoute path="/addclient" component={AddClient} layout={AppLayout} />
        <LayoutRoute
          path="/inspect/:slug?"
          component={Inspect}
          layout={AppLayout}
        />
        <LayoutRoute path="/settings" component={Settings} layout={AppLayout} />
        <LayoutRoute path="/admin" component={Admin} layout={AppLayout} />




        {/* 404 */}
        <Route component={forwardToDashboard} />
      </Switch>
    </BrowserRouter>
  )
};

export default AppRouter