import * as Yup from 'yup';

import {
  INSERT,
  UNSATISFACTORY,
  YES,
  VERSION_PRO,
  FUEL_GAS,
  FUEL_PELLET
} from 'components/app/InspectionForm/constants';
import {
  REQUIRED,
  VALID_NUMBER,
  VALID_ONE_PHOTO,
} from 'components/app/InspectionForm/stateConstants';

const state = {
  initState: {
    insertBoxState: '',
    insertBoxLiteIssues: [],
    insertBoxLitePhotos: [],
    insertBoxVersion: VERSION_PRO.value,
    insertBoxFirebrickRepairNeeded: '',
    insertBoxFirebricksNeeded: '',
    insertBoxFirebrickCutsNeeded: '',
    insertBoxFirebrickPhotos: [],
    insertPelletCleanInsideComparts: '',
    insertPelletBaffles: '',
    insertPelletExchanger: '',
    insertPelletBurnPot: '',
    insertPelletIgnitor: '',
    insertPelletAshPan: '',
    insertPelletGlass: '',
    insertPelletFans: '',
    insertBoxFirebrickState: '',
  },
  validation: (inspection) => ({
    ...(inspection.data.overviewApplianceType === INSERT.value &&
      inspection.data.insertBoxVersion === VERSION_PRO.value
      && inspection.data.overviewStructureFuelType !== FUEL_GAS.value
      ? {
        insertBoxState: Yup.string().required(REQUIRED),
        insertBoxFirebrickState: Yup.string().when(['insertBoxState', 'overviewStructureFuelType'], {
          is: (state, fuel) => state === UNSATISFACTORY.value && fuel !== FUEL_PELLET.value,
          then: Yup.string().required(REQUIRED),
          otherwise: Yup.string().notRequired(),
        }),
        insertBoxFirebrickPhotos: Yup.array().when(
          ['insertBoxState', 'insertBoxFirebrickRepairNeeded'],
          {
            is: (state, firebrickRepair) =>
              state === UNSATISFACTORY.value && firebrickRepair === YES.value,
            then: Yup.array().required(REQUIRED).min(1, VALID_ONE_PHOTO),
            otherwise: Yup.array().notRequired(),
          }
        ),
        insertBoxFirebricksNeeded: Yup.number().when(
          ['insertBoxState', 'insertBoxFirebrickRepairNeeded'],
          {
            is: (state, firebrickRepair) =>
              state === UNSATISFACTORY.value && firebrickRepair === YES.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(VALID_NUMBER),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
        insertBoxFirebrickCutsNeeded: Yup.number().when(
          ['insertBoxState', 'insertBoxFirebrickRepairNeeded'],
          {
            is: (state, firebrickRepair) =>
              state === UNSATISFACTORY.value && firebrickRepair === YES.value,
            then: Yup.number()
              .typeError(VALID_NUMBER)
              .positive(VALID_NUMBER)
              .required(VALID_NUMBER),
            otherwise: Yup.number().typeError(VALID_NUMBER).notRequired(),
          }
        ),
      }
      : {}),
  }),
};

export default state